// 客服按钮入口白名单
export default [
    // 'b2bOrderList', // 我的订货-待付款列表
    'b2bOrderDetail', // 我的订货-待付款详情（普通订货）
    'b2bGroupOrderDetail', // 我的订货-待付款详情（升级订货）
    // 'b2bPartnerOrderList', // 分销订货-待付款列表
    'b2bPartnerOrderDetail', // 分销订货-待付款详情
    // 'b2cOrders', // 商城订单-待付款列表
    'b2cOrderDetail', // 商城订单-待付款详情
    // 'b2bSendList', // 发货订单-待付款列表
    'b2bSendDetail', // 发货订单-待付款详情
    // 'b2cOrdersForBuyer', // 旗舰店订单-待付款列表
    'b2cOrderDetail', // 旗舰店订单-待付款详情
    // 'b2cOrders', // 商城订单-待确认列表
    'b2cOrderDetail', // 商城订单-待确认详情
    // 'b2bSendList', // 发货订单-待确认列表
    'b2bSendDetail', // 发货订单-待确认详情
    // 'b2cOrdersForBuyer', // 旗舰店订单-待确认列表
    'b2cOrderDetail', // 旗舰店订单-待确认详情
    'newWalletIndex', // 我的钱包
    'rechargeNew', // 转入
    'listedEquityIndex', // 上市股权权益
    'listedEquityIntent', // 上市股权协议
    'fulfilNewCenter', // 报表中心
    'daPeiGoodsList', // 旗舰店列表
    // 'mainTabs', // 首页 消息 我
    // 'b2bOrderGoods', // 订货区列表（仅特约首单/升级订货tab）
    'invite', // 邀请分销客户
    'partnerList', // 我的分销客户
    'rewardIndex', // 市场服务费
    'my', // 普通用户-我的（普通用户一级页面不需要快捷导航）
    'myStock' // 我的库存
]
