/**
 @description: 错误处理 https://reactjs.org/docs/error-boundaries.html
 @date: 2018/11/4
 @author: 雷利(Rayleight@baie.com.cn)
 */
import React, {Component} from 'react'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true}
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service


        const STORAGE_KEY = 'ErrorBoundaryHasAutoRefresh'
        if (info && info.componentStack) {
            let stack = info.componentStack
            if (stack.match('in Unknown') !== null && stack.match('in Connect') !== null) {
                if (localStorage.getItem(STORAGE_KEY) === 'yes') {
                    localStorage.removeItem(STORAGE_KEY)
                    return
                }
                this.setState(
                    {
                        hasError: false
                    },
                    () => {
                        localStorage.setItem(STORAGE_KEY, 'yes')
                        this.refresh()
                    }
                )
            }
        }
    }

    refresh() {

window.history.go(0);

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{textAlign: 'center'}}>
                    <h1 style={{fontSize: 26, marginTop: 50, marginBottom: 50}}>出错了</h1>
                    <p>
                        <a className="b-btn-primary" href="/mainTabs?tab=index" >
                            刷新试试
                        </a>
                        <a className="b-btn-bordered" style={{marginLeft: '30px'}} href="/mainTabs?tab=index">
                            返回首页
                        </a>
                    </p>
                </div>
            )
        }

        return this.props.children
    }
}

/*
页面打开着，然后 发布了版本，加载新的页面会遇到下面这个错误：

"{"componentStack":"\n    in Unknown\n    in t\n    in Connect(t)\n    in t\n    in t\n    in div\n    in t\n    in t\n    in t\n    in div\n    in View\n    in n\n    in Connect(n)\n    in n"}"

 */
