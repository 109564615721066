/**
 @description: 创建store
 @date: 2018/3/26
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import thunk from 'redux-thunk'
import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import createRootReducer from '../reducers'

export const history = createBrowserHistory()

const middlewares = [
    routerMiddleware(history), // for dispatching history actions
    thunk
]

if (process.env.NODE_ENV === 'dev') {
    const {logger} = require('redux-logger')

    middlewares.push(logger)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(preloadedState) {
    return createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares))
    )
}
