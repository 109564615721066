/**
 @description: 各种文本
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example: <Text type="h1">文本组件</Text>
 */

import React, {Component} from 'react'
import {Text as RNText} from 'react-native'
import VStyle from './VStyle'
const NumberOfLinesMap = {
    '1': 'text-omitted-1',
    '2': 'text-omitted-2',
    '3': 'text-omitted-3',
    '4': 'text-omitted-4',
    '5': 'text-omitted-5',
    '6': 'text-omitted-6'
}

export default class Text extends Component {
    render() {
        const {style, numberOfLines, children, type, hide} = this.props
        if (hide) {
            return null
        }
        let _style = VStyle.font
        if (type && VStyle[type]) {
            _style = VStyle[type]
        }
        let content = children
        if (typeof content === 'number') {
            content = content.toString()
        }
        let className = ''
        if (NumberOfLinesMap[numberOfLines]) {
            className = NumberOfLinesMap[numberOfLines]
        }

        let allProps = {...this.props}
        delete allProps.hide

        return (
            <RNText className={className} selectable={numberOfLines !== 1} {...allProps} style={[_style, style]}>
                {content}
            </RNText>
        )
    }
}
