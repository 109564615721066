import React from 'react'
import {connect} from 'react-redux'
import TouchableOpacity from '../TouchableOpacity'
import Image from '../Image'
import {tSize} from '../VStyle'
import View from '../View'
import * as StyleSheet from '../StyleSheet'
import {R_confirm} from '../../utils'
import {RNMAKF} from '../../components/RNMAKF'
import whiteList from './whiteList'
import Draggable from "react-draggable"

const styles = StyleSheet.create({
    customer: {
        position: 'absolute',
        right: tSize(15),
        bottom: tSize(160),
        iPhoneX: {
            bottom: tSize(180)
        }
    }
})
const CustomerService = props => {
    const {
        navigation: {routeName, tab},
        appData: {quickMenu},
        onPress
    } = props

    // 比如同时满足两个条件才能render
    // 1 store.appData.quickMenu === true
    // 2 当前路由为白名单 或者 当前路由为'mainTabs'且param.tab 不为 kb，circle
    const route = typeof routeName === 'string' ? routeName : Object.keys(routeName)[0]

    return quickMenu &&
        (whiteList.includes(route) || (route === 'mainTabs' && !['kb', 'circle', 'my'].includes(tab))) ? (

        <Draggable
            axis="y"
        >
            <div>
                <View style={{zIndex: 98}}>
                        <TouchableOpacity
                            style={styles.customer}
                            onPress={
                                onPress ||
                                (() => {
                                    const {
                                        userInfo: {mobile, userName}
                                    } = props
                                    R_confirm({
                                        title: false,
                                        content: '您是否要联系在线客服？',
                                        yesText: '确定',
                                        yes: () => {
                                            RNMAKF(`${userName}`, `${mobile}`)
                                        }
                                    })
                                })
                            }>
                            <Image
                                style={{width: tSize(75), height: tSize(75)}}
                                source={require('../../img/new/my/service.png')}
                            />
                        </TouchableOpacity>
                </View>
            </div>
        </Draggable>
    ) : null
}

export default connect(state => state)(CustomerService)
