/**
 @Description: 带小图标文案的flex item
 @date: 2019/2/20
 @author: vivi@baie.com.cn
*/
import React from 'react'
import {View, Text, Image} from 'react-native'
import TouchableOpacity from '../TouchableOpacity'
import {COLOR_WHITE_24} from '../Colors'
import VStyle, {tSize} from '../VStyle'
import * as StyleSheet from '../StyleSheet'

const styles = StyleSheet.create({
    iconStyle: {
        width: tSize(35),
        height: tSize(35)
    },
    labelStyle: {
        fontSize: tSize(10),
        color: COLOR_WHITE_24,
        marginTop: tSize(2)
    },
    redCircle: {
        width: tSize(8),
        height: tSize(8),
        borderRadius: '50%',
        backgroundColor: '#FC5757',
        top: tSize(8),
        right: tSize(15)
    },
    number: {
        width: tSize(18),
        height: tSize(18),
        top: tSize(2),
        right: tSize(8),
        backgroundColor: '#FC5757',
        borderRadius: '50%',
        color: '#fff',
        fontSize: tSize(12),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default class TopItemWithIcon extends React.Component {
    onPress = () => {
        const {onPress} = this.props

        if (onPress) {
            onPress()
        }
    }

    render = () => {
        const {style, icon, iconStyle, label, labelStyle = {}, hide, tips = false, number = 0, tipsStyle} = this.props
        if (hide) {
            return null
        }

        let tipsDom = null
        if (tips) {
            tipsDom = <View style={[VStyle.pa, styles.redCircle, tipsStyle]} />
            if (number > 0) {
                tipsDom = <Text style={[VStyle.pa, styles.number, tipsStyle]}>{number}</Text>
            }
        }
        return (
            <View style={[VStyle.flex, VStyle.pr, style]}>
                <TouchableOpacity style={{alignItems: 'center'}} onPress={this.onPress}>
                    <Image style={[styles.iconStyle, iconStyle]} source={icon} />
                    <Text numberOfLines={1} style={[styles.labelStyle, labelStyle]}>
                        {label}
                    </Text>
                </TouchableOpacity>
                {tipsDom}
            </View>
        )
    }
}
