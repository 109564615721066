/**
 @description: 个人中心模块的actions
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as types from '../constants/ActionTypes'

/**
 * 设置个人中心数据
 * @param user_info
 * @returns {{type, newData: *}}
 */
export function setUserInfo(user_info) {
    return {
        type: types.SET_USER_INFO,
        user_info
    }
}

/**
 * 更新个人中心数据
 * @param newData
 * @returns {{type, newData: *}}
 */
export function updateUserInfo(newData) {
    return {
        type: types.UPDATE_USER_INFO,
        newData
    }
}

/**
 * 退出登录
 * @returns {{type, newData: *}}
 */
export function logout() {
    return {
        type: types.LOGOUT
    }
}

/**
 * 更新选中的地址
 * @param address
 * @returns {{type, address: *}}
 */
export function updateCheckAddress(address) {
    return {
        type: types.UPDATE_CHECK_ADDRESS,
        address
    }
}
