/**
 @description: 图片类的差异
 @date: 2018/2/28
 @author: 雷利(Rayleight@baie.com.cn)
 */
export default {
    "创客_旗舰店": {
        "baie": require('../../img/chuangKe/icon-qjd.png'),
    },
    "创客_朋友圈小图标": {
        "baie": require('../../img/icons/baie-avatar.png'),
    },
    "应用小图标": {
        "baie": require('../../img/login_page_icon1.png'),
    },
    "创客_商城入口图标": {
        "baie": require('../../img/mall/mall-in.png'),
    },
    "创客_商城入口图标_新": {
        "baie": require('../../img/mall/mall-show.png'),
    },
    "创客_商品详情_仓库图标": {
        "baie": require('../../img/mall/rosepie_store_logo.png'),
    },
    "创客_商城_店铺卡片": {
        "baie": require('../../img/share/shop_bg.png'),
    },
    "学堂_banner": {
        "baie": require('../../img/chuangKe/banner.png'),
    },
    "个人中心_关于_logo": {
        "baie": require('../../img/login_page_icon1.png'),
    },
    "个人中心_头像默认图片": {
        "baie": require('../../img/icons_tab_my/avatar_new_bg.png'),
    },
    "个人中心_分销伙伴_默认头像": {
        "baie": require('../../img/icons_tab_my/avatar_bg.png'),
    },
    "个人中心_分销伙伴_默认头像_2": {
        "baie": require('../../img/icons_tab_my/avatar_bg_2.png'),
    },
    "个人中心_分销伙伴_上阶banner": {
        "baie": require('../../img/icons_tab_my/partnerDetailBanner.png'),
    },
    "首页_商城零售_icon": {
        "baie": require('../../img/chuangKe/mall.png'),
    },
   
    "个人中心_百e旗舰店_icon": {
        "baie": require('../../img/icons_tab_my/area_7_2.png'),
    },
}