/**
 @description: 各种actions
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
export const ROUTE_DID_FOCUS = 'ROUTE_DID_FOCUS' // 界面被显示出来了
export const NAVIGATE = 'NAVIGATE'
export const GET_USER_INTO = 'GET_USER_INTO' // 实名认证
export const SHOW_LOGIN = 'SHOW_LOGIN'
export const SWITCH_TAB = 'SWITCH_TAB'
export const GET_USER = 'GET_USER'
export const SET_APP_DATA = 'SET_APP_DATA'
export const SET_TOKEN = 'SET_TOKEN'
export const SHOW_NOTIFY = 'SHOW_NOTIFY' // 显示个人中心 公告弹窗
export const HIDE_NOTIFY = 'HIDE_NOTIFY' // 隐藏个人中心 公告弹窗
export const UPDATE_SHARE = 'UPDATE_SHARE' // 更新分享信息
export const UPDATE_TABS_MSG_NUM = 'UPDATE_TABS_MSG_NUM' // 更新底部tab 的数字

// b2b - 分销
export const B2B_CART_ADD = 'B2B_CART_ADD' // 添加到进货单
export const B2B_CART_RESET = 'B2B_CART_RESET' // 重置进货单
export const B2B_CART_DELETE = 'B2B_CART_DELETE' // 从进货单中删除
export const B2B_CART_UPDATE = 'B2B_CART_UPDATE' // 更新进货单信息

export const B2B_CHANGE_CATEGORY = 'B2B_CHANGE_CATEGORY' // 切换商品分类

// user
export const UPDATE_CHECK_ADDRESS = 'UPDATE_CHECK_ADDRESS' // 修改选中的地址

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO' // 更新个人中心数据
export const SET_USER_INFO = 'SET_USER_INFO' // 覆盖个人中心数据
export const LOGOUT = 'LOGOUT' // 退出登录

// iPay-user
export const SET_IPAY_INFO = 'SET_IPAY_INFO' // 设置iPayToken(新支付平台token)
export const UPDATE_IPAY_INFO = 'UPDATE_IPAY_INFO' // 更新个人中心数据

// b2c
export const B2C_UPDATE_SHOP_INFO = 'B2C_UPDATE_SHOP_INFO' // 更新店铺信息
export const B2C_CHANGE_PRODUCT_LIST_CATEGORY = 'B2C_CHANGE_PRODUCT_LIST_CATEGORY' // 切换试销商城商品分类
export const B2C_CHANGE_GOODS_LIST_CATEGORY = 'B2C_CHANGE_GOODS_LIST_CATEGORY' // 切换 云商城商品列表 分类
export const B2C_CART_ADD = 'B2C_CART_ADD' // 加入购物车
export const B2C_CART_DELETE = 'B2C_CART_DELETE' // 从购物车删除
export const B2C_CART_UPDATE = 'B2C_CART_UPDATE' // 更新购物车

// 直播切换tab
export const SWITCH_TAB_LIVE = 'SWITCH_TAB_LIVE'

//
export const NOTIFICATION_DATA = 'NOTIFICATION_DATA' // 暂存通知内容
export const NET_INFO_CHANGE = 'NET_INFO_CHANGE' // 网络环境变化

export const SHOW_IMAGE_SLIDE = 'SHOW_IMAGE_SLIDE' // 网络环境变化

// boc中行卡
export const BOC_SUBMIT_INFO_UPDATE = 'BOC_SUBMIT_INFO_UPDATE'

// 客服入口、快捷导航显隐
export const TOGGLE_QUICKMENU = 'TOGGLE_QUICKMENU'

// CRM
export const CRM_LOGIN = 'CRM_LOGIN' // 云信登录
export const CRM_LOGOUT = 'CRM_LOGOUT' // 云信登出
export const CRM_MESSAGE = 'CRM_MESSAGE' // 接收到新消息
export const CRM_MESSAGE_TOP = 'CRM_MESSAGE_TOP' // 云信消息 - 置顶
export const CRM_MESSAGE_NOTIFY = 'CRM_MESSAGE_NOTIFY' // 云信消息 - 提醒
export const CRM_MESSAGE_DELETE = 'CRM_MESSAGE_DELETE' // 云信消息 - 删除

// 托管
export const HOSTING_RECEIVE_REDPOT = 'HOSTING_RECEIVE_REDPOT' // 更新红点
