/**
 @description: b2c部分数据
 @date: 2016-8-31
 @author: Rayleigh(Rayleigh@Nero-zou.com)
 */
import * as TYPES from '../constants/ActionTypes'

// const initialState = {
//     cartItems: [],
//     productListCategory: -1, //-1 表示全部 , 试销商城 当前选中的分类
//     goodsListCategory: -1, //-1 表示全部 , 云商城 列表 当前选中的分类
// }
const initialState = {
    cartItems: [],
    productListCategory: -1, // -1 表示全部 , 试销商城 当前选中的分类
    goodsListCategory: -1, // -1 表示全部 , 云商城 列表 当前选中的分类
    shopInfo: {} // 云商城 店铺信息
}

export default function navigation(state = initialState, action) {
    switch (action.type) {
        case TYPES.B2C_CHANGE_PRODUCT_LIST_CATEGORY: {
            return {
                ...state,
                productListCategory: action.category
            }
        }

        case TYPES.B2C_CHANGE_GOODS_LIST_CATEGORY: {
            return {
                ...state,
                goodsListCategory: action.category
            }
        }

        case TYPES.B2C_CART_ADD: {
            // 如果这个商品已经加入过了购物车，那么就把旧的那个给替换了
            // 如果这个商品还未加入过，就加到最后
            // action.item={
            //     "gmtCreated": "2016-09-08 11:23:02",
            //     "gmtModify": "2016-09-08 11:24:55",
            //     "id": 73149,
            //     "userId": 4458,
            //     "cartId": 6026,
            //     "goodsId": 48646,
            //     "productQuantity": 3
            // }
            let hasAdd = false
            let cartItems = []
            for (let i = 0; i < state.cartItems.length; i++) {
                let obj = state.cartItems[i]
                if (obj.id === action.item.id) {
                    cartItems.push(action.item)
                    hasAdd = true
                } else {
                    cartItems.push(obj)
                }
            }
            if (!hasAdd) {
                cartItems.push(action.item)
            }
            return {
                ...state,
                cartItems
            }
        }
        case TYPES.B2C_CART_DELETE: {
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.item.id)
            }
        }
        case TYPES.B2C_UPDATE_SHOP_INFO: {
            return {
                ...state,
                shopInfo: action.shopInfo
            }
        }
        case TYPES.B2C_CART_UPDATE: {
            return {
                ...state,
                cartItems: action.cartItems
            }
        }
        default:
            return state
    }
}
