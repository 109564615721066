/**
 * 工程级配置
 */
import {currency} from '../utils'

const registOrigin = '小爱'// 注册渠道--早团/变啦/云创/小爱

const GLOBAL_PROJECT_TEXT = {
    "创客_XX自营": {
        "baie": "百e自营",
    },
    "创客_XX旗舰店": {
        "baie": "百e旗舰店",
    },
    "学堂_H5分享页标题": {
        "baie": "百e介绍",
    },
    "app名称": {
        "baie": "iEnglish服务商管理平台",
    },
    "公众号名称": {
        "baie": "iEnglish服务商管理平台",
    },
    "app简称": {
        "baie": "iEnglish服务商管理平台",
    },
    "用户集合名称": {
        "baie": "服务商",
    },
    "app简称1": {
        "baie": "百e",
    },
    "自营": {
        "baie": "百e自营",
    },
    "渠道": {
        "baie": "MC1001",
    },
    "公司名称": {
        "baie": "杭州百奕网络科技有限公司",
    },
    "公司简称": {
        "baie": "百e国际",
    },
    "联系我们_违规举报邮箱": {
        "baie": "weiguijubao@baie.com.cn",
    },
    "联系我们_违规举报电话": {
        "baie": "15067114492",
    },
    "联系我们_投诉受理邮箱": {
        "baie": "fuwutousu@baie.com.cn",
    },
    "联系我们_投诉受理电话": {
        "baie": "182-5716-7390",
    },
    "联系我们_客服热线": {
        "baie": "010-86397776",
    },
    "WechatAppID": {
        "baie": "wxddb03c6eb3fd01a2",
    },
    "WechatAPPSECRET": {
        "baie": "1b9218c01d3175f341b273a779f1ccf2",
    }
}


/**
 * 用户身份配置
 */
const GLOBAL_USER_MAP = {
    '5': {
        text: "普通会员",           // 等级名称
        totalText: "普通会员",    // 等级全称
        title: "普通会员",              // 等级显示为一个字的情况
        textAbbr: "普通会员",          // 等级显示为两个字的情况
        risePrice: 3000,          // 升级需要一次性订的特惠总价
        totalPrice: 0,             // 达到当前等级价格需要累计的特惠总价
        priceType: 'NORMAL'        // 商品价格的外键
    },
    '4': {
        text: "超级会员",
        totalText: "超级会员",
        title: "超级会员",
        textAbbr: "超级会员",
        risePrice: 17000,
        totalPrice: 1400,
        priceType: 'SUPER'
    },
    '3': {
        text: "合伙人",
        totalText: "合伙人",
        title: "合伙人",
        textAbbr: "合伙人",
        risePrice: 60000,
        totalPrice: 0,
        priceType: 'PARTNER'
    },
    '2': {
        text: "会长",
        totalText: "会长",
        title: "会长",
        textAbbr: "会长",
        risePrice: 165000,
        totalPrice: 28000,
        priceType: 'SOCIETY'
    },
    '1': {
        text: "校董",
        totalText: "校董",
        title: "校董",
        textAbbr: "校董",
        risePrice: 165000,
        totalPrice: 82800,
        priceType: 'SCHOOL'
    },
    error: {
        text: "未知",
        totalText: "未知",
        title: "00",
        textAbbr: "未知",
        risePrice: 0,
        totalPrice: 0,
        priceType: 'NORMAL'
    }
}


const gradeInfoScheme = {
    text: "未知",
    totalText: "未知",
    title: "00",
    textAbbr: "未知",
    risePrice: 0,
    totalPrice: 0
}

/**
 * 获取对应等级的信息
 * 防止未来等级变化，可以动态变更grade对应的信息
 * @param grade {string | number}
 * @returns {({} & {totalPrice: number, totalText: string, risePrice: number, textAbbr: string, text: string, title: string})|any}
 */
function getUserGradeInfo(grade) {
    switch (grade) {
        case '1':
        case 1:
        case '2':
        case 2:
        case '3':
        case 3:
        case '4':
        case 4:
        case '5':
        case 5:
            return Object.assign({}, gradeInfoScheme, GLOBAL_USER_MAP[`${grade}`]);
        default:
            return Object.assign({}, gradeInfoScheme)
    }
}

/**
 * 第三方资源配置
 */
const GLOBAL_THIRD_PARTY = {
    "WeChat": {
        hideOptions: true, // 在页面初始化时候默认禁用右上角分享配置，可在业务中重新修改
    }
}

const userLevelArray = [1, 2, 3, 4, 5]

/**
 * 价格信息类型,
 * 定义该数组的原因在于后端返回的价格信息里的唯一键是priceType，而不是直接的等级,
 * v 1.0更换后，排序仍存在
 */
const priceType = ['2', '3', '4', '5']
/**
 *
 * @param info {array} 需要排序的价格信息
 * @param orderType {string} ['priceType', 'price'] 排序关键字
 * @param asc {boolean} 升序
 * @returns {Array|*[]}
 */
const reSortGoodsPrice = (info = [], orderType = 'priceType', asc = true) => {
    if (!info.length) return []
    let result = JSON.parse(JSON.stringify(info))
    try {
        result.sort(priceSortFunction.bind(this, orderType, asc))
        return result
    } catch (e) {
        return []
    }
}

const priceSortFunction = (key, asc, a, b) => {
    try {
        if (key === 'priceType' ) {
            // 按某种排序标准进行比较, a 小于 b
            if (priceType.indexOf(a[key]) < priceType.indexOf(b[key]) ) {
                return asc ? -1 : 1;
            }
            if (priceType.indexOf(a[key]) > priceType.indexOf(b[key])) {
                return asc ? 1 : -1;
            }
            // a must be equal to b
            return 0;
        }
        if (a[key] < b[key] ) {
            return asc ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return asc ? 1 : -1;
        }
        return 0;
    } catch (e) {
        return 0
    }
}

/**
 * 价格类型转等级
 * @param _priceType
 * @returns {number|*}
 */
const changePriceTypeToPriceLevel = (_priceType) => {
    try {
        return priceType.indexOf(_priceType) + 2
    } catch {
        return 0
    }
}

/**
 * 获取价格等级
 * @param info {array}
 * @param normalPrice {number}
 * @param nowGrade {number | string}
 * @param monthCount {number}
 * @param expect {number}
 * @returns {number}
 */
const getPriceLevel = (info = [], normalPrice, nowGrade, monthCount, expect = 1) => {
    if (!info.length || isNaN(expect)) return 1
    try {
        const nowMonthCount = monthCount + expect * normalPrice
        const ascInfo = reSortGoodsPrice(info)
        for (let i=0; i < ascInfo.length; i++) {
            if(ascInfo[i]['totalPrice'] > nowMonthCount) {
                const priceType = ascInfo[i]['priceType']
                return changePriceTypeToPriceLevel(priceType) - 1 > 0 ? changePriceTypeToPriceLevel(priceType) - 1 : 1
            }
        }
        return 5
    } catch (e) {
        return 1
    }
}

const priceInfoScheme = {
    price: 0,
    priceType: "NORMAL",
    productId: 0,
    stationCount: 0,
    totalPrice: 0,
}

/**
 * 获取价格信息
 * @param info
 * @param normalPrice
 * @param nowGrade
 * @param monthCount
 * @param expect
 * @returns {({} & {productId: number, price: number, stationCount: number, priceType: string})|number|any}
 */
const getPriceInfo = (info = [], normalPrice ,nowGrade, monthCount, expect = 1) => {
    if (!info.length || isNaN(expect)) return Object.assign({}, priceInfoScheme, {price: normalPrice})
    try {
        const nowMonthCount = monthCount + expect * normalPrice
        const ascInfo = reSortGoodsPrice(info)
        // 找出等级范围内最接近的升级上限
        let equalPriceInfo = ''
        for (let i=0; i < ascInfo.length; i++) {
            const totaolPrice = parseInt(ascInfo[i]['totalPrice'])
            const priceLevel = ascInfo[i]['priceType']
            if(totaolPrice > nowMonthCount && priceLevel <= nowGrade) {
                return i > 0 ? Object.assign({}, priceInfoScheme, ascInfo[i - 1]) : Object.assign({}, priceInfoScheme, {price: normalPrice})
            }
            if (+priceLevel === +nowGrade) {
                equalPriceInfo = ascInfo[i]
            }
        }
        // 超出等级上限，直接使用当前等级的价格
        if (equalPriceInfo) {
            return Object.assign({}, priceInfoScheme, equalPriceInfo)
        }
        // 啥都没找到的给个零售价
        return Object.assign({}, priceInfoScheme, {price: normalPrice})
    } catch (e) {
        return 1
    }
}

/**
 * 获取MOQ
 * @param priceInfo
 * @param retailPrice
 * @param userGrade
 * @returns {({minQuantity: number, price: *, levelUpQuantity: number, priceLevel: number, error: number} & {error: number})|({minQuantity: number, price: *, levelUpQuantity: number, priceLevel: number, error: number} & {minQuantity: number, price, levelUpQuantity: number, priceLevel: number})}
 */
const getMinOrderQuantity = (priceInfo = [], retailPrice ,userGrade) =>{
    const resultScheme = {
        price: retailPrice,
        priceLevel: 1,
        minQuantity: 1,
        levelUpQuantity: 0,
        error: 0
    }
    if(!priceInfo.length || !userLevelArray.includes(+userGrade) || +userGrade === 1) {
        return Object.assign(resultScheme, {error: 1})
    }
    const ascPriceInfo = reSortGoodsPrice(priceInfo)
    try {
        for (let i=0; i < ascPriceInfo.length; i++) {
            const {totalPrice, price, priceType} = ascPriceInfo[i]
            const priceLevel = +priceType
            if(priceLevel === +userGrade) {
                let levelUpQuantity = 0, minQuantity = 0, collocationPrice = null
                if (i !== ascPriceInfo.length) {
                    const levelUpInfo = ascPriceInfo[i + 1]
                    collocationPrice = levelUpInfo?.collocationPrice
                }
                if (collocationPrice) {
                    levelUpQuantity = Math.ceil(collocationPrice/price)
                }
                if (totalPrice) {
                    minQuantity = Math.ceil(totalPrice/retailPrice)
                }
                return Object.assign(resultScheme, {price, priceLevel, minQuantity, levelUpQuantity})
            }
        }
    } catch (e) {
        // 处理后端未知配置数据专用
        return Object.assign(resultScheme, {error: 1})
    }

    return Object.assign(resultScheme, {error: 1})
}

/**
 * 后端返回奇葩时间纠正
 * @param time {string}
 */
const backEndTimeWrap = (time) => {
    let reg = new RegExp(/[0-9]{4}-[0-9]{2}/g)
    if (reg.test(time)) {
        return time.replace(/-/g, '/') + '/01'
    }
    return new Date()
}

/**
 * 我的页面金钱展示规则
 * ①金额小于一万元，单位【元】；金额大于等于一万元，单位【万】；
 * ②保留一位小数（不进行四舍五入缩进），小数位为0时不显示；
 * ③本规则只限【我的】页面，收益详情、钱包详情页面不受影响
 * @param number
 * @returns {{unit: string, value: string}|string}
 */
const numberDisplayRules = (number) => {
    let result = {
        unit: '元',
        value: '0'
    }
    if (isNaN(number)) {
        return result
    }
    let tempNum = number
    try {
        if (tempNum >= 100000000) {
            tempNum = Math.floor(tempNum/100000000 * 10) / 10 // 计算出保留一位小数的数值
            result.value = Number(tempNum) // 对小数位是否是0进行处理
            result.unit = '亿' // 确定单位
            return result
        } else if (number >= 10000) {
            tempNum = Math.floor(tempNum/10000 * 10) / 10
            result.value = Number(tempNum)
            result.unit = '万'
            return result
        } else {
            tempNum = Math.floor(tempNum * 10) / 10
            result.value = Number(tempNum)
            return result
        }
    } catch (e) {
        return {
            unit: '元',
            value: '0'
        }
    }
}

export {
    GLOBAL_PROJECT_TEXT,
    GLOBAL_USER_MAP,
    GLOBAL_THIRD_PARTY,
    getUserGradeInfo,
    reSortGoodsPrice,
    priceType,
    getPriceLevel,
    getPriceInfo,
    registOrigin,
    getMinOrderQuantity,
    backEndTimeWrap,
    numberDisplayRules
}
