/**
 @description: 各种通用的样式
 @date: 2016/7/8
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import {Dimensions, PixelRatio, Platform} from 'react-native'
import * as StyleSheet from './StyleSheet'
import {
    COLOR_WHITE_92,
    COLOR_WHITE_24,
    COLOR_WHITE_40,
    borderColor,
    pageBackgroundColor,
    PRIMARY_COLOR,
    COLOR_WHITE_60
} from './Colors'

// See https://mydevice.io/devices/ for device dimensions
export const isIPhoneX = false

export const isAndroid = StyleSheet.isAndroid
export const isWeb = StyleSheet.isWeb
export const isInWechat = StyleSheet.isInWechat
export const isInApp = StyleSheet.isInApp
export const isIos = StyleSheet.isIos
export const isWebIos = StyleSheet.isWebIos

export const userDeviceIsInWechat = StyleSheet.userDeviceIsInWechat // 是否在 微信中访问 web项目
export const userDeviceIsWebAndroid = StyleSheet.userDeviceIsWebAndroid // 是否在 安卓浏览器（包含各种app内的webview）中访问web项目
export const userDeviceIsWebIos = StyleSheet.userDeviceIsWebIos // 是否在 iOS浏览器（包含各种app内的webview）中访问web项目
export const userDeviceIsInAppWebview = StyleSheet.userDeviceIsInAppWebview // 用户正在 百e云创app的webview内访问

export let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth // 这个手机 的屏幕宽度
export let windowHeight = Dimensions.get('window').height // 这个手机 的屏幕高度

if (windowHeight === 0) {
    windowHeight = 667
}

if (windowWidth > 750) {
    windowWidth = 750
}

// 页面大小变了，刷新页面重新布局
let refreshWindowTimeout = null
window.onresize = function(e) {
    let newWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (newWindowWidth !== windowWidth && (newWindowWidth < 750 || (newWindowWidth > 750 && windowWidth < 750))) {
        // 页面宽度有变化则刷新页面
        if ((Math.abs(newWindowWidth - windowWidth) * 100) / windowWidth < 3) {
            // 两次页面宽度变化在3%以内，不刷新页面
            return
        }
        clearTimeout(refreshWindowTimeout)
        refreshWindowTimeout = setTimeout(() => {
            window.location.reload()
        }, 500)
    }
}

export const PIXEL_SIZE = PixelRatio.getPixelSizeForLayoutSize(1) // 像素密度
export const IS_IN_PAD = windowWidth > 550 // 是否在pad中使用
export const IS_IN_TOUCH_PC = PIXEL_SIZE <= 1.8 // 是否在触控一体机上用

export let appScale = Math.min(windowWidth, 750) / 375 // 这个手机 相对于 375宽 的比例 - iPhone 6
// web 最大宽度750

export let textScale = Math.min(windowWidth / 375, 1.3) // 不同手机

export let TRUE_ONE_PIXEL = textScale < appScale ? 1 : 1 / PixelRatio.get()
if (PIXEL_SIZE >= 3) {
    TRUE_ONE_PIXEL = 1
}
if (IS_IN_PAD) {
    textScale = appScale
}

export const borderWidth = TRUE_ONE_PIXEL
export const bottomBarHeight = isIPhoneX ? 70 : 50
export const bottomTabBarHeight = isIPhoneX ? 85 : 50 // 首页 tab 高一些

const STYLE = StyleSheet.create({
    container: {
        height: '100%',
        backgroundColor: '#fff'
    },
    container_bg: {
        height: '100%',
        backgroundColor: pageBackgroundColor
    },
    h1: {
        fontSize: tSize(20),
        color: COLOR_WHITE_24,
        lineHeight: tSize(28),
        fontWeight: 'bold',
        backgroundColor: 'transparent'
    },
    h2: {
        fontSize: tSize(18),
        color: COLOR_WHITE_24,
        lineHeight: tSize(25),
        fontWeight: 'bold',
        backgroundColor: 'transparent'
    },
    h3: {
        fontSize: tSize(16),
        color: COLOR_WHITE_24,
        lineHeight: tSize(23),
        fontWeight: 'bold',
        backgroundColor: 'transparent'
    },
    p: {
        fontSize: tSize(15),
        color: COLOR_WHITE_24,
        lineHeight: tSize(21),
        backgroundColor: 'transparent'
    },
    font: {
        fontSize: tSize(13),
        color: COLOR_WHITE_24,
        backgroundColor: 'transparent'
    },
    em: {
        fontSize: tSize(13),
        color: COLOR_WHITE_24,
        lineHeight: tSize(18),
        backgroundColor: 'transparent'
    },
    small: {
        fontSize: tSize(12),
        color: COLOR_WHITE_24,
        lineHeight: tSize(16),
        backgroundColor: 'transparent'
    },
    split_line: {
        height: TRUE_ONE_PIXEL,
        backgroundColor: borderColor
    },
    split_line_bg: {
        height: TRUE_ONE_PIXEL,
        backgroundColor: pageBackgroundColor
    },
    bottomBar: {
        height: bottomBarHeight,
        flexDirection: 'row',
        backgroundColor: '#fff',
        borderTopColor: COLOR_WHITE_92,
        borderTopWidth: TRUE_ONE_PIXEL,
        iPhoneX: {
            paddingBottom: 20
        }
    },
    form_bottom_button: {
        margin: 15,
        marginVertical: 30
    },
    page_bottom_btn: {
        flex: 1,
        borderRadius: 0,
        height: 50,
        iPhoneX: {
            height: 75,
            paddingBottom: 25
        }
    },
    forIPhoneX: {
        height: 0,
        iPhoneX: {
            height: 14,
            backgroundColor: '#f09430'
        }
    },
    form_area: {
        marginTop: 15,
        backgroundColor: '#fff',
        paddingHorizontal: 15
    },
    form_area2: {
        marginTop: 15,
        backgroundColor: '#fff'
    },
    form_area3: {
        backgroundColor: '#fff'
    },
    form_area4: {
        padding: 15,
        backgroundColor: '#fff',
        position: 'relative'
    },
    form_area5: {
        paddingHorizontal: 15,
        backgroundColor: '#fff'
    },
    m10: {
        margin: tSize(10)
    },
    m15: {
        margin: tSize(15)
    },
    ml5: {
        marginLeft: tSize(5)
    },
    ml10: {
        marginLeft: tSize(10)
    },
    ml15: {
        marginLeft: tSize(15)
    },
    ml20: {
        marginLeft: tSize(20)
    },
    mr10: {
        marginRight: tSize(10)
    },
    mr15: {
        marginRight: tSize(15)
    },
    mr20: {
        marginRight: tSize(20)
    },
    mt5: {
        marginTop: tSize(5)
    },
    mt10: {
        marginTop: tSize(10)
    },
    mt15: {
        marginTop: tSize(15)
    },
    mt20: {
        marginTop:tSize(20)
    },
    mb5: {
        marginBottom: tSize(5)
    },
    mb10: {
        marginBottom: tSize(10)
    },
    mb15: {
        marginBottom: tSize(15)
    },
    mh5: {
        marginHorizontal: tSize(5)
    },
    mh10: {
        marginHorizontal: tSize(10)
    },
    mh15: {
        marginHorizontal: tSize(15)
    },
    mv5: {
        marginVertical: tSize(5)
    },
    mv10: {
        marginVertical: tSize(10)
    },
    mv15: {
        marginVertical: tSize(15)
    },
    p5: {
        padding: tSize(5)
    },
    p10: {
        padding: tSize(10)
    },
    p15: {
        padding: tSize(15)
    },
    pl5: {
        paddingLeft: tSize(5)
    },
    pl10: {
        paddingLeft: tSize(10)
    },
    pl15: {
        paddingLeft: tSize(15)
    },
    pt5: {
        paddingTop: tSize(5)
    },
    pt10: {
        paddingTop: tSize(10)
    },
    pt15: {
        paddingTop: tSize(15)
    },
    pb5: {
        paddingBottom: tSize(5)
    },
    pb10: {
        paddingBottom: tSize(10)
    },
    pb15: {
        paddingBottom: tSize(15)
    },
    ph5: {
        paddingHorizontal: tSize(5)
    },
    ph10: {
        paddingHorizontal: tSize(10)
    },
    ph15: {
        paddingHorizontal: tSize(15)
    },
    pv5: {
        paddingVertical: tSize(5)
    },
    pv10: {
        paddingVertical: tSize(10)
    },
    pv15: {
        paddingVertical: tSize(15)
    },
    flexItemCenter: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    order_area_wrap: {
        backgroundColor: '#fff',
        paddingHorizontal: 15,
        paddingVertical: 5,
        marginBottom: 15,
        paddingBottom: 5
    },
    order_bottom_area_wrap: {
        backgroundColor: '#fff',
        paddingHorizontal: 15,
        paddingVertical: 10,
        marginBottom: 15
    },
    partTitle: {
        fontSize: tSize(14),
        color: COLOR_WHITE_40,
        height: tSize(40),
        lineHeight: tSize(40),
        paddingHorizontal: tSize(15),
        android: {
            lineHeight: tSize(14),
            textAlignVertical: 'center'
        }
    },
    // 字重
    fontLight: {
        fontWeight: '100'
    },
    fontRegular: {
        fontWeight: 'normal'
    },
    fontMedium: {
        fontWeight: 'bold'
    },
    fontSemibold: {
        fontWeight: '900'
    },
    // 字体大小 f12 f13 ...
    ...[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 28, 30, 45].reduce(
        (res, item) =>
            Object.assign(res, {
                [`f${item}`]: {
                    fontSize: tSize(item)
                }
            }),
        {}
    ),
    row: {
        flexDirection: 'row'
    },
    flex: {
        flex: 1
    },
    jc: {
        justifyContent: "center"
    },
    ac: {
        alignItems: "center"
    },
    jcBetween: {
        justifyContent: 'space-between'
    },
    fcPrimary: {
        color: PRIMARY_COLOR
    },
    fcPrimaryBlack: {
        color: COLOR_WHITE_24
    },
    fcWhite: {
        color: '#fff'
    },
    fc999: {
        color: COLOR_WHITE_60
    },
    fc666: {
        color: COLOR_WHITE_40
    },
    fc333: {
        color: COLOR_WHITE_24
    },
    fb: {
        fontWeight: "bold",
    },
})
export default STYLE

/**
 * 返回最合适当前屏幕的大小
 * @param num
 * @param small - 优化高度计算
 * @returns {Number}
 */
export function tSize(num, small) {
    if (num <= 2) {
        return num
    }
    let scale = textScale

    if (small) {
        scale = Math.min(scale, 1.3)
    }
    return num ? parseInt(num * scale, 10) : num
}

export function tSizeSm(num) {
    return tSize(num, true)
}
