/**
 @description: 微信相关api
 @date: 2018/4/18
 @author: 雷利(Rayleight@baie.com.cn)
 */
import {R_GET} from './index'
import {isInWechat, isWebIos} from '../VUI/VStyle'
import {saveItem} from './Storage'
import {JSSDK_SIGNATURE, NODE_WECHAT_AUTH, WECHAT_ACCESSTOKEN} from '../constants/FetchUrls'
import {GLOBAL_THIRD_PARTY} from '../project_config/global_config'
import {getProjectText} from './getProjetcBind'

export function isWXAppInstalled() {
    return new Promise(function(resolve, reject) {
        if (isInWechat) {
            resolve(true)
        } else {
            reject()
        }
    })
}

/**
 * 触发微信登录
 * @param type - [0,1,2] 0表示登录，1表示设置中的绑定微信 2表示绑定完直接访问早团
 * @param params - from、returnUrl
 * @returns {Promise}
 */
export function sendAuthRequest(type = 0, params = {}) {
    const {authorizeType = 'MengQi'} = params

    const returnUrlList = [`${window.location.origin}/wechatLogin`, `${window.location.origin}/accountBindWechat`]
    saveItem('WECHAT_LOGIN_PARAM', params).catch(e => {
        // console.log('WECHAT_LOGIN_PARAM', e)
    })
    return new Promise(function(resolve, reject) {
        R_GET(NODE_WECHAT_AUTH, {
            authorizeType,
            returnUrl: returnUrlList[type],
            scope: 'snsapi_userinfo'
        })
            .then(result => {
                window.location.replace(result.oauthUrl)
                resolve() // 本来没有resolve 但是为了解决 拒绝授权 页面无法感知修改状态才加上resolve
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 发给给朋友 -- web没用
 * @param data
 */
export function shareToSession(data) {}

/**
 * 分享到朋友圈 -- web没用
 */
export function shareToTimeLine(data) {}

/**
 * 分享带上app的后缀
 */
export function appendAppInfo(url) {
    if (url && url.match) {
        if (url.match(/sf=/) === null) {
            if (url.match(/\?/) !== null) {
                url += '&'
            } else {
                url += '?'
            }
            url += 'sf=w'
        }
    }
    return url
}

/** ******* web微信sdk1.4分享重构 ******** */
// import { setShareConfig } from '../utils/WechatApi'
//
// setShareConfig({
//     title: 'xxx',
//     link: 'xxx',
//     imgUrl: 'xxx',
//     desc: 'xxx'
// })

// 干掉lint报错
const {wx} = global
// 默认分享参数
const defaultConfig = {
    imgUrl: 'https://cdn.meiguipai.net/baieweb/assets/images/touch-icon-ipad-retina.png',
    desc: `${getProjectText('app名称')}大健康移动社交电商，汇集全球精品百里挑一，为您精选的云商城。`
}
// 分享路径黑名单
const routeBlackList = []
// 设置默认分享
const setDefaultShare = () => {
    _wxShare({
        title: global.document.title,
        link: global.location.href,
        imgUrl: defaultConfig.imgUrl,
        desc: defaultConfig.desc
    })
}

/**
 * 签名
 * @param {boolean} firstSign 是否第一次签名 在这里判断新版api客户端是否支持
 */
const wechatSign = firstSign =>
    new Promise(async (resolve, reject) => {
        const res = await R_GET(WECHAT_ACCESSTOKEN, {
            urls: window.location.href
        })

        const {appid, timestamp, nonceStr, signature} = res.data
        wx.config({
            debug: false,
            appId: appid,
            nonceStr: nonceStr,
            signature,
            timestamp,
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'showOptionMenu',
                'hideOptionMenu'
            ]
        })

        wx.ready(async () => {
            if (firstSign) {
                global.__WECHAT_SUPPORT_NEW_API__ = await new Promise(resolve => {
                    wx.checkJsApi({
                        jsApiList: ['updateAppMessageShareData'],
                        success: res => {
                            resolve(res.updateAppMessageShareData)
                        }
                    })
                })
            }
            // console.log('config接口注入权限验证配置成功')
            resolve('config接口注入权限验证配置成功')
        })

        wx.error(err => {
            // console.log(`微信签名错误:${err.errMsg}`)
        })
    })

// 检查客户端是否支持新api 调用相应方法
const _wxShare = shareConfig => {
    if (
        (GLOBAL_THIRD_PARTY.WeChat.hideOptions && !shareConfig?.showShare) ||
        routeBlackList.includes(global.location.pathname)
    ) {
        wx.hideOptionMenu()
    } else {
        const isSupportNewApi = global.__WECHAT_SUPPORT_NEW_API__ === true
        wx[isSupportNewApi ? 'updateAppMessageShareData' : 'onMenuShareAppMessage'](shareConfig)
        wx[isSupportNewApi ? 'updateTimelineShareData' : 'onMenuShareTimeline'](shareConfig)
        wx.showOptionMenu()
    }
}

/**
 * 检查微信签名
 * 为了处理安卓每次跳转重新签名 在navigator.js history回调再次调用
 * @param {boolean} firstSign 是否第一次需要发起签名
 */
export const checkWxSdkSign = async firstSign => {
    if (!isInWechat) {
        return
    }

    if (global.__WECHAT_IOS_SIGN__ && isWebIos) {
        setDefaultShare()
        return
    }

    // 首次签名或者安卓跳转再次签名
    wechatSign(firstSign).then(() => {
        global.__WECHAT_IOS_SIGN__ = true
        setDefaultShare()
    })
}

/**
 * 暴露给页面自定义分享内容
 * @param {object} options
 * @param {object} forWebview
 */
export const wxShare = (options, forWebview = {}) => {
    // console.log(options)

    const shareCfg = {
        title: global.document.title,
        link: global.location.href,
        ...options
    }
    if (!shareCfg.imgUrl) {
        shareCfg.imgUrl = defaultConfig.imgUrl
    }
    if (!shareCfg.desc) {
        shareCfg.desc = defaultConfig.desc
    }
    wx.ready(() => {
        const timer = setTimeout(() => {
            clearTimeout(timer)
            _wxShare(shareCfg)
        }, 300)
    })
    try {
        // 目前appwebview点击分享，会触发获取页面内 shareConfig 变量
        window.shareConfig = {...shareCfg, ...forWebview}

        // 触发 app内webview更新分享信息
        window.postMessage(
            JSON.stringify({
                type: 'shareInfo',
                shareConfig: shareCfg
            })
        )
    } catch (e) {
        console.log('e')
        console.log(e)
    }
}

/**
 * 微信静默授权
 * @param href 授权回转地址
 * @param href 授权公众号类型
 * @returns {Promise}
 */
export const wxAuth = (href, authType) => {
    return R_GET(NODE_WECHAT_AUTH, {
        authorizeType: authType, // 授权公众号
        returnUrl: href, // 授权接口支持带参数了
        scope: 'snsapi_base' // 静默授权
    })
}

export function Pay() {
    return new Promise(function(resolve, reject) {
        reject()
    })
}
