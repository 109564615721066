/**
 @description: 路由配置
 @date: 2018/3/12
 @author: 雷利(Rayleight@baie.com.cn)
 */
import React, {Component, Suspense, lazy} from 'react'
import {Switch, Redirect} from 'react-router-dom'
import {history} from '../store/configureStore'
import {PrivateRoute, Route} from './Route'
import {checkWxSdkSign} from '../utils/WechatApi'
import {updateHtmlAutoHeight} from '../utils/dom'
import NotFoundView from './Common/NotFoundView'
import ErrorBoundary from '../VUI/ErrorBoundary'
import AppPayFailedView from './Common/AppPayFailedView'
/* 爱英优选授权证书 */
const AppAuthorizationCert = lazy(() => import('./AppAuthorizationCert/AppAuthorizationCert'))
/* 媒体资源教程 */
const MediaHelpper = lazy(() => import('./../VUI/MediaHelpper'))
/* 账号转移 */
const AppAccountTransferSetp1 = lazy(() => import('./AccountTransfer/AppAccountTransferSetp1'))
const AppAccountTransferSetp2 = lazy(() => import('./AccountTransfer/AppAccountTransferSetp2'))
const AppAccountTransferSetp3 = lazy(() => import('./AccountTransfer/AppAccountTransferSetp3'))

const MiddlePageView = lazy(() => import('./Common/MiddlePageView'))

const AppContactUsView = lazy(() => import('./ContactUs/AppContactUsView'))
const AppContactUsWordView = lazy(() => import('./ContactUs/AppContactUsWordView'))
const AppFeedbackView = lazy(() => import('./ContactUs/AppFeedbackView'))
const AppFeedback2View = lazy(() => import('./ContactUs/AppFeedback2View'))
const AppFeedback3View = lazy(() => import('./ContactUs/AppFeedback3View'))
const AppReportListView = lazy(() => import('./ContactUs/AppReportListView'))
const AppReportDetailView = lazy(() => import('./ContactUs/AppReportDetailView'))
// 首页
const AppActivityListView = lazy(() => import('./mainTabs/AppTabIndexPart/AppActivityListView'))
const AppCityPartnerAgreementView = lazy(() => import('./mainTabs/AppTabIndexPart/AppCityPartnerAgreementView'))
// 报表中心
const AppFulfilNewList1View = lazy(() => import('./fulfilNew/AppFulfilNewList1View'))
const AppFulfilNewList2View = lazy(() => import('./fulfilNew/AppFulfilNewList2View'))
const AppFulfilNewCenterView = lazy(() => import('./fulfilNew/AppFulfilNewCenterView'))
const AppFulfilScoreView = lazy(() => import('./fulfilNew/AppFulfilScoreView'))
const AppFulfilNewListTimeSlotView = lazy(() => import('./fulfilNew/AppFulfilNewListTimeSlotView'))
// 邀请注册
const RegisterLeadView = lazy(() => import('./Register/RegisterLeadView'))
const GangAoRegisterView = lazy(() => import('./Register/GangAoRegisterView'))
const RegisterView = lazy(() => import('./Register/RegisterView'))
const RegisterPayPage = lazy(() => import('./Register/RegisterPayPage'))
const RegisterSuccessView = lazy(() => import('./Register/RegisterSuccessView'))
const DownloadAppView = lazy(() => import('./Register/DownloadAppView'))
const InvitePartnerView = lazy(() => import('./Register/InvitePartnerView'))
const AppInviteView = lazy(() => import('./Register/AppInviteView'))
const AppInviteEspecialGoodsView = lazy(() => import('./Register/AppInviteEspecialGoodsView'))
const AppInvitePay = lazy(() => import('./Register/AppInvitePay'))
const AppSelectPartnerView = lazy(() => import('./Register/AppSelectPartnerView'))
const MessageIndexView = lazy(() => import('./Message/AppMessageIndexView'))
const MessageListView = lazy(() => import('./Message/AppMessageListView'))
const MessageListCollegeView = lazy(() => import('./Message/AppMessageListCollegeView'))
const MessageDetailView = lazy(() => import('./Message/AppMessageDetailView'))
const AfficheDetailView = lazy(() => import('./Message/AppAfficheDetailView'))

const SendGuideView = lazy(() => import('./Retail/SendGuideView'))
const AccountGuideView = lazy(() => import('./Retail/AccountGuideView'))
const SendGuideViewApp = lazy(() => import('./Retail/sendGuideViewAPP'))
const AccountGuideViewApp = lazy(() => import('./Retail/AccountGuideViewApp'))

//好友
const newMessageList = lazy(() => import('./Message/newMessageList'))
const allMessageList = lazy(() => import('./Message/allMessageList'))

// 分销订单

const AppDistributionOrderView = lazy(() => import('./Distribution/AppDistributionOrderView'))
const AppDistributionBillView = lazy(() => import('./Distribution/AppDistributionBillView'))

// 收益

const AppIncomeIndexView = lazy(() => import('./Income/AppIncomeIndexView.js'))

// 钱包-- iEnglish
const AppWalletIndexView = lazy(() => import('./Wallet/AppWalletIndexView.js'))
const AppWalletBillIndex = lazy(() => import('./Wallet/AppWalletBillIndex.js'))

//
const AppSettingView = lazy(() => import('./member/AppSettingView'))
const AppMyInfoView = lazy(() => import('./member/AppMyInfoView'))
const MyAddressEditView = lazy(() => import('./member/MyAddressEditView'))
const MyPasswordTradeAddView = lazy(() => import('./member/MyPasswordTradeAddView'))
const MyPasswordLoginView = lazy(() => import('./member/MyPasswordLoginView'))
const MydealLoginView = lazy(() => import('./member/MydealLoginView'))
const MyPasswordLoginEditView = lazy(() => import('./member/MyPasswordLoginEditView'))
const MyAdvisorView = lazy(() => import('./member/MyAdvisorView'))
const MyEquityIndexView = lazy(() => import('./member/MyEquityIndexView'))
const CityCertificateView = lazy(() => import('./member/CityCertificateView'))
const AppChangeUserNameView = lazy(() => import('./member/AppChangeUserNameView'))
const AppConfirmIDView = lazy(() => import('./member/AppConfirmIDView'))
const MyPromoteIndexView = lazy(() => import('./member/MyPromoteIndexView'))
const MyMoveIndexView = lazy(() => import('./member/MyMoveIndexView'))
const BindMobileView = lazy(() => import('./member/BindMobileView'))
const DealerQueryView = lazy(() => import('./member/DealerQueryView'))
const AppMyAssetView = lazy(() => import('./wallet1/AppMyAssetView'))
const AppMyPartnerIndexView = lazy(() => import('./Partner/AppMyPartnerIndexView'))
const AppMyPartnerScoreDetailsView = lazy(() => import('./My/AppMyPartnerScoreDetailsView'))
const B2COrdersView = lazy(() => import('./B2C/AppB2COrdersView'))
const AppB2COrderDetailView = lazy(() => import('./B2C/AppB2COrderDetailView'))
const AppB2CPaySuccessView = lazy(() => import('./B2C/AppB2CPaySuccessView'))
const B2CPartnerOrderClearingFinishDetailView = lazy(() => import('./B2C/AppB2CPartnerOrderClearingFinishDetailView'))
const B2CPartnerOrderClearingDetailView = lazy(() => import('./B2C/AppB2CPartnerOrderClearingDetailView'))
const AppB2COrdersForBuyerView = lazy(() => import('./B2C/AppB2COrdersForBuyerView'))
const B2CMallManageView = lazy(() => import('./B2C/B2CMallManageView'))
const B2CProductListView = lazy(() => import('./B2C/B2CProductListView'))
const B2CShopConfigView = lazy(() => import('./B2C/B2CShopConfigView'))
const TabCartView = lazy(() => import('./Common/TabCartView'))
const B2BSendExpressListView = lazy(() => import('./B2B/AppB2BSendExpressListView'))
const LogisticsInfoView = lazy(() => import('./Common/LogisticsInfoView'))
const SetupProgressDetailView = lazy(() => import('./Common/SetupProgressDetailView'))
const B2BSendStep3View = lazy(() => import('./B2B/AppB2BSendStep3View'))
const AppB2BCancelOrderAuditDetailView = lazy(() => import('./B2B/AppB2BCancelOrderAuditDetailView'))
// const B2BSendDetailView = lazy(() => import('./B2B/B2BSendDetailView'));
// const AppB2BSendListView = lazy(() => import('./B2B/AppB2BSendListView'));
const MyStocksView = lazy(() => import('./stocks/AppMyStocksView'))
const MyStockDetailView = lazy(() => import('./stocks/AppMyStockDetailView'))
const AppHostingStocksDetailView = lazy(() => import('./stocks/AppHostingStocksDetailView'))
const AppB2BCartView = lazy(() => import('./OrderGoods/AppB2BCartView'))
const AppB2BOrderConfirmView = lazy(() => import('./OrderGoods/AppB2BOrderConfirmView'))
const AppStarOrderConfirmView = lazy(() => import('./OrderGoods/AppStarOrderConfirmView'))
const B2BOrderListView = lazy(() => import('./OrderGoods/AppB2BOrderListView'))
// const B2BOrderDetailView = lazy(() => import('./OrderGoods/B2BOrderDetailView'));
const B2BRefundDetailView = lazy(() => import('./OrderGoods/B2BRefundDetailView'))
const EvaluateIndexView = lazy(() => import('./member/EvaluateIndexView'))
const VExperienceIndexView = lazy(() => import('./member/VExperienceIndexView'))
const VExperienceAwardView = lazy(() => import('./member/VExperienceAwardView'))
// 分笔支付
const AppOperatePayIndexView = lazy(() => import('./OperatePay/AppOperatePayIndexView'))
const AppOperatePayWebView = lazy(() => import('./OperatePay/AppOperatePayWebView'))
const AppOperateMiddleView = lazy(() => import('./OperatePay/AppOperateMiddleView'))
const AppOperatePayListView = lazy(() => import('./OperatePay/AppOperatePayListView'))
const InviteMiddle = lazy(() => import('./invite/InviteMiddle'))
const InviteAuditView = lazy(() => import('./invite/InviteAuditView'))
const MyPartnerListView = lazy(() => import('./member/MyPartnerListView'))
const MyFriendListView = lazy(() => import('./member/MyFrindeView'))
const MyAssistant = lazy(() => import('./member/MyAssistant'))
const MyPartnerSubSListView = lazy(() => import('./member/MyPartnerSubSListView'))
const MyPartnerDetailView = lazy(() => import('./member/MyPartnerDetailView'))
// 新零售
const AppRetailIndexNewView = lazy(() => import('./Retail/AppRetailIndexNewView'))

const AppRetailSendDetailView = lazy(() => import('./Retail/AppRetailSendDetailView'))

const AppRetailListNewView = lazy(() => import('./Retail/AppRetailListNewView'))
const AppRetailConfirmView = lazy(() => import('./Retail/AppRetailConfirmView'))
const RetailConfirmQrCodeView = lazy(() => import('./Retail/RetailConfirmQrCodeView'))
// const AppMallNewView = lazy(() => import('./Retail/AppMallNewView'));
const AppMallForNewYearView = lazy(() => import('./Retail/AppMallForNewYearView'))
// const AppMallMiddleView = lazy(() => import('./Retail/AppMallMiddleView'))
// const AppMallActivityView = lazy(() => import('./Retail/AppMallActivityView'))
const AppReplaceActivityView = lazy(() => import('./Retail/AppReplaceActivityView'))
const AppMallCollectionListView = lazy(() => import('./Retail/AppMallCollectionListView'))
const AppMallBeautyListView = lazy(() => import('./Retail/AppMallBeautyListView'))
const AppMallGoodsListView = lazy(() => import('./Retail/AppMallGoodsListView'))
const AppB2CGoodsDetailView = lazy(() => import('./Retail/AppB2CGoodsDetailView'))
const AppB2CConfirmOrderView = lazy(() => import('./Retail/AppB2CConfirmOrderView'))
const AppB2BSendListView = lazy(() => import('./Retail/AppB2BSendListView'))
const AppBianLaOrderListView = lazy(() => import('./Retail/AppBianLaOrderListView'))
const AppSelfBuyingGoodsListView = lazy(() => import('./Retail/AppSelfBuyingGoodsListView'))
const AppSelfBuyingGoodsDetailView = lazy(() => import('./Retail/AppSelfBuyingGoodsDetailView'))
const AppSelfBuyingConfirmOrderView = lazy(() => import('./Retail/AppSelfBuyingConfirmOrderView'))
const AppSelfBuyingOrderSuccessView = lazy(() => import('./Retail/AppSelfBuyingOrderSuccessView'))
const AppB2BSendDetailView = lazy(() => import('./Retail/AppB2BSendDetailView'))
const AppDaPeiGoodsListView = lazy(() => import('./Retail/AppDaPeiGoodsListView'))
const AppDaPeiGoodsDetailView = lazy(() => import('./Retail/AppDaPeiGoodsDetailView'))
const AppDaPeiConfirmOrderView = lazy(() => import('./Retail/AppDaPeiConfirmOrderView'))
const AppActivitiesOfInvoiceView = lazy(() => import('./Retail/AppActivitiesOfInvoiceView'))
const AppPromotionDetailView = lazy(() => import('./Retail/AppPromotionDetailView'))
const AppIEnglishActivateListView = lazy(() => import('./Retail/AppIEnglishActivateListView'))
const AppIEnglishDetailView = lazy(() => import('./Retail/AppIEnglishDetailView'))
const MainTabsView = lazy(() => import('./mainTabs/MainTabsView'))
const AppTabMyCView = lazy(() => import('./mainTabs/AppTabMyCView'))
const AppCommonMiddle = lazy(() => import('./mainTabs/AppCommonMiddle'))
const LoginView = lazy(() => import('./Login/LoginView'))
const PhoneLoginView = lazy(() => import('./Login/PhoneLoginView'))
const AppNewLoginView = lazy(() => import('./Login/AppNewLoginView'))
const LoginUseWechatView = lazy(() => import('./Login/LoginUseWechatView'))
const AccountBindWechatView = lazy(() => import('./Login/AccountBindWechatView'))
const IForgetView = lazy(() => import('./Login/IForgetView'))
const PhoneBindWechatView = lazy(() => import('./Login/PhoneBindWechatView'))
const AddressSelect = lazy(() => import('./member/AppAddressSelectView'))
const AppImperfectInformationView = lazy(() => import('./member/AppImperfectInformationView'))
const AppReplaceGoodsNewIndexView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsNewIndexView'))
const AppReplaceGoodsNewIntroView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsNewIntroView'))
const AppReplaceConfirmListView = lazy(() => import('./ReplaceGoods/AppReplaceConfirmListView'))
const AppReplaceGoodsRecordListView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsRecordListView'))
const AppReplaceGoodsRecordDetailView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsRecordDetailView'))
const AppReplaceSendView = lazy(() => import('./ReplaceGoods/AppReplaceSendView'))
const AppReplaceBackDetailView = lazy(() => import('./ReplaceGoods/AppReplaceBackDetailView'))
const AppReplaceBackListView = lazy(() => import('./ReplaceGoods/AppReplaceBackListView'))
const AppReplaceMiddleView = lazy(() => import('./ReplaceGoods/AppReplaceMiddleView'))
const AppReplaceGoodSuccessView = lazy(() => import('./ReplaceGoods/AppReplaceGoodSuccessView'))
const AppReplaceGoodsDetailView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsDetailView'))
const AppReplaceGoodsNewIntroForSendView = lazy(() => import('./ReplaceGoods/AppReplaceGoodsNewIntroForSendView'))
const B2BGoodsDetailView = lazy(() => import('./B2B/AppB2BGoodsDetailView'))
const RewardIndexView = lazy(() => import('./RetailReward/RewardIndexView'))
const RewardCalcView = lazy(() => import('./RetailReward/RewardCalcView'))
const RetailRewardIndexView = lazy(() => import('./RetailReward/RetailRewardIndexView'))
const RetailRewardDetailView = lazy(() => import('./RetailReward/RetailRewardDetailView'))
const RetailRewardTaskDetailView = lazy(() => import('./RetailReward/RetailRewardTaskDetailView'))
const RetailTaskListView = lazy(() => import('./RetailReward/RetailTaskListView'))
const RetailTaskUndoneView = lazy(() => import('./RetailReward/RetailTaskUndoneView'))
const RetailTaskDetailView = lazy(() => import('./RetailReward/RetailTaskDetailView'))
const RetailTaskClearingInfoView = lazy(() => import('./RetailReward/RetailTaskClearingInfoView'))
const RetailTaskUnclearDetailView = lazy(() => import('./RetailReward/RetailTaskUnclearDetailView'))
const RetailProvideListView = lazy(() => import('./RetailReward/RetailProvideListView'))
const RetailMoveIntroView = lazy(() => import('./RetailReward/RetailMoveIntroView'))
const RetailMoveDesView = lazy(() => import('./RetailReward/RetailMoveDesView'))
const AppRebateIndexView = lazy(() => import('./Rebate/AppRebateIndexView'))
const AppRebateMonthListView = lazy(() => import('./Rebate/AppRebateMonthListView'))
const AppRebateMonthDetailView = lazy(() => import('./Rebate/AppRebateMonthDetailView'))
const AppRebateMonthServiceListView = lazy(() => import('./Rebate/AppRebateMonthServiceListView'))
const AppRebateMonthServiceDetailView = lazy(() => import('./Rebate/AppRebateMonthServiceDetailView'))
const AppRebateSeasonListView = lazy(() => import('./Rebate/AppRebateSeasonListView'))
const AppRebateSeasonDetailView = lazy(() => import('./Rebate/AppRebateSeasonDetailView'))
const AppRebateYearListView = lazy(() => import('./Rebate/AppRebateYearListView'))
const B2BOrderDetailView = lazy(() => import('./OrderGoods/AppB2BOrderDetailView'))
const B2BGroupOrderDetailView = lazy(() => import('./OrderGoods/AppB2BGroupOrderDetailView'))
const B2BPartnerOrderListView = lazy(() => import('./OrderGoods/AppB2BPartnerOrderListView'))
const RenewOrderView = lazy(() => import('./OrderGoods/RenewOrderView/RenewOrderView'))
const B2BReceiveOrderListView = lazy(() => import('./OrderGoods/AppB2BReceiveOrderListView'))
const APPB2BReceiveDetailView = lazy(() => import('./OrderGoods/AppB2BReceiveDetailView'))
const OrderDetailView = lazy(() => import('./OrderGoods/RenewOrderView/OrderDetailView'))
const ChooseAccount = lazy(() => import('./OrderGoods/RenewOrderView/ChooseAccount'))
const AppB2BReceiveListView = lazy(() => import('./OrderGoods/AppB2BReceiveListView'))
const B2BPartnerOrderDetailView = lazy(() => import('./OrderGoods/AppB2BPartnerOrderDetailView'))
const AppB2BPartnerOrderClearingDetailView = lazy(() => import('./OrderGoods/AppB2BPartnerOrderClearingDetailView'))
const AppB2BOrderRepairConfirmView = lazy(() => import('./OrderGoods/AppB2BOrderRepairConfirmView'))
const AppB2BPartnerOrderClearingFinishDetailView = lazy(() =>
    import('./OrderGoods/AppB2BPartnerOrderClearingFinishDetailView')
)
const AppB2BPartnerOrderClearingFinishDetailForZTView = lazy(() =>
    import('./OrderGoods/AppB2BPartnerOrderClearingFinishDetailForZTView')
)
const OpenStarView = lazy(() => import('./OrderGoods/OpenStarView'))
const AppReplenishmentConfirmForKAndJView = lazy(() => import('./OrderGoods/AppReplenishmentConfirmForKAndJView'))
const AppReplaceRepairListView = lazy(() => import('./OrderGoods/AppReplaceRepairListView'))
//
// const IIACHInfoView = lazy(() => import('./IIACH/InfoView'));
// const IIACHAuthView = lazy(() => import('./IIACH/AuthView'));
const AuthDetailView = lazy(() => import('./IIACH/AuthDetailView'))
const RankListView = lazy(() => import('./member/RankListView'))
// 新订货区
const AppB2BNewOrderGoodsView = lazy(() => import('./b2bOrderPlace/AppB2BNewOrderGoodsView'))
const SignAgreement = lazy(() => import('./b2bOrderPlace/SignAgreement'))
const AppB2BLevelUpConfirmView = lazy(() => import('./b2bOrderPlace/AppB2BLevelUpConfirmView'))
const AppNewUserLevelUpConfirmView = lazy(() => import('./b2bOrderPlace/AppNewUserLevelUpConfirmView')) // 新增新用户注册或者登录之后跳转到的付费页面
const AppInviteEspecialGoodsConfirmView = lazy(() => import('./b2bOrderPlace/AppInviteEspecialGoodsConfirmView'))
const AppInviteMiddleView = lazy(() => import('./Register/AppInviteMiddleView'))
const AppB2BOrderSelectView = lazy(() => import('./b2bOrderPlace/AppB2BOrderSelectView'))
const AppNormalSelectCartView = lazy(() => import('./b2bOrderPlace/AppNormalSelectCartView'))
const AppTopSelectCartView = lazy(() => import('./b2bOrderPlace/AppTopSelectCartView'))
const AppB2BSelectLevelUpConfirmView = lazy(() => import('./b2bOrderPlace/AppB2BSelectLevelUpConfirmView'))
const AppB2BOrderSuccessView = lazy(() => import('./b2bOrderPlace/AppB2BOrderSuccessView'))
const AppCollocationDetailView = lazy(() => import('./b2bOrderPlace/AppCollocationDetailView'))
const AppB2BPartnerCanBuyView = lazy(() => import('./b2bOrderPlace/AppB2BPartnerCanBuyView'))
const AppB2BGoodConfirmView = lazy(() => import('./b2bOrderPlace/AppB2BGoodConfirmView'))
// 分账通钱包
const WalletConfirmNewView = lazy(() => import('./newWallet/WalletConfirmNewView'))
const AppAddBranchView = lazy(() => import('./newWallet/AppAddBranchView'))
const WalletIndexNewView = lazy(() => import('./newWallet/WalletIndexNewView'))
const WalletLastYearView = lazy(() => import('./newWallet/WalletLastYearView'))
const RechargeNewView = lazy(() => import('./newWallet/RechargeNewView'))
const RechargeSuccessView = lazy(() => import('./newWallet/RechargeSuccessView'))
const WithdrawNewView = lazy(() => import('./newWallet/WithdrawNewView'))
const WithdrawSuccessView = lazy(() => import('./newWallet/WithdrawSuccessView'))
const WalletMiddle = lazy(() => import('./newWallet/WalletMiddle'))
const BankCardNewView = lazy(() => import('./newWallet/BankCardNewView'))
const TradingPasswordChangeView = lazy(() => import('./newWallet/TradingPasswordChangeView'))
const BankCardAddView = lazy(() => import('./newWallet/BankCardAddView'))
const BankCardAddConfirmView = lazy(() => import('./newWallet/BankCardAddConfirmView'))
const SettleBankCardChangeSelectView = lazy(() => import('./newWallet/SettleBankCardChangeSelectView'))
const MyConsumeView = lazy(() => import('./newWallet/MyConsumeView'))
const BillListView = lazy(() => import('./newWallet/AppBillListView'))
const CombineWithdrawDetailView = lazy(() => import('./newWallet/CombineWithdrawDetailView'))
const BankSuccessView = lazy(() => import('./newWallet/BankSuccessView'))
const PayOverdueBillsView = lazy(() => import('./newWallet/PayOverdueBillsView'))
const AppPayBackListView = lazy(() => import('./newWallet/AppPayBackListView'))
const RechargeAppPayView = lazy(() => import('./newWallet/RechargeAppPayView'))
const AppRechargeBocPayView = lazy(() => import('./newWallet/AppRechargeBocPayView'))
const AppRechargeWechatSharePayTipsView = lazy(() => import('./newWallet/AppRechargeWechatSharePayTipsView'))
const ExamQuestions = lazy(() => import('../components/ExamQuestions'))
const ExamNoPassView = lazy(() => import('./member/ExamNoPassView'))
const AppExamPassView = lazy(() => import('./member/AppExamPassView'))
const AppRechargeWechatSharePayView = lazy(() => import('./newWallet/AppRechargeWechatSharePayView'))
const RechargeQuotaTipsView = lazy(() => import('./newWallet/RechargeQuotaTipsView'))
const AppTallyOrderView = lazy(() => import('./newWallet/AppTallyOrderView'))
// 年货节活动
const Activity1View = lazy(() => import('./Activity/Activity1View'))
const Activity2View = lazy(() => import('./Activity/Activity2View'))
const Activity3View = lazy(() => import('./Activity/Activity3View'))
const Activity3ListView = lazy(() => import('./Activity/Activity3ListView'))
// 中行联名卡
const BankOfChinaView = lazy(() => import('./BankOfChina/index'))
const BankOfChinaSubmitStep1View = lazy(() => import('./BankOfChina/SubmitStep1'))
const BankOfChinaSubmitStep2View = lazy(() => import('./BankOfChina/SubmitStep2'))
const BankOfChinaSubmitStep3View = lazy(() => import('./BankOfChina/SubmitStep3'))
const BankOfChinaStatusView = lazy(() => import('./BankOfChina/Status'))
const BankOfChinaReceiveGiftView = lazy(() => import('./BankOfChina/ReceiveGift'))
const BankOfChinaReceiveGiftSuccessView = lazy(() => import('./BankOfChina/ReceiveGiftSuccess'))
// 港澳台三要素认证
const NewQualificationView = lazy(() => import('./newWallet/NewQualificationView'))
const QualificationFailureView = lazy(() => import('./newWallet/QualificationFailureView'))
// 变啦监测
const CollectInfoView = lazy(() => import('./Bianla/CollectInfoView'))
const EvaluateView = lazy(() => import('./Bianla/EvaluateView'))
const MakePlanView = lazy(() => import('./Bianla/MakePlanView'))
const EvaluateResultView = lazy(() => import('./Bianla/EvaluateResultView'))
const ConfirmSchemeView = lazy(() => import('./Bianla/ConfirmSchemeView'))
const ConfirmSchemeNewView = lazy(() => import('./Bianla/ConfirmSchemeNewView'))
const FoodListSelectView = lazy(() => import('./Bianla/FoodListSelectView.js'))
// 上市权益股权
const AppListedEquityIndexView = lazy(() => import('./StockRight/AppListedEquityIndexView'))
const AppListedEquityIntentView = lazy(() => import('./StockRight/AppListedEquityIntentView'))
const AppListedHistoryEquityListView = lazy(() => import('./StockRight/AppListedHistoryEquityListView'))
const AppEquityHelpView = lazy(() => import('./StockRight/AppEquityHelpView'))
const AppHistoryEquityHelpView = lazy(() => import('./StockRight/AppHistoryEquityHelpView'))
const AppEquityOfFatTigerView = lazy(() => import('./StockRight/AppEquityOfFatTigerView'))
const AppUploadFatTigerEquityView = lazy(() => import('./StockRight/AppUploadFatTigerEquityView'))
const AppUploadHistoryEquityView = lazy(() => import('./StockRight/AppUploadHistoryEquityView'))
const AppUploadTheEquitySuccessView = lazy(() => import('./StockRight/AppUploadTheEquitySuccessView'))
const AppUploadEquityRecordsView = lazy(() => import('./StockRight/AppUploadEquityRecordsView'))
const AppStockRightIndexView = lazy(() => import('./StockRight/AppStockRightIndexView'))
const AppStockRightIntentView = lazy(() => import('./StockRight/AppStockRightIntentView'))
// 身份转移
const IdentityTransferStep1View = lazy(() => import('./IdentityTransfer/IdentityTransferStep1View'))
const IdentityTransferStep2View = lazy(() => import('./IdentityTransfer/IdentityTransferStep2View'))
const IdentityTransferStep3View = lazy(() => import('./IdentityTransfer/IdentityTransferStep3View'))
const IdentityTransferStep4View = lazy(() => import('./IdentityTransfer/IdentityTransferStep4View'))

// 微信支付中间页
const WechatPayView = lazy(() => import('./WechatPay/WechatPayView'))

// 我的客服
const AppAssistantCenterView = lazy(() => import('./Assistant/AppAssistantCenterView'))
const AppAssistantListView = lazy(() => import('./Assistant/AppAssistantListView'))
const AppAssistantLDetailView = lazy(() => import('./Assistant/AppAssistantDetailView'))

// 退货退款
const AppRefundPickerView = lazy(() => import('./Refund/AppRefundPickerView'))
const AppRequestRefundView = lazy(() => import('./Refund/AppRequestRefundView'))
const AppRefundShippingOrderDetailView = lazy(() => import('./Refund/shipping/AppRefundShippingOrderDetailView'))
const AppRefundShippingPickerView = lazy(() => import('./Refund/shipping/AppRefundShippingPickerView'))
const AppRequestShippingRefundView = lazy(() => import('./Refund/shipping/AppRequestShippingRefundView'))
const AppRefundOrderListView = lazy(() => import('./Refund/AppRefundOrderListView'))
const AppRefundOrderDetailView = lazy(() => import('./Refund/AppRefundOrderDetailView'))
const AppRefundCompanyOrderDetailView = lazy(() => import('./Refund/AppRefundCompanyOrderDetailView'))
const AppUploadCredentialsView = lazy(() => import('./Refund/AppUploadCredentialsView'))
const AppRefundProgressView = lazy(() => import('./Refund/AppRefundProgressView'))
const AppRefundShippingProgressView = lazy(() => import('./Refund/shipping/AppRefundShippingProgressView'))
const AppFillInTheLogisticsView = lazy(() => import('./Refund/AppFillInTheLogisticsView'))
const AppRefundShippingFillInTheLogisticsView = lazy(() =>
    import('./Refund/shipping/AppRefundShippingFillInTheLogisticsView')
)

// 托管
const AppHostingPickerView = lazy(() => import('./Hosting/AppHostingPickerView'))
const AppRequestHostingView = lazy(() => import('./Hosting/AppRequestHostingView'))
const AppHostingOrderListView = lazy(() => import('./Hosting/AppHostingOrderListView'))
const AppHostingOrderDetailView = lazy(() => import('./Hosting/AppHostingOrderDetailView'))
const AppUploadHostingCredentialsView = lazy(() => import('./Hosting/AppUploadHostingCredentialsView'))
const AppHostingProgressView = lazy(() => import('./Hosting/AppHostingProgressView'))
const AppHostingManagerPickerView = lazy(() => import('./Hosting/AppHostingManagerPickerView'))

// 规则中心
// const RegisterRule = lazy(() => import('./Rule/RegisterRule'))
const AppRuleCenterView = lazy(() => import('./Rule/AppRuleCenterView'))
const AppRefundRuleView = lazy(() => import('./Rule/AppRefundRuleView'))

// 糖脂分离
const AppAgreementView = lazy(() => import('./Glycolipids/AppAgreementView'))
const AppMarginMoneyView = lazy(() => import('./Glycolipids/AppMarginMoneyView'))
const AppSignSuccessView = lazy(() => import('./Glycolipids/AppSignSuccessView'))
const AppServiceTypeView = lazy(() => import('./Glycolipids/AppServiceTypeView'))
const AppDepositAccountView = lazy(() => import('./Glycolipids/AppDepositAccountView'))

//
const MyRewardListView = lazy(() => import('./reward/MyRewardListView'))
const MyRewardDetailView = lazy(() => import('./reward/MyRewardDetailView'))
const MyRewardOrderDetailView = lazy(() => import('./reward/MyRewardOrderDetailView'))

// 账号管理
const AccountManageIndexView = lazy(() => import('./AccountManage/IndexView'))
const AccountDetailListView = lazy(() => import('./AccountManage/AccountDetailListView'))
const AccountSearchView = lazy(() => import('./AccountManage/AccountSearchView'))
const TransferDetailView = lazy(() => import('./AccountManage/TransferDetailView'))
const SearchView = lazy(() => import('./member/search'))
const SearchViewPage = lazy(() => import('./member/searchView'))

//协议
const OrderGoods = lazy(() => import('./Agreement/orderGoods'))
const ServiceAgreement = lazy(() => import('./Agreement/serviceAgreement'))
const Register = lazy(() => import('./Agreement/register'))
const Useragreement = lazy(() => import('./Agreement/useragreement'))
const BehavioralNorm = lazy(() => import('./Agreement/behavioralNorm'))
const Disclaimer = lazy(() => import('./Agreement/disclaimer'))
const Privacy = lazy(() => import('./Agreement/privacy'))
const ComplianceStatement = lazy(() => import('./Agreement/ComplianceStatement'))
const Renew = lazy(() => import('./Agreement/renew'))
const Termination = lazy(() => import('./Agreement/termination'))

const ManualRefund = lazy(() => import('./manualRefund/manualRefund'))

const ReadingPartner = lazy(() => import('./readingPartner/index'))

const Email = lazy(() => import('./Email/Email'))
//实名认证

const Authentication = lazy(() => import('./Authentication/authentication'))
const AuthenticationBook = lazy(() => import('./Authentication/AuthenticationBook'))
const Agreement = lazy(() => import('./Authentication/Agreement'))
const Consignee = lazy(() => import('./Consignee/consignee'))
const ConsigneeDetail = lazy(() => import('./Consignee/consigneeDetail'))

//数据审核

const TrainAdvance = lazy(() => import('./TrainAdvance/TrainAdvance'))
const TrainAdvanceDetail = lazy(() => import('./TrainAdvance/TrainAdvanceDetail'))
const TrainAdvanceSearch = lazy(() => import('./TrainAdvance/TrainAdvanceSearch'))

//学霸有理优惠券
const DiscountCoupon = lazy(() => import('./DiscountCoupon/DiscountCouponPage'))

const Entrance = lazy(() => import('./Entrance/index')) //空白页

export default class Navigator extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.unlistenHistory = null
    }

    componentDidMount() {
        // Navigator挂载时候进行第一次微信签名
        checkWxSdkSign(true)
        updateHtmlAutoHeight()

        // history改变回调重新检查微信签名
        this.unlistenHistory = history.listen((location, action) => {
            // location is an object like window.location
            setTimeout(() => {
                // checkWxSdkSign()
                updateHtmlAutoHeight()
                global.toggleQuickMenu(true)
            }, 100)
        })
    }

    componentWillUnmount() {
        if (this.unlistenHistory) {
            this.unlistenHistory()
        }
    }

    render() {
        return (
            <div className="navigatorContainer">
                <ErrorBoundary>
                    <Suspense fallback={<PageLoading />}>
                        <Switch>
                            <Route path="/mainTabs" component={MainTabsView} />
                            <PrivateRoute path="/my" component={AppTabMyCView} />
                            <Route path="/m" component={AppCommonMiddle} />
                            {/* 登录模块 开始 */}
                            <Route path="/phoneLogin" component={PhoneLoginView} />
                            <Route path="/wechatLogin" component={LoginUseWechatView} />
                            <Route path="/accountBindWechat" component={AccountBindWechatView} />
                            <Route path="/phoneBindWechat" component={PhoneBindWechatView} />
                            {/* 登录模块 结束 */}
                            {/* 零售模块 开始 */}
                            {/* <PrivateRoute exact path="/" component={() => <Redirect to="/mainTabs?tab=index" />} /> */}
                            <PrivateRoute exact path="/" component={() => <Redirect to="/entrance" />} />
                            <Route path="/mall" component={AppMallForNewYearView} />
                            {/* <PrivateRoute exact path="/" component={MainTabsView} /> */}
                            {/* <Redirect exact from="/" to="mainTabs?tab=index" /> */}
                            {/* <PrivateRoute path="/mall" component={AppMallMiddleView} /> */}
                            <Route path="/replaceActivity" component={AppReplaceActivityView} />
                            <Route path="/mallCollectionList" component={AppMallCollectionListView} />
                            <Route path="/mallBeautyList" component={AppMallBeautyListView} />
                            <Route path="/mallGoodsList" component={AppMallGoodsListView} />
                            <PrivateRoute path="/retailList" component={AppRetailListNewView} />
                            <PrivateRoute path="/mallManage" component={B2CMallManageView} />
                            <PrivateRoute path="/b2cProductList" component={B2CProductListView} />
                            <PrivateRoute path="/b2cShopConfig" component={B2CShopConfigView} />
                            <PrivateRoute path="/b2cOrders" component={B2COrdersView} />
                            {/* 首页 */}
                            <PrivateRoute path="/activityList" component={AppActivityListView} />
                            <PrivateRoute path="/cityPartnerAgreement" component={AppCityPartnerAgreementView} />
                            {/* 商城订单 */}
                            <Route path="/b2cOrderDetail" component={AppB2COrderDetailView} />
                            <Route path="/b2cPaySuccess" component={AppB2CPaySuccessView} />
                            <PrivateRoute
                                path="/b2cOrderClearingFinishDetail"
                                component={B2CPartnerOrderClearingFinishDetailView}
                            />
                            <PrivateRoute
                                path="/b2cOrderClearingDetail"
                                component={B2CPartnerOrderClearingDetailView}
                            />
                            <PrivateRoute path="/b2cOrdersForBuyer" component={AppB2COrdersForBuyerView} />
                            <PrivateRoute path="/b2cCart" component={TabCartView} />
                            {/* 物流 */}
                            <PrivateRoute path="/progressDetail" component={SetupProgressDetailView} />
                            {/* 京东水机安装进度 */}
                            <PrivateRoute path="/b2cConfirmOrder" component={AppB2CConfirmOrderView} />
                            <PrivateRoute path="/daPeiGoodsList" component={AppDaPeiGoodsListView} />
                            <Route path="/daPeiGoodsDetail" component={AppDaPeiGoodsDetailView} />
                            <PrivateRoute path="/daPeiConfirmOrder" component={AppDaPeiConfirmOrderView} />
                            <PrivateRoute path="/activitiesOfInvoice" component={AppActivitiesOfInvoiceView} />
                            <PrivateRoute path="/promotionDetail" component={AppPromotionDetailView} />
                            <PrivateRoute path="/bianLaOrderList" component={AppBianLaOrderListView} />
                            <PrivateRoute path="/selfBuyingGoodsList" component={AppSelfBuyingGoodsListView} />
                            <PrivateRoute path="/selfBuyingGoodsDetail" component={AppSelfBuyingGoodsDetailView} />
                            <PrivateRoute path="/selfBuyingConfirmOrder" component={AppSelfBuyingConfirmOrderView} />
                            <PrivateRoute path="/selfBuyingOrderSuccess" component={AppSelfBuyingOrderSuccessView} />
                            {/* 零售模块 结束 */}
                            {/* 发货模块 开始 */}
                            {/* 发货模块 结束 */}
                            {/* 撤销取消发货 开始 */}
                            <PrivateRoute
                                path="/b2bCancelOrderAuditDetail"
                                component={AppB2BCancelOrderAuditDetailView}
                            />
                            {/* 撤销取消发货 结束 */}
                            {/* 库存模块 开始 */}
                            <PrivateRoute path="/hostingStocksDetail" component={AppHostingStocksDetailView} />
                            {/* 库存模块 结束 */}
                            {/* 订货模块 开始 */}
                            <PrivateRoute path="/b2bCart" component={AppB2BCartView} />
                            <PrivateRoute path="/b2bOrderConfirm" component={AppB2BOrderConfirmView} />
                            <PrivateRoute path="/starOrderConfirm" component={AppStarOrderConfirmView} />
                            <PrivateRoute path="/b2bRefundDetail" component={B2BRefundDetailView} />
                            {/* 订货模块 结束 */}
                            {/* 空白页 */}
                            <PrivateRoute path="/entrance" component={Entrance} />
                            <PrivateRoute
                                path="/b2bClearingFinishDetailForZT"
                                component={AppB2BPartnerOrderClearingFinishDetailForZTView}
                            />
                            <PrivateRoute path="/openStar" component={OpenStarView} />
                            <PrivateRoute
                                path="/replenishmentConfirmForKAndJ"
                                component={AppReplenishmentConfirmForKAndJView}
                            />
                            <PrivateRoute path="/replaceRepairList" component={AppReplaceRepairListView} />
                            {/* 分销模块 结束 */}
                            {/* 分笔支付 开始 */}
                            <PrivateRoute
                                path="/operateIndex"
                                key="operateIndex"
                                authType="BaieAPP"
                                component={AppOperatePayIndexView}
                            />
                            <PrivateRoute path="/operateWebView" component={AppOperatePayWebView} />
                            <PrivateRoute path="/operateMiddle" component={AppOperateMiddleView} />
                            <PrivateRoute path="/operatePayList" component={AppOperatePayListView} />
                            <PrivateRoute path="/SendGuide" component={SendGuideView} />
                            <PrivateRoute path="/SendGuideViewApp" component={SendGuideViewApp} />
                            <PrivateRoute path="/AccountGuide" component={AccountGuideView} />
                            <Route path="/SendGuideViewApp" component={SendGuideViewApp} />
                            <Route path="/AccountGuideApp" component={AccountGuideViewApp} />
                            {/* 分笔支付 结束 */}
                            {/* 好友 */}
                            <PrivateRoute path="/newMessageList" component={newMessageList} />
                            <PrivateRoute path="/allMessageList" component={allMessageList} />
                            {/* 动销任务、结算模块 开始 */}
                            <PrivateRoute path="/rewardIndex" component={RetailMoveIntroView} />
                            <PrivateRoute path="/rewardCalc" component={RewardCalcView} />
                            <PrivateRoute path="/retailRewardIndex" component={RetailRewardIndexView} />
                            <PrivateRoute path="/retailRewardDetail" component={RetailRewardDetailView} />
                            <PrivateRoute path="/retailRewardTask" component={RetailRewardTaskDetailView} />
                            <PrivateRoute path="/retailTaskList" component={RetailTaskListView} />
                            <PrivateRoute path="/retailTaskUndone" component={RetailTaskUndoneView} />
                            <PrivateRoute path="/retailTaskDetail" component={RetailTaskDetailView} />
                            <PrivateRoute path="/retailTaskClearingInfo" component={RetailTaskClearingInfoView} />
                            <PrivateRoute path="/retailTaskUnclear" component={RetailTaskUnclearDetailView} />
                            <PrivateRoute path="/retailProvideList" component={RetailProvideListView} />
                            <Route path="/retailMoveIntro" component={RetailMoveIntroView} />
                            <Route path="/retailMoveDes" component={RetailMoveDesView} />
                            <Route path="/IIACHInfo" component={RetailMoveIntroView} />
                            {/* 动销任务、结算模块 结束 */}
                            {/* //历史市场补贴模块 开始 */}
                            <PrivateRoute path="/rebate" component={AppRebateIndexView} />
                            <PrivateRoute path="/rebateMonthList" component={AppRebateMonthListView} />
                            <PrivateRoute path="/rebateMonthDetail" component={AppRebateMonthDetailView} />
                            <PrivateRoute path="/rebateMonthServiceList" component={AppRebateMonthServiceListView} />
                            <PrivateRoute
                                path="/rebateMonthServiceDetail"
                                component={AppRebateMonthServiceDetailView}
                            />
                            <PrivateRoute path="/rebateSeasonList" component={AppRebateSeasonListView} />
                            <PrivateRoute path="/rebateSeasonDetail" component={AppRebateSeasonDetailView} />
                            <PrivateRoute path="/rebateYearList" component={AppRebateYearListView} />
                            {/* 历史市场补贴模块 结束 */}
                            {/* 用户信息模块 开始 */}
                            <PrivateRoute path="/addTradePwd" component={MyPasswordTradeAddView} />
                            <PrivateRoute path="/myAdvisor" component={MyAdvisorView} />
                            <PrivateRoute path="/myMoveIndex" component={MyMoveIndexView} />
                            <PrivateRoute path="/imperfectInformation" component={AppImperfectInformationView} />
                            {/* 新订货区 开始 */}
                            <PrivateRoute path="/b2bOrderSelect" component={AppB2BOrderSelectView} />
                            <PrivateRoute path="/b2bOrderNormalCart" component={AppNormalSelectCartView} />
                            <PrivateRoute path="/b2bOrderTopCart" component={AppTopSelectCartView} />
                            <PrivateRoute path="/b2bSelectConfirm" component={AppB2BSelectLevelUpConfirmView} />
                            <PrivateRoute path="/partnerCanBuyList" component={AppB2BPartnerCanBuyView} />
                            {/* 新订货区 结束 */}
                            <PrivateRoute path="/myEquity" component={MyEquityIndexView} />
                            <PrivateRoute path="/cityCertificat" component={CityCertificateView} />
                            <PrivateRoute path="/myPromoteIndex" component={MyPromoteIndexView} />
                            <Route path="/dealerQuery" component={DealerQueryView} />
                            {/* 用户信息模块 结束 */}
                            {/* 换货区 开始 */}
                            <PrivateRoute path="/replaceGoodsIndex" component={AppReplaceGoodsNewIndexView} />
                            {/* <PrivateRoute path="/replaceGoodsIndex" component={AppReplaceGoodsIndexView}/> */}
                            <PrivateRoute path="/replaceGoodsRecordList" component={AppReplaceGoodsRecordListView} />
                            <PrivateRoute path="/replaceGoodsIntro" component={AppReplaceGoodsNewIntroView} />
                            {/* <PrivateRoute path="/replaceGoodsIntro" component={AppReplaceGoodsIntroView}/> */}
                            <PrivateRoute path="/replaceConfirmList" component={AppReplaceConfirmListView} />
                            <PrivateRoute
                                path="/replaceGoodsRecordDetail"
                                component={AppReplaceGoodsRecordDetailView}
                            />
                            <PrivateRoute path="/replaceSend" component={AppReplaceSendView} />
                            <PrivateRoute path="/replaceBackList" component={AppReplaceBackListView} />
                            <PrivateRoute path="/replaceBackDetail" component={AppReplaceBackDetailView} />
                            <PrivateRoute path="/replaceMiddle" component={AppReplaceMiddleView} />
                            <PrivateRoute path="/replaceSuccess" component={AppReplaceGoodSuccessView} />
                            <PrivateRoute path="/replaceGoodsDetail" component={AppReplaceGoodsDetailView} />
                            <PrivateRoute
                                path="/replaceGoodsIntroForSend"
                                component={AppReplaceGoodsNewIntroForSendView}
                            />
                            {/* 换货区结束 */}
                            {/* 分销模块开始 */}
                            <PrivateRoute path="/b2bGoodsDetail" component={B2BGoodsDetailView} />
                            {/* 协议 */}
                            <Route path="/orderGoods" component={OrderGoods} />
                            <Route path="/behavioralNorm" component={BehavioralNorm} />
                            <Route path="/registerAgreement" component={Register} />
                            <Route path="/useragreement" component={Useragreement} />
                            <Route path="/serviceAgreement" component={ServiceAgreement} />
                            <Route path="/disclaimer" component={Disclaimer} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/complianceStatement" component={ComplianceStatement} />
                            <Route path="/Renew" component={Renew} />
                            <Route path="/Termination" component={Termination} />
                            
                            
                            {/* 分销模块结束 */}
                            <PrivateRoute path="/customerIndex" component={InviteMiddle} />
                            <Route path="/auditInvite" component={InviteAuditView} />
                            <PrivateRoute path="/myPartnerSubS" component={MyPartnerSubSListView} />
                            <PrivateRoute path="/PartnerDetail" component={MyPartnerDetailView} />
                            {/* 钱包、支付模块 开始 */}
                            <PrivateRoute path="/myAsset" component={AppMyAssetView} />
                            {/* 钱包、支付模块 结束 */}
                            {/* 分销客户模块 开始 */}
                            <PrivateRoute path="/myPartner" component={AppMyPartnerIndexView} />
                            <PrivateRoute path="/partnerScoreDetails" component={AppMyPartnerScoreDetailsView} />
                            {/* 分销客户模块 结束 */}
                            {/* 消息模块 开始 */}
                            <PrivateRoute path="/messageIndex" component={MessageIndexView} />
                            <PrivateRoute path="/messageList" component={MessageListView} />
                            <PrivateRoute path="/messageListCollege" component={MessageListCollegeView} />
                            <PrivateRoute path="/messageDetail" component={MessageDetailView} />
                            <PrivateRoute path="/afficheDetail" component={AfficheDetailView} />
                            {/* 消息模块 结束 */}
                            {/* 联系我们 开始 */}
                            <PrivateRoute path="/connectUs" component={AppContactUsView} />
                            <PrivateRoute path="/contactUsWord" component={AppContactUsWordView} />
                            <PrivateRoute path="/feedback" component={AppFeedbackView} />
                            <PrivateRoute path="/feedback2" component={AppFeedback2View} />
                            <PrivateRoute path="/feedback3" component={AppFeedback3View} />
                            <PrivateRoute path="/reportList" component={AppReportListView} />
                            <PrivateRoute path="/reportDetail" component={AppReportDetailView} />
                            {/* 联系我们 结束 */}
                            {/* 报表中心模块 开始 */}
                            <PrivateRoute path="/fulfilNewCenter" component={AppFulfilNewCenterView} />
                            <PrivateRoute path="/fulfilNewList1" component={AppFulfilNewList1View} />
                            <PrivateRoute path="/fulfilNewList2" component={AppFulfilNewList2View} />
                            <PrivateRoute path="/fulfilScore" component={AppFulfilScoreView} />
                            <PrivateRoute path="/fulfilTimeSlot" component={AppFulfilNewListTimeSlotView} />
                            {/* 报表中心模块 结束 */}
                            {/* 股权 */}
                            <PrivateRoute path="/listedEquityIndex" component={AppListedEquityIndexView} />
                            <PrivateRoute path="/listedEquityIntent" component={AppListedEquityIntentView} />
                            <PrivateRoute path="/listedHistoryEquityList" component={AppListedHistoryEquityListView} />
                            <PrivateRoute path="/equityHelp" component={AppEquityHelpView} />
                            <PrivateRoute path="/historyEquityHelp" component={AppHistoryEquityHelpView} />
                            <PrivateRoute path="/equityOfFatTiger" component={AppEquityOfFatTigerView} />
                            <PrivateRoute path="/uploadFatTigerEquity" component={AppUploadFatTigerEquityView} />
                            <PrivateRoute path="/uploadHistoryEquity" component={AppUploadHistoryEquityView} />
                            <PrivateRoute path="/uploadTheEquitySuccess" component={AppUploadTheEquitySuccessView} />
                            <PrivateRoute path="/uploadEquityRecords" component={AppUploadEquityRecordsView} />
                            <PrivateRoute path="/stockRightIndex" component={AppStockRightIndexView} />
                            <PrivateRoute path="/stockRightIntent" component={AppStockRightIntentView} />
                            {/* 经销商邀请注册 */}
                            <Route path="/downloadApp" component={DownloadAppView} />
                            <Route path="/invitePartner" component={InvitePartnerView} />
                            <Route path="/inviteMiddle" component={AppInviteMiddleView} />
                            <PrivateRoute path="/selectPartner" component={AppSelectPartnerView} />
                            <PrivateRoute path="/evaluateIndex" component={EvaluateIndexView} />
                            <PrivateRoute path="/vExperience" component={VExperienceIndexView} />
                            <PrivateRoute path="/vAward" component={VExperienceAwardView} />
                            <PrivateRoute path="/examQuestions" component={ExamQuestions} />
                            <PrivateRoute path="/examNoPass" component={ExamNoPassView} />
                            <PrivateRoute path="/examPass" component={AppExamPassView} />
                            <PrivateRoute path="/IIACHAuthDetail" component={AuthDetailView} />
                            <PrivateRoute path="/rankList" component={RankListView} />
                            {/* 分账通 */}
                            <PrivateRoute path="/newWalletConfirm" component={WalletConfirmNewView} />
                            <PrivateRoute path="/addBranch" component={AppAddBranchView} />
                            <PrivateRoute path="/walletMiddle" component={WalletMiddle} />
                            <PrivateRoute path="/newWalletIndex" component={WalletIndexNewView} />
                            <PrivateRoute path="/walletLastYear" component={WalletLastYearView} />
                            <PrivateRoute path="/rechargeNew" key="rechargeNew" component={RechargeNewView} />
                            <PrivateRoute path="/rechargeNewSuccess" component={RechargeSuccessView} />
                            <PrivateRoute path="/withdrawNew" component={WithdrawNewView} />
                            <PrivateRoute path="/withdrawNewSuccess" component={WithdrawSuccessView} />
                            <PrivateRoute path="/newBankCard" component={BankCardNewView} />
                            <PrivateRoute path="/bankCardAdd" component={BankCardAddView} />
                            <PrivateRoute path="/bankCardAddConfirm" component={BankCardAddConfirmView} />
                            <PrivateRoute path="/newBankCardSelect" component={SettleBankCardChangeSelectView} />
                            <PrivateRoute path="/myConsume" component={MyConsumeView} />
                            <PrivateRoute path="/billList" component={BillListView} />
                            <PrivateRoute path="/combineWithdrawDetail" component={CombineWithdrawDetailView} />
                            <PrivateRoute path="/bankSuccess" component={BankSuccessView} />
                            <PrivateRoute path="/payOverdueBills" component={PayOverdueBillsView} />
                            <PrivateRoute path="/payBackList" component={AppPayBackListView} />
                            <PrivateRoute path="/rechargeAppPay" component={RechargeAppPayView} />
                            <PrivateRoute path="/rechargeBocPay" component={AppRechargeBocPayView} />
                            <PrivateRoute
                                path="/rechargeWechatSharePayTips"
                                component={AppRechargeWechatSharePayTipsView}
                            />
                            <Route
                                path="/rechargeWechatSharePay"
                                key="rechargeWechatSharePay"
                                authType="Yunchuang"
                                component={AppRechargeWechatSharePayView}
                            />
                            <Route path="/rechargeQuota" component={RechargeQuotaTipsView} />
                            <PrivateRoute path="/tallyOrder" component={AppTallyOrderView} />
                            <Route path="/activity1" component={Activity1View} />
                            <Route path="/activity2" component={Activity2View} />
                            <Route path="/activity3" component={Activity3View} />
                            <Route path="/activity3List" component={Activity3ListView} />
                            {/* 中行联名卡 */}
                            <PrivateRoute path="/boc" component={BankOfChinaView} />
                            <PrivateRoute path="/bankOfChinaSubmitStep1" component={BankOfChinaSubmitStep1View} />
                            <PrivateRoute path="/bankOfChinaSubmitStep2" component={BankOfChinaSubmitStep2View} />
                            <PrivateRoute path="/bankOfChinaSubmitStep3" component={BankOfChinaSubmitStep3View} />
                            <PrivateRoute path="/bocStatus" component={BankOfChinaStatusView} />
                            <PrivateRoute path="/receiveGift" component={BankOfChinaReceiveGiftView} />
                            <PrivateRoute path="/receiveGiftSuccess" component={BankOfChinaReceiveGiftSuccessView} />
                            <PrivateRoute path="/newQualification" component={NewQualificationView} />
                            <PrivateRoute path="/qualificationFailure" component={QualificationFailureView} />
                            <PrivateRoute path="/bianlaCollectInfo" component={CollectInfoView} />
                            <PrivateRoute path="/bianlaEvaluate" component={EvaluateView} />
                            <PrivateRoute path="/bianlaMakePlan" component={MakePlanView} />
                            <PrivateRoute path="/bianlaEvaluateResult" component={EvaluateResultView} />
                            <PrivateRoute path="/bianlaConfirmScheme" component={ConfirmSchemeView} />
                            <PrivateRoute path="/bianlaConfirmSchemeNew" component={ConfirmSchemeNewView} />
                            <PrivateRoute path="/foodListSelect" component={FoodListSelectView} />
                            {/* 身份转移 */}
                            <PrivateRoute path="/identityTransferStep1" component={IdentityTransferStep1View} />
                            <PrivateRoute path="/identityTransferStep2" component={IdentityTransferStep2View} />
                            <PrivateRoute path="/identityTransferStep3" component={IdentityTransferStep3View} />
                            <PrivateRoute path="/identityTransferStep4" component={IdentityTransferStep4View} />
                            {/* 我的客服 */}
                            <PrivateRoute path="/assistantCenter" component={AppAssistantCenterView} />
                            <PrivateRoute path="/assistantList" component={AppAssistantListView} />
                            <Route path="/assistantDetail" component={AppAssistantLDetailView} />
                            {/* 退货退款 */}
                            <PrivateRoute path="/refundPicker" component={AppRefundPickerView} />
                            <PrivateRoute path="/refundShippingPicker" component={AppRefundShippingPickerView} />
                            <PrivateRoute path="/requestRefund" component={AppRequestRefundView} />
                            <PrivateRoute path="/requestShippingRefund" component={AppRequestShippingRefundView} />
                            <PrivateRoute path="/refundOrderList" component={AppRefundOrderListView} />
                            <PrivateRoute
                                path="/refundShippingOrderDetail"
                                component={AppRefundShippingOrderDetailView}
                            />
                            <PrivateRoute path="/refundOrderDetail" component={AppRefundOrderDetailView} />
                            <PrivateRoute
                                path="/refundCompanyOrderDetail"
                                component={AppRefundCompanyOrderDetailView}
                            />
                            <PrivateRoute path="/uploadCredentials" component={AppUploadCredentialsView} />
                            <PrivateRoute path="/refundProgress" component={AppRefundProgressView} />
                            <PrivateRoute path="/refundShippingProgress" component={AppRefundShippingProgressView} />
                            <PrivateRoute path="/fillInTheLogistics" component={AppFillInTheLogisticsView} />
                            <PrivateRoute
                                path="/refundShippingFillInTheLogistics"
                                component={AppRefundShippingFillInTheLogisticsView}
                            />
                            <PrivateRoute path="/ReadingPartner" component={ReadingPartner} />
                            {/* 托管 */}
                            <PrivateRoute path="/hostingPicker" component={AppHostingPickerView} />
                            <PrivateRoute path="/requestHosting" component={AppRequestHostingView} />
                            <PrivateRoute path="/hostingOrderList" component={AppHostingOrderListView} />
                            <PrivateRoute path="/hostingOrderDetail" component={AppHostingOrderDetailView} />
                            <PrivateRoute
                                path="/uploadHostingCredentials"
                                component={AppUploadHostingCredentialsView}
                            />
                            <PrivateRoute path="/hostingProgress" component={AppHostingProgressView} />
                            <PrivateRoute path="/hostingManagerPicker" component={AppHostingManagerPickerView} />
                            {/* 微信支付中间页 */}
                            <PrivateRoute
                                path="/wechatPayYC"
                                key="wechatPayYC"
                                authType="Yunchuang"
                                component={WechatPayView}
                            />
                            <PrivateRoute
                                path="/wechatPayMQ"
                                key="wechatPayMQ"
                                authType="MengQi"
                                component={WechatPayView}
                            />
                            <PrivateRoute
                                path="/wechatPayZT"
                                key="wechatPayZT"
                                authType="ZaoTuan"
                                component={WechatPayView}
                            />
                            <PrivateRoute
                                path="/wechatPayBaie"
                                key="wechatPayBaie"
                                authType="BaieAPP"
                                component={WechatPayView}
                            />
                            <Route path="/hub" key="hub" component={MiddlePageView} />
                            <Route path="/ruleCenter" component={AppRuleCenterView} />
                            {/* <Route path="/RegisterRule" component={RegisterRule}/> */}
                            <Route path="/refundRule" component={AppRefundRuleView} />
                            <Route path="/payFailed" component={AppPayFailedView} />
                            <PrivateRoute path="/agreement" component={AppAgreementView} />
                            <PrivateRoute path="/marginMoney" component={AppMarginMoneyView} />
                            <PrivateRoute path="/signSuccess" component={AppSignSuccessView} />
                            <PrivateRoute path="/serviceType" component={AppServiceTypeView} />
                            <PrivateRoute path="/depositAccount" component={AppDepositAccountView} />
                            /** * ██╗███████╗███╗ ██╗ ██████╗ ██╗ ██╗███████╗██╗ ██╗ * ██║██╔════╝████╗ ██║██╔════╝ ██║
                            ██║██╔════╝██║ ██║ * ██║█████╗ ██╔██╗ ██║██║ ███╗██║ ██║███████╗███████║ * ██║██╔══╝
                            ██║╚██╗██║██║ ██║██║ ██║╚════██║██╔══██║ * ██║███████╗██║
                            ╚████║╚██████╔╝███████╗██║███████║██║ ██║ * ╚═╝╚══════╝╚═╝ ╚═══╝ ╚═════╝
                            ╚══════╝╚═╝╚══════╝╚═╝ ╚═╝ * iEnglish路由开始 */
                            {/* 登录相关开始 */}
                            <Route path="/login" component={AppNewLoginView} />
                            <Route path="/iForget" component={IForgetView} />
                            {/* 登录相关结束 */}
                            {/* 订货开始 */}
                            <PrivateRoute path="/b2bOrderGoods" component={AppB2BNewOrderGoodsView} />
                            <PrivateRoute path="/signAgreement" component={SignAgreement} />
                            <PrivateRoute path="/collocationDetail" component={AppCollocationDetailView} />
                            <PrivateRoute path="/b2BGoodConfirm" component={AppB2BGoodConfirmView} />
                            <PrivateRoute path="/b2bOrderConfirmLevelUpSettled" component={AppB2BLevelUpConfirmView} />
                            <PrivateRoute path="/b2bNewUserConfirmLevelUpSettled" component={AppNewUserLevelUpConfirmView} />
                            <PrivateRoute path="/b2bOrderList" component={B2BOrderListView} />
                            <PrivateRoute path="/b2bGroupOrderDetail" component={B2BGroupOrderDetailView} />
                            <PrivateRoute path="/b2bOrderDetail" component={B2BOrderDetailView} />
                            <PrivateRoute path="/b2bOrderSuccess" component={AppB2BOrderSuccessView} />
                            {/* 订货结束 */}
                            {/* 帮助中心开始 */}
                            <PrivateRoute path="/assistantCenter" component={AppAssistantCenterView} />
                            <PrivateRoute path="/assistantList" component={AppAssistantListView} />
                            <Route path="/assistantDetail" component={AppAssistantLDetailView} />
                            {/* 帮助中心结束 */}
                            {/* 实名认证*/}
                            <PrivateRoute path="/Authentication" component={Authentication} />
                            {/* 授权书  */}
                            <PrivateRoute path="/AuthenticationBook" component={AuthenticationBook} />
                            {/* 我的协议  */}
                            <PrivateRoute path="/AgreementPage" component={Agreement} />
                            {/* 收货确认单  */}
                            <PrivateRoute path="/consignee" component={Consignee} />
                            <PrivateRoute path="/consigneeDetail" component={ConsigneeDetail} />
                            {/* 学霸有理优惠券 */}
                            <PrivateRoute path="/discountCoupon" component={DiscountCoupon} />
                            {/* 邀请注册开始 */}
                            <PrivateRoute path="/invite" component={AppInviteView} />
                            <Route path="/AppInvitePay" component={AppInvitePay} />
                            <Route path="/inviteEspecialGoods" component={AppInviteEspecialGoodsView} />
                            <PrivateRoute
                                path="/inviteEspecialGoodsConfirm"
                                component={AppInviteEspecialGoodsConfirmView}
                            />
                            <Route path="/register" component={RegisterView} />
                            <Route path="/registerpaypage" component={RegisterPayPage} />
                            <Route path="/GangAoRegisterView" component={GangAoRegisterView} />
                            <Route path="/success" component={RegisterSuccessView} />
                            <Route path="/registerLead" component={RegisterLeadView} />
                            <PrivateRoute path="/partnerList" component={MyPartnerListView} />
                            <PrivateRoute path="/friendList" component={MyFriendListView} />
                            <PrivateRoute path="/myassistant" component={MyAssistant} />
                            <PrivateRoute path="/search" component={SearchView} />
                            <PrivateRoute path="/searchView" component={SearchViewPage} />
                            {/* 邀请注册结束 */}
                            {/* 物流相关开始 */}
                            <PrivateRoute path="/logistics" component={LogisticsInfoView} level={0} />
                            <PrivateRoute path="/expressList" component={B2BSendExpressListView} level={0} />
                            {/* 物流相关结束 */}
                            {/* 发货开始 */}
                            <PrivateRoute path="/retailIndex" component={AppRetailIndexNewView} />
                            <PrivateRoute path="/retailsenddetail" component={AppRetailSendDetailView} />
                            <PrivateRoute path="/b2cGoodsDetail" component={AppB2CGoodsDetailView} />
                            <PrivateRoute path="/retailConfirm" component={AppRetailConfirmView} />
                            <PrivateRoute path="/sendStep3" component={B2BSendStep3View} />
                            {/* 发货结束 */}
                            {/* 发货订单开始 */}
                            <PrivateRoute path="/b2bSendList" component={AppB2BSendListView} />
                            <Route path="/b2bSendDetail" component={AppB2BSendDetailView} />
                            <PrivateRoute path="/RetailConfirmQrCode" component={RetailConfirmQrCodeView} />
                            <PrivateRoute path="/iEnglishDetail" component={AppIEnglishDetailView} level={0} />
                            <PrivateRoute
                                path="/iEnglishActivateList"
                                component={AppIEnglishActivateListView}
                                level={0}
                            />
                            {/* 发货订单结束 */}
                            {/* 我的库存开始 */}
                            <PrivateRoute path="/myStock" component={MyStocksView} />
                            <PrivateRoute path="/myStockDetail" component={MyStockDetailView} />
                            {/* 我的库存结束 */}
                            {/* 设置开始 */}
                            <PrivateRoute path="/appSetting" component={AppSettingView} />
                            <PrivateRoute path="/myInfo" component={AppMyInfoView} />
                            <PrivateRoute path="/changeUserName" component={AppChangeUserNameView} />
                            <PrivateRoute path="/confirmID" component={AppConfirmIDView} />
                            <PrivateRoute path="/bindMobile" component={BindMobileView} />
                            <PrivateRoute path="/b2bAddressSelect" component={AddressSelect} />
                            <PrivateRoute path="/addressEdit" component={MyAddressEditView} />
                            <PrivateRoute path="/managePasswordLogin" component={MyPasswordLoginView} />
                            <PrivateRoute path="/dealLoginView" component={MydealLoginView} />
                            <PrivateRoute path="/editLoginPwd" component={MyPasswordLoginEditView} />
                            <PrivateRoute path="/tradingPasswordChange" component={TradingPasswordChangeView} />
                            {/* 设置结束 */}
                            {/* 开始 */}
                            <PrivateRoute path="/myRewardList" component={MyRewardListView} />
                            <PrivateRoute path="/myRewardDetail" component={MyRewardDetailView} />
                            <PrivateRoute path="/myRewardOrderDetail" component={MyRewardOrderDetailView} />
                            {/* 结束 */}
                            {/* 账号管理 */}
                            <PrivateRoute path="/accountManage" component={AccountManageIndexView} />
                            <PrivateRoute path="/accountDetailList" component={AccountDetailListView} />
                            <PrivateRoute path="/accountSearch" component={AccountSearchView} />
                            <PrivateRoute path="/transferDetail" component={TransferDetailView} />
                            <PrivateRoute path="/Email" component={Email} />
                            {/* 媒体资源教程 */}
                            <PrivateRoute path="/mediaHelpper" component={MediaHelpper} />
                            {/* 爱英优选授权证书 */}
                            <PrivateRoute path="/authorizationCert" component={AppAuthorizationCert} />
                            {/* 账号转移 */}
                            <PrivateRoute path="/accountTransferSetp1" component={AppAccountTransferSetp1} />
                            <PrivateRoute path="/accountTransferSetp2" component={AppAccountTransferSetp2} />
                            <PrivateRoute path="/accountTransferSetp3" component={AppAccountTransferSetp3} />
                            {/* 分销订单模块 开始 */}
                            <PrivateRoute path="/distributionOrder" component={AppDistributionOrderView} />
                            <PrivateRoute path="/distributionBill" component={AppDistributionBillView} />
                            {/* 分销订单模块 结束 */}
                            {/* 收益模块 开始 */}
                            <PrivateRoute path="/incomeIndex" component={AppIncomeIndexView} />
                            <PrivateRoute path="/walletIndex" component={AppWalletIndexView} />
                            {/* 收益模块 结束 */}
                            {/* 新支付模块、钱包模块 开始 */}
                            <PrivateRoute path="/walletBillIndex" component={AppWalletBillIndex} />
                            {/* 新支付模块、钱包模块 结束 */}
                            {/* 数据审核 */}
                            <Route path="/trainAdvance" component={TrainAdvance} />
                            <Route path="/TrainAdvanceDetail" component={TrainAdvanceDetail} />
                            <Route path="/TrainAdvanceSearch" component={TrainAdvanceSearch} />
                            {/* 分销模块 开始 */}
                            <PrivateRoute
                                path="/b2bPartnerOrderClearingDetail"
                                component={AppB2BPartnerOrderClearingDetailView}
                            />
                            <PrivateRoute path="/B2BOrderRepairConfirmView" component={AppB2BOrderRepairConfirmView} />
                            <PrivateRoute
                                path="/b2bPartnerOrderClearingFinishDetail"
                                component={AppB2BPartnerOrderClearingFinishDetailView}
                            />
                            {/* 分销模块 结束 */}
                            {/* 分销模块 开始 */}
                            <PrivateRoute path="/b2bPartnerOrderList" component={B2BPartnerOrderListView} />
                            <PrivateRoute path="/B2BReceiveOrderListView" component={B2BReceiveOrderListView} />
                            <PrivateRoute path="/B2BReceiveListView" component={AppB2BReceiveListView} />
                            <PrivateRoute path="/B2BReceiveDetailView" component={APPB2BReceiveDetailView} />
                            <PrivateRoute path="/OrderDetailView" component={OrderDetailView} />
                            <PrivateRoute path="/ChooseAccount" component={ChooseAccount} />
                            <PrivateRoute path="/b2bPartnerOrderDetail" component={B2BPartnerOrderDetailView} />
                            <PrivateRoute path="/renewOrder" component={RenewOrderView} />
                            <Route path="/ManualRefund" component={ManualRefund} />
                            <Route component={NotFoundView} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </div>
        )
    }
}

class PageLoading extends Component {
    render() {
        return (
            <div style={{width: '100%'}}>
                <div className="lazy-p" />
            </div>
        )
    }
}
