/**
 @description: reducer - 用户信息
 @date: 2016/7/18
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as TYPES from '../constants/ActionTypes'
import {IPAY_INFO} from '../constants/StorgeKeys'
import * as Storage from '../utils/Storage'

const initialState = {
    userToken: null,                                // 新支付平台token
}

function save(state) {
    Storage.saveItem(IPAY_INFO, JSON.stringify(state))
        .then(() => {})
        .catch(err => {
            console.log('存储用户信息失败：', err)
        })

    return state
}

export default function iPayInfo(state = initialState, action) {
    switch (action.type) {
        case TYPES.SET_IPAY_INFO: {
            global.__userToken = action.iPayInfo.userToken
            return save({
                ...initialState,
                ...state,
                ...action.iPayInfo
            })
        }

        case TYPES.UPDATE_IPAY_INFO: {
            global.__userToken = action.newData.userToken
            return save({
                ...initialState,
                ...state,
                ...action.newData
            })
        }

        case TYPES.LOGOUT: {
            global.__userToken = 0
            return save({
                ...initialState
            })
        }

        default:
            return state
    }
}
