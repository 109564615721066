/**
 @description: 中行卡提交信息
 @date: 2019-1-7
 @author: hoe(aha2mao@gmail.com)
 */
import * as TYPES from '../constants/ActionTypes'

const initialState = {
    reApply: 0, // 0: add, 1: update
    realname: '',
    sex: '',
    nation: '',
    identity: '', // 身份证号
    identityEndDate: '', // 身份证到期时间
    province: '', // 省
    city: '', // 市
    area: '', // 区
    detailAddress: '', // 详细地址
    identityImageFont: '', // 身份证正面
    identityImageBack: '', // 身份证反面,
    mailAddress: '',
    phone: '',
    code: ''
}

export default function boc(state = initialState, action) {
    switch (action.type) {
        case TYPES.BOC_SUBMIT_INFO_UPDATE: {
            return {
                ...state,
                ...action.category
            }
        }
        default:
            return state
    }
}
