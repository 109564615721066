import React, {Component} from 'react'
import {View, TouchableWithoutFeedback} from 'react-native'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {hot} from 'react-hot-loader/root'
import Cookies from 'js-cookie'
import ImageGallery from 'react-image-gallery'
import * as AppActions from '../actions/App'
import * as UserInfoActions from '../actions/UserInfo'
import * as IPayInfoActions from '../actions/IPayInfo'

import './App.css'

import * as StyleSheet from '../VUI/StyleSheet'
import {pageBackgroundColor} from '../VUI/Colors'
import Loading from '../VUI/Loading'
import TouchableOpacity from '../VUI/TouchableOpacity'
import {isInApp, windowHeight, windowWidth, isInWechat, isWebIos} from '../VUI/VStyle'

import Navigator from './Navigator'
// import Navigator from '../forUiExample/Navigator.vui'

import {getIpayInfo, getUserInfo} from '../utils/index'
import {getItem, removeItem} from '../utils/Storage'
import {APP_DATA, IPAY_INFO, USER_INFO} from '../constants/StorgeKeys'

import 'react-image-gallery/styles/css/image-gallery.css'
import {getParamFromSearch} from './Route'
import {isRightToken} from '../utils/Common'
import {fetchUserGradeInfo} from '../constants/AppConfig'
import SystemStatus from '../components/SystemStatus'
import QuickMenu from '../VUI/QuickMenu/index'
import CustomerService from '../VUI/CustomerService/index'
import {track} from '../utils/ZGTools'
import {saveLandingPageReturnUrl} from '../utils/wormhole'
import {Redirect} from 'react-router-dom'
import {JUMP_TOP365_URL, IENGLISH_BASE_URL3} from '../constants/FetchUrls'

const styles = StyleSheet.create({
    container: {
        backgroundColor: pageBackgroundColor,
        height: '100%',
        position: 'relative'
    }
})

class App extends Component {
    constructor(props) {
        super(props)
        this.checkAuthStatus = this.checkAuthStatus.bind(this)
        this.state = {
            isChecking: true
        }
    }

    componentDidMount() {
        this.checkAuthStatus()
        sessionStorage.clear() // 每次页面刷新后清空sessionStorage，目的：清空 DataList的cache
        initInAppJump()
        saveLandingPageReturnUrl().catch(err => {
            // console.log('saveLandingPageReturnUrl', err)
        })
        // 解决 iOS 微信底部导航条一开始不出现的问题
        if (isInWechat && isWebIos && history?.length <= 1) {
            history.pushState({}, '', location.pathname + location.search)
        }
        if (/BaiE_APP/i.test(window.navigator.userAgent)) {
            const searchParams = getParamFromSearch(window.location.search)
            if (!searchParams.platform) {
                track('app-webview-url', {
                    referrer: document.referrer,
                    href: location.href,
                    pathname: location.pathname
                })
            }
        }
    }

    componentDidCatch(error, info) {
        console.log(error, info)
    }

    checkAuthStatus() {
        const {actions} = this.props

        let TokenSign = Cookies.get('TokenSign')

        if (!TokenSign && TokenSign === '0') {
            TokenSign = 0
        }

        const tokenFromSearch = getTokenFromSearch()
        if (tokenFromSearch) {
            TokenSign = tokenFromSearch
        }

        ;(async () => {
            const oldAppData = await getOldAppData()
            const oldToken = oldAppData.token
            if (TokenSign !== oldToken) {
                // cookie里的TokenSign和 Storage里token不一致时，以cookie为准
                oldAppData.token = TokenSign
                await removeItem(USER_INFO)
            }
            if (oldAppData.token === 'logout') {
                oldAppData.token = 0
            }
            actions.setAppData(oldAppData)
            fetchUserGradeInfo(oldAppData.token)

            const userInfo = await getOldUserInfo(oldAppData.token)
            // let iPayInfo = await getOldIPayInfo(oldAppData.token)
            if (userInfo && userInfo.userId) {
                global.__userId = userInfo.userId
                actions.setUserInfo(userInfo)
            }
            // if (iPayInfo && iPayInfo.userToken) {
            //     global.__userToken = iPayInfo.userToken
            //     actions.setIPayInfo(iPayInfo)
            // }
            getUserInfo()
                .then(userInfo => {
                    actions.setUserInfo(userInfo)
                })
                .catch(() => {})

            // getIpayInfo()
            //     .then(iPayInfo => {
            //         actions.setIPayInfo(iPayInfo)
            //     })
            //     .catch(() => {})
            this.setState({
                isChecking: false
            })
        })()
    }

    hideImageSlide() {
        const {actions} = this.props
        actions.showImageSlide(false)
    }

    renderImageSlide() {
        // 图片轮播图
        const {imageSlide} = this.props.appData
        if (!imageSlide) {
            return null
        }

        const images = imageSlide.imageList.map(i => {
            return {
                original: i,
                renderItem: () => {
                    return <img src={i} style={{width: windowWidth, height: windowHeight, objectFit: 'contain'}} />
                }
            }
        })

        return (
            <TouchableOpacity
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    justifyContent: 'center'
                }}
                activeOpacity={1}
                onPress={this.hideImageSlide.bind(this)}>
                <TouchableWithoutFeedback>
                    <ImageGallery
                        showBullets={true}
                        showIndex={true}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showThumbnails={false}
                        showNav={false}
                        items={images}
                        startIndex={imageSlide.index ? imageSlide.index : 0}
                        // onClick={this.hideImageSlide.bind(this)}
                    />
                </TouchableWithoutFeedback>
            </TouchableOpacity>
        )
    }

    render() {
        const {isChecking} = this.state

        let quickDom = <QuickMenu />

        const {ramarkJSON, grade, mobile} = this.props.userInfo

        // const {token} = this.props.navigator
        if (grade == 5) {
            if (ramarkJSON) {
                if (
                    window.location.pathname == '/b2bOrderConfirmLevelUpSettled' ||
                    window.location.pathname == '/addressEdit' ||
                    window.location.pathname == '/b2bAddressSelect' ||
                    window.location.pathname == '/inviteEspecialGoods' ||
                    window.location.pathname == '/b2bOrderSuccess'
                ) {
                    quickDom = <QuickMenu showBack={true} />
                } else {
                }
            }
        }

        if (!isChecking) {
            const flag = window.location.pathname == '/mainTabs'
            return (
                <View style={styles.container}>
                    <Navigator />
                    {this.renderImageSlide.call(this)}
                    {/*<SystemStatus />*/}
                    {window.location.pathname != '/inviteEspecialGoods' ? quickDom : null}
                    {flag && (
                        <CustomerService
                            onPress={() => {
                                // track('悬浮客服按钮', {页面来源: window.location.pathname, 参数: window.location.search})
                                // window.__b_navigator.push({
                                //
                                //
                                // })

                                window.location.href = `${IENGLISH_BASE_URL3}/iToAgent?type=1&showNav=false&mobile=${mobile}`
                                // window.location.href=JUMP_TOP365_URL+"?xxl_sso_sessionid="+global.token
                            }}
                        />
                    )}
                </View>
            )
        }
        return (
            <View style={styles.container}>
                <Loading show={true} text="" />
            </View>
        )
    }
}

// export default App;

function mapStateToProps(state) {
    return {
        appData: state.appData,
        userInfo: state.userInfo,
        iPayInfo: state.iPayInfo
    }
}

function mapDispatchToProps(dispatch) {
    global.toggleQuickMenu = (bool = true) => dispatch(AppActions.toggleQuickMenu(bool))
    return {
        actions: bindActionCreators({...AppActions, ...UserInfoActions, ...IPayInfoActions}, dispatch)
    }
}

const AppWithConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
export default hot(AppWithConnect)

function getOldAppData() {
    return new Promise(function(resolve, reject) {
        getItem(APP_DATA)
            .then(oldDataString => {
                const oldData = JSON.parse(oldDataString)
                if (oldData && oldData.token !== undefined) {
                    if (oldData && oldData.shareInfo) {
                        oldData.shareInfo = {}
                    }
                    if (oldData && oldData.imageSlide) {
                        oldData.imageSlide = false
                    }
                    if (oldData && oldData.qucikMenu) {
                        oldData.qucikMenu = true
                    }
                    resolve(oldData)
                } else {
                    resolve({token: 0})
                }
            })
            .catch(err => {
                resolve({token: 0})
            })
    })
}
function getOldUserInfo(token) {
    return new Promise(function(resolve, reject) {
        getItem(USER_INFO)
            .then(oldDataString => {
                const userInfo = JSON.parse(oldDataString)
                if (userInfo && userInfo.userId) {
                    resolve(userInfo)
                } else {
                    // storage里没有用户信息，去接口取
                    if (token) {
                        getUserInfo(token)
                            .then(userInfo => {
                                resolve(userInfo)
                            })
                            .catch(() => {
                                resolve({})
                            })
                    } else {
                        resolve({})
                    }
                }
            })
            .catch(() => {
                getUserInfo(token)
                    .then(userInfo => {
                        resolve(userInfo)
                    })
                    .catch(() => {
                        resolve({})
                    })
            })
    })
}

/**
 *
 * @param token
 * @returns {Promise<any>}
 */
function getOldIPayInfo(token) {
    return new Promise(function(resolve, reject) {
        getItem(IPAY_INFO)
            .then(oldDataString => {
                const iPayInfo = JSON.parse(oldDataString)
                if (iPayInfo && iPayInfo.userToken) {
                    resolve(iPayInfo)
                } else {
                    // storage里没有用户信息，去接口取
                    if (token) {
                        // getIpayInfo(token)
                        //     .then(userInfo => {
                        //         resolve(userInfo)
                        //     })
                        //     .catch(() => {
                        //         resolve({})
                        //     })
                    } else {
                        resolve({})
                    }
                }
            })
            .catch(() => {
                // getIpayInfo(token)
                //     .then(userInfo => {
                //         resolve(userInfo)
                //     })
                //     .catch(() => {
                //         resolve({})
                //     })
            })
    })
}

/**
 * 从地址栏获取token
 * 如果地址栏上有token，那么以地址栏的token来更新本地token - app内包含网页自动登录需求
 * @returns {*}
 */
function getTokenFromSearch() {
    const token = false

    const searchParams = getParamFromSearch(window.location.search)
    if (searchParams && ['baieapp', 'zaotuan'].includes(searchParams.platform) && isRightToken(searchParams.token)) {
        return searchParams.token
    }
    return token
}

/**
 * 如果在iOS的spa中，默认的返回有bug
 */
function initInAppJump() {
    if (!isInApp) {
        return
    }

    const checkCounter = window.setInterval(() => {
        // react native webview 准备好会注入 originalPostMessage 变量
        if (window.originalPostMessage) {
            clearInterval(checkCounter)
            window.postMessage(
                JSON.stringify({
                    type: 'triggerIosSpaJump'
                })
            )
        }
    }, 500)

    window.document.addEventListener('message', function(e) {
        const beforeJumpUrl = window.location.href
        try {
            const result = JSON.parse(e.data)
            if (result.type === 'goBack') {
                window.__b_navigator.pop()
                setTimeout(function() {
                    const afterJumpUrl = window.location.href

                    if (afterJumpUrl === beforeJumpUrl) {
                        window.postMessage(
                            JSON.stringify({
                                type: 'closeWindow'
                            })
                        )
                    }
                }, 200)
            } else if (result.type === 'pageReplace') {
                window.location.replace(result.url)
            }
        } catch (e) {
            console.log(e)
        }
    })
}
