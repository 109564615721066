/**
 @description: 系统维护状态 - 刷新策略 app、web不一致
 @date: 2019/4/4
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example:
 */
import React, {Component} from 'react'
import {connect} from 'react-redux'

import Modal from '../VUI/Modal'
import Text from '../VUI/Text'
import {tSize} from '../VUI/VStyle'
import {SYSTEM_STATUS} from '../constants/FetchUrls'

import {R_GET_CMS} from '../utils/net'

class SystemStatus extends Component {
    constructor(props) {
        super(props)
        this.show = this.show.bind(this)

        this.state = {
            value: 'on',
            desc: ''
        }

        this.latestCheck = 0
    }

    componentDidUpdate(prevProps) {
        // this.show 有对两次请求间隔做限制，所以这里不判断是否路由变化了
        // this.show()
    }

    componentDidMount() {
        // this.show()
    }

    show() {
        const nowTime = Date.now()
        if (nowTime - this.latestCheck < 1000 * 60 * 10) {
            return // 10分钟内最多触发一次检测
        }

        this.latestCheck = nowTime
        // R_GET_CMS(SYSTEM_STATUS)
        //     .then(response => {
        //         const {value, desc} = response
        //         this.setState({
        //             value,
        //             desc
        //         })
        //     })
        //     .catch(err => {})
    }

    render() {
        const {value, desc} = this.state
        if (value !== 'off') {
            return null
        }
        return (
            <Modal title="系统维护中" visible={true} hideClose={true} hideFooter={true}>
                <Text
                    style={{fontSize: tSize(16), lineHeight: tSize(20), alignSelf: 'center', marginBottom: tSize(15)}}>
                    给您带来的不便，敬请谅解。
                </Text>
                <Text style={{fontSize: tSize(14), lineHeight: tSize(20), alignSelf: 'center'}}>{desc}</Text>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        routeName: state.navigation.routeName
    }
}

export default connect(
    mapStateToProps,
    null
)(SystemStatus)
