/**
 @description: 工具类集合
 @date: 2018/3/9
 @author: Buggy(chenyuanhui@baie.com.cn)
 */

import {Dimensions, PixelRatio} from 'react-native'
import layer from '../VUI/utils/layer/layer'
import * as net from './net'
import * as Common from './Common'
import * as dom from './dom'
import * as AppConfig from '../constants/AppConfig'
import {APP_DOWNLOAD, PRODUCT_COUNT_GET, TIMEBURIEDPOINTACT} from '../constants/FetchUrls'
import {getProjectText} from './getProjetcBind'
import {View} from 'antd-mobile'

export const USER_AGENT = net.USER_AGENT
export const R_GET = net.R_GET
export const R_POST = net.R_POST
export const getUserInfo = net.getUserInfo
export const getIpayInfo = net.getIPayInfo
export const noRefOpen = net.noRefOpen

export const currency = Common.currency
export const currency2 = Common.currency2
export const isNumber = Common.isNumber
export const toNumber = Common.toNumber
export const parseParam = Common.parseParam
export const isMobile = Common.isMobile
export const isUserName = Common.isUserName
export const isIdCard = Common.isIdCard
export const isMobileCaptcha = Common.isMobileCaptcha
export const getDate = Common.getDate
export const checkWalletAndOpen = Common.checkWalletAndOpen
export const isNeedAuthWallet = Common.isNeedAuthWallet
export const goToDetail = Common.goToDetail
export const numberFormat = Common.numberFormat
export const getUserAddressList = Common.getUserAddressList
export const isRightToken = Common.isRightToken
export const getUrlRequest = Common.getUrlRequest
export const getTimeNumber = Common.getTimeNumber
export const getUserGradeText = AppConfig.getUserGradeText
export const getUserStarGradeText = AppConfig.getUserStarGradeText
export const getUserGrade = AppConfig.getGradeInfo
export const appendCss = dom.appendCss

export const PIXEL_SIZE = PixelRatio.getPixelSizeForLayoutSize(1) // 像素密度
export const IS_IN_TOUCH_PC = PIXEL_SIZE <= 1.8
export const windowHeight = Dimensions.get('window').height // 这个手机 的屏幕高度
export const windowWidth = Dimensions.get('window').width // 这个手机 的屏幕宽度
export const isPad = windowWidth > 550 // 是否在pad中使用

export function R_alert(config) {
    if (typeof config === 'string') {
        config = {
            content: config
        }
    }
    let {title = '提示', content = '提示文字', yesText = '确认', yes} = config
    if (content && content.message) {
        content = content.message
    }
    if (content && typeof content !== 'string') {
        content = '未知错误'
    }
    if (__isNeedReturn(content)) {
        return
    }
    return layer.open({
        title,
        content: content.includes('\r\n') ? content.replace(/\r\n/gim, '<br />') : content || '',
        btn: yesText,
        style: 'width: 270px',
        yes: index => {
            if (yes) {
                yes()
            }
            layer.close(index)
        }
    })

    function __isNeedReturn(text) {
        if (
            text &&
            text.match &&
            (text.match('登录') !== null || text.match('token') !== null) &&
            (global.token === 'logout' || global.token === 0)
        ) {
            // todo 暂时解决多次提示登录的bug
            return true
        }
        return false
    }
}

export function R_confirm(config) {
    let {title = '提示', content = '请传入content', yesText = '确定', yes, noText = '取消', no} = config
    if (content && content.message) {
        content = content.message
    }
    if (content && typeof content !== 'string') {
        content = '未知错误'
    }

    if (content && content.includes('\r\n')) {
        content = content.replace(/\r\n/gim, '<br />')
    }
    layer.open({
        title,
        content: content || '',
        btn: [yesText, noText],
        anim: 'up',
        style: 'width: 270px',
        yes: index => {
            if (yes) {
                yes()
            }
            layer.close(index)
        },
        no: index => {
            if (no) {
                no()
            }
            layer.close(index)
        }
    })
}

/**
 * 返回 yyyy-mm-dd hh:mm:ss格式的时间
 * @param time
 * @param type
 * @returns {string}
 */
export function getTime(time, type = 'yyyy-mm-dd hh:mm:ss') {
    if (!time) {
        time = new Date()
    }
    let d = {
        year: time.getFullYear(),
        month: time.getMonth() + 1,
        day: time.getDate(),
        hour: time.getHours(),
        min: time.getMinutes(),
        sec: time.getSeconds()
    }
    for (let key in d) {
        if (d[key] < 10) {
            d[key] = `0${d[key]}`
        }
    }
    if (type === 'yyyy-mm-dd') {
        return `${d.year}-${d.month}-${d.day}`
    }
    if (type === 'hh:mm') {
        return `${d.hour}:${d.min}`
    }
    if (type === 'yyyy-mm-dd hh:mm') {
        return `${d.year}-${d.month}-${d.day} ${d.hour}:${d.min}`
    }
    if (type === 'mm.dd') {
        return `${d.month}.${d.day}`
    }
    if (type === 'yyyy/mm/dd hh:mm') {
        return `${d.year}/${d.month}/${d.day} ${d.hour}:${d.min}`
    }
    if (type === 'yyyy:MM:DD hh:mm:ss') {
        return `${d.year}:${d.month}:${d.day} ${d.hour}:${d.min}:${d.sec}`
    }
    if (type === 'mm月dd日 hh:mm') {
        return `${d.month}月${d.day}日 ${d.hour}:${d.min}`
    }
    if (type === 'yyyy年mm月dd日') {
        return `${d.year}年${d.month}月${d.day}日`
    }
    if (type === 'yyyy年mm月') {
        return d.year + '年' + d.month + '月'
    }
    if (type === 'mm月dd日') {
        return `${d.month}月${d.day}日`
    }
    if (type === 'yyyymmdd') {
        return `${d.year}-${d.month}${d.day}`
    }
    return `${d.year}-${d.month}-${d.day} ${d.hour}:${d.min}:${d.sec}`
}
/**
 * 数据埋点
 * @param config 传参信息
 * @returns {Promise}
 */

export function statisticalData(config) {
    return new Promise(function(resolve, reject) {
        R_GET(TIMEBURIEDPOINTACT, config)
            .then(response => {
                resolve(response)
            })
            .catch(reject)
    })
}
/**
 * 返回 YYYY.MM.DD HH:MM 、 YYYY/MM/DD HH:MM 、YYYY年MM月DD日 HH:MM 、YYYY/MM/DD、YYYY.MM.DD、YYY年MM月DD日
 * @param time 时间戳
 * @param type 转换类型
 * @returns Date
 */

export function getSpecialDate({time, type = 'YYYY.MM.DD'}) {
    if (!time) {
        time = new Date()
    }
    let nowtime = new Date().getFullYear()
    let d = {
        year: new Date(time).getFullYear(),
        month: new Date(time).getMonth() + 1,
        day: new Date(time).getDate(),
        hour: new Date(time).getHours(),
        min: new Date(time).getMinutes(),
        sec: new Date(time).getSeconds()
    }
    for (let key in d) {
        if (d[key] < 10) {
            d[key] = `0${d[key]}`
        }
    }
    // if (nowtime == d.year) {
    //     return `${d.month}/${d.day} ${d.hour}:${d.min}`
    // }
    switch (type) {
        case 'YYYY.MM.DD HH:MM':
            return `${d.year}.${d.month}.${d.day} ${d.hour}:${d.min}`
        case 'YYYY/MM/DD HH:MM':
            return `${d.year}/${d.month}/${d.day} ${d.hour}:${d.min}`
        case 'YYYY年MM月DD日 HH:MM':
            return `${d.year}年${d.month}月${d.day}日  ${d.hour}:${d.min}`
        case 'YYYY/MM/DD':
            return `${d.year}/${d.month}/${d.day}`
        case 'YYYY.MM.DD':
            return `${d.year}.${d.month}.${d.day}`
        case 'YYYY年MM月DD日':
            return `${d.year}年${d.month}月${d.day}日`
        default:
            return `${d.year}.${d.month}.${d.day}`
    }
}
/**
 * 防抖事件
 * @param cb 回调函数
 * @param wait 等待时间
 * @returns {*}
 */

let timeout = null
export function debounce(cb, wait = 500) {
    if (timeout !== null) clearTimeout(timeout)
    timeout = setTimeout(() => {
        timeout = null
        cb && cb()
    }, wait)
}

/**
 * 图片根据显示的大小尽可能的小
 * @param url
 * @param width
 * @param height
 * @returns {*}
 */
export function imgCompress(url, width, height, fixedPixel) {
    if (!width || !height) {
        return url
    }
    let src = url
    let pixel = PixelRatio.get()
    // 大触摸屏强制设为3
    if (IS_IN_TOUCH_PC) {
        pixel = 3
    }
    if (fixedPixel) {
        pixel = fixedPixel
    }
    width = parseInt(width * pixel, 10)
    height = parseInt(height * pixel, 10)

    // 太大的数字，阿里云不支持，而且也裁剪压缩的意义了
    if (width > 1500 || height > 1500) {
        return src
    }
    try {
        let isAvatar = src.match(/static\.rosepie\.com/) !== null || src.match(/static\.meiguipai\.net/) !== null
        if (src.match('x-oss-process') !== null) {
            return src
        }
        if (isAvatar) {
            src = src.replace(/@.*/, '')
            if (src.match(/\?/) !== null) {
                src += '&'
            } else {
                src += '?'
            }
            src += `x-oss-process=image/format,jpg/quality,q_80/resize,m_fill,h_${height},w_${width}` // 缩略图
        }
    } catch (e) {}

    return src
}

export function dismissKeyboard() {
    // todo
    // console.log('todo')
}

/**
 * 手机号 xxx-xxxx-xxxx
 * @param text
 * @returns {text}
 */
export function phoneText(text) {
    try {
        return text.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
    } catch (e) {}
    return text
}

/**
 * 手机号 xxx****xxxx
 * @param text
 * @returns {*}
 */
export function phoneText2(text) {
    try {
        return text.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
    } catch (e) {}
    return text
}

/**
 * 计算字符串所占的内存字节数，默认使用UTF-8的编码方式计算，也可制定为UTF-16
 * UTF-8 是一种可变长度的 Unicode 编码格式，使用一至四个字节为每个字符编码
 *
 * 000000 - 00007F(128个代码)      0zzzzzzz(00-7F)                             一个字节
 * 000080 - 0007FF(1920个代码)     110yyyyy(C0-DF) 10zzzzzz(80-BF)             两个字节
 * 000800 - 00D7FF
 00E000 - 00FFFF(61440个代码)    1110xxxx(E0-EF) 10yyyyyy 10zzzzzz           三个字节
 * 010000 - 10FFFF(1048576个代码)  11110www(F0-F7) 10xxxxxx 10yyyyyy 10zzzzzz  四个字节
 *
 * 注: Unicode在范围 D800-DFFF 中不存在任何字符
 * {@link http://zh.wikipedia.org/wiki/UTF-8}
 *
 * UTF-16 大部分使用两个字节编码，编码超出 65535 的使用四个字节
 * 000000 - 00FFFF  两个字节
 * 010000 - 10FFFF  四个字节
 *
 * {@link http://zh.wikipedia.org/wiki/UTF-16}
 * @param  {String} str
 * @param  {String} charset utf-8, utf-16
 * @return {Number}
 */
export function sizeofStr(str, charset = 'utf-8') {
    let total = 0
    let charCode
    let i
    let len
    charset = charset ? charset.toLowerCase() : ''
    if (charset === 'utf-16' || charset === 'utf16') {
        for (i = 0, len = str.length; i < len; i++) {
            charCode = str.charCodeAt(i)
            if (charCode <= 0xffff) {
                total += 2
            } else {
                total += 4
            }
        }
    } else {
        for (i = 0, len = str.length; i < len; i++) {
            charCode = str.charCodeAt(i)
            if (charCode <= 0x007f) {
                total += 1
            } else if (charCode <= 0x07ff) {
                total += 2
            } else if (charCode <= 0xffff) {
                total += 3
            } else {
                total += 4
            }
        }
    }
    return total
}

export function subStrInSize(str, maxLength, charset = 'utf-8') {
    let total = 0
    let charCode
    let i
    let len
    charset = charset ? charset.toLowerCase() : ''
    if (charset === 'utf-16' || charset === 'utf16') {
        for (i = 0, len = str.length; i < len; i++) {
            charCode = str.charCodeAt(i)
            if (charCode <= 0xffff) {
                total += 2
            } else {
                total += 4
            }
            if (total >= maxLength) {
                return str.substring(0, i)
            }
        }
    } else {
        for (i = 0, len = str.length; i < len; i++) {
            charCode = str.charCodeAt(i)
            if (charCode <= 0x007f) {
                total += 1
            } else if (charCode <= 0x07ff) {
                total += 2
            } else if (charCode <= 0xffff) {
                total += 3
            } else {
                total += 4
            }
            if (total >= maxLength) {
                return str.substring(0, i)
            }
        }
    }
    return str
}

/**
 * 银行卡 xxxx****xxxx
 * @param text
 * @returns {*}
 */
export function bankCard2(text) {
    try {
        return text.replace(/^(\d{4}).+(\d{4})$/, '$1 **** **** $2')
    } catch (e) {}
    return text
}

/**
 * 获取资源文件时间戳
 * @returns {Number}
 */
export function getCdnTimeStamp() {
    return parseInt(Date.now() / 1000 / 60 / 10, 10)
    // 每十分钟更新一次，所以需要更新 cdn上的文件，要避开 00、10、20、30、40、50 这几个点的分钟数
    // 比如 要更新 STOCK_RIGHT_CONTRACT 这个文件了，可以选在 18:05分这个点，应该每遇到分钟数 十分位变了， timeStamp 就会改变，导致客户单强制刷新这个文件
    // 安卓手机的缓存有问题，cdn 刷新缓存也没用，所以加了这个策略
}

/**
 * 获取商品总数
 * @param items
 * @returns {string}
 */
export function getProductCount(items) {
    let productCount = 0
    try {
        for (let i = 0; i < items.length; i++) {
            let obj = items[i]
            if (obj.quantity) {
                productCount += obj.quantity
            } else if (obj.count) {
                productCount += obj.count
            } else if (obj.productQuantity) {
                productCount += obj.productQuantity
            } else if (obj.repairCount) {
                productCount += obj.repairCount
            }
        }
    } catch (e) {}
    return `${productCount}`
}
/**
 * 获取主域名
 * @returns {string}
 */
export function getMainDomain(host) {
    if (!host || !host.split || !host.replace) {
        return host
    }
    host = host.replace(/:\d+$/, '')
    if (host && host.match) {
        if (host.match('localhost') !== null || host.match(/\d+\.\d+\.\d+\.\d+/) !== null) {
            return host
        }
    }
    let list = host.split('.')
    return `.${list[list.length - 2]}.${list[list.length - 1]}`
}

/**
 * 阿里云图片根据需要显示的图片大小进行裁剪
 * @param src
 * @param type
 * @returns {string}
 */
export function aliImgClip(src, type) {
    if (!src || !src.replace) {
        return ''
    }
    if (src.match('static.rosepie.com') === null && src.match('static.meiguipai.net') === null) {
        return src
    }
    // src : "https://static.rosepie.com/o_1b4vs48umt16v665jbaa21m7rc_1536x1536.jpg
    src = src.replace(/_\d+[X|x]\d+/, '')
    src = src.replace(/@.*/, '')
    switch (type) {
        case 1:
            return `${src}?x-oss-process=image/resize,m_mfit,h_300,w_300` // 缩略图
            break
        case 2:
            return `${src}?x-oss-process=image/resize,w_1800` // 自动裁剪 - 放大
            break
        case 3:
            return `${src}?x-oss-process=image/resize,m_fill,h_308,w_162` // 体脂称截图
            break
        case 4:
            return `${src}?x-oss-process=image/resize,m_mfit,h_330,w_285/crop,x_0,y_15,h_330,w_330` // 体脂称截图
            break
        case 5:
            return `${src}?x-oss-process=image/resize,m_fill,h_667,w_375` // 数据对比图用
            break
        case 6:
            return `${src}?x-oss-process=image/resize,m_mfit,h_225,w_375/crop,x_0,y_20,h_225,w_375` // 数据上下对比图用
            break
        case 7:
            return `${src}?x-oss-process=image/resize,m_mfit,h_190,w_344/crop,x_0,y_18,h_190,w_344` // 数据上下对比图用 - 首页
            break
        case 'DiaryItem':
            return `${src}?x-oss-process=image/resize,m_mfit,h_444,w_280/crop,x_0,y_13,h_444,w_280` // 数据对比图用
            break
        case 'AppIndexBanner':
            return `${src}?x-oss-process=image/resize,m_mfit,h_280,w_750/crop,x_0,y_48,h_280,w_750` // 数据对比图用
            break
        case 101:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzA2ODBkNGExNzU0YTRiZmJhZDI1NjNjYzU1MjIxYTY4P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10/resize,m_mfit,h_300,w_300` // 百E
            break
        case 102:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzkyZTkyNjAwZDM1ZDRkZDM4YTViMjliZjE1OTliM2Y2P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10/resize,m_mfit,h_300,w_300` // 百E
            break
        case 1010:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzA2ODBkNGExNzU0YTRiZmJhZDI1NjNjYzU1MjIxYTY4P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10` // 百E
            break
        case 1020:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzkyZTkyNjAwZDM1ZDRkZDM4YTViMjliZjE1OTliM2Y2P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10` // 百E
            break
        case 10100:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzA2ODBkNGExNzU0YTRiZmJhZDI1NjNjYzU1MjIxYTY4P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10/resize,w_1800` // 百E
            break
        case 10200:
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_90/format,jpg/watermark,image_cmVzLzkyZTkyNjAwZDM1ZDRkZDM4YTViMjliZjE1OTliM2Y2P3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLHdfNTAwLGhfNTAw,t_100,g_center,x_10,y_10/resize,w_1800` // 百E
            break
        case '易宝认证':
            return `${src}?x-oss-process=image/auto-orient,1/quality,q_80/resize,m_mfit,h_666,w_1000/crop,x_0,y_333,h_666,w_1000`
        default:
            return src
    }
}

/**
 * 获取限购数量
 * @param pIds
 * @param userId
 * @param ifRetailFlg
 * @returns {Promise}
 */
export function productCountsGet(pIds, userId, ifRetailFlg) {
    return new Promise(function(resolve, reject) {
        R_GET(PRODUCT_COUNT_GET, {
            pIds,
            userId,
            ifRetailFlg
        })
            .then(response => {
                resolve({countsGet: response.data.countsGet, sumsGet: response.data.sumsGet})
            })
            .catch(reject)
    })
}

/**
 * 工具线时间格式化tool
 */

export function toolTimeFormat(startTime, endTime, type = 'MM月dd日 hh:mm - hh.mm') {
    if (!startTime || !endTime) {
        return ''
    }
    if (typeof startTime == 'string') {
        startTime = new Date(startTime.replace(/-/g, '/'))
    }
    if (typeof endTime == 'string') {
        endTime = new Date(endTime.replace(/-/g, '/'))
    }

    let st = {
        year: startTime.getFullYear(),
        month: startTime.getMonth() + 1,
        day: startTime.getDate(),
        hour: startTime.getHours(),
        min: startTime.getMinutes(),
        sec: startTime.getSeconds()
    }
    let et = {
        year: endTime.getFullYear(),
        month: endTime.getMonth() + 1,
        day: endTime.getDate(),
        hour: endTime.getHours(),
        min: endTime.getMinutes(),
        sec: endTime.getSeconds()
    }
    for (let key in st) {
        if (st[key] < 10) {
            st[key] = `0${st[key]}`
        }
    }
    for (let key in et) {
        if (et[key] < 10) {
            et[key] = `0${et[key]}`
        }
    }
    let timeFinal = ''
    if ((st.year !== et.year || st.month !== et.month || st.day !== et.day) && type === 'MM月dd日 hh:mm - hh.mm') {
        type = 'MM月dd日 - MM月dd日'
    }
    switch (type) {
        case 'yyyy.MM.dd~MM.dd':
            timeFinal = `${st.year}.${st.month}.${st.day} - ${et.month}.${et.day}`
            break
        case 'MM月dd日 - MM月dd日':
            timeFinal = `${st.month}月${st.day}日 - ${et.month}月${et.day}日`
            break
        default:
            timeFinal = `${st.month}月${st.day}日 ${st.hour}:${st.min} - ${et.hour}:${et.min}`
            break
    }
    return timeFinal
}

/**
 * 数值格式化
 * @param num
 * @returns {string}
 */
export function numFormat(num) {
    try {
        let w = 10000
        let sw = 100000
        let qw = 10000000
        let yw = 100000000
        let sy = 1000000000

        if (num < w) {
            return num
        }
        if (num < sw || num < qw) {
            return `${(num / w).toFixed(2)}万`
        }
        if (num < yw) {
            return `${(num / qw).toFixed(2)}千万`
        }
        if (num < sy) {
            return `${(num / yw).toFixed(2)}亿`
        }
        if (num > sy) {
            return '10亿'
        }
    } catch (e) {
        return '0.00'
    }
}

/**
 * 解析路由跳转参数
 * @param routerUrl
 * @returns {reouteName: params}
 */
export function extractRouteUrl(routerUrl) {
    if (typeof routerUrl !== 'string' || !routerUrl) {
        return null
    }
    let routerUrlNew = ''
    let paramList = 0
    let sendParam = {}
    // 是否带参数
    if (routerUrl.indexOf('?') !== -1) {
        routerUrlNew = routerUrl.split('?')[0]
        paramList = routerUrl.split('?')[1].split('&')
        for (let i = 0; i < paramList.length; i++) {
            let obj = paramList[i].split('=')
            sendParam[obj[0]] = obj[1]
        }
    } else {
        routerUrlNew = routerUrl
    }

    return {routerUrlNew, sendParam}
}

// 需要下载app
export function needDownloadApp() {
    R_confirm({
        title: false,
        content: `该功能仅限APP客户端使用，请先下载${getProjectText('app名称')}APP。`,
        yesText: '去下载',
        yes: () => {
            window.location.href = APP_DOWNLOAD
        }
    })
}

// 是否json字符串
const isJsonStr = str => {
    if (typeof str === 'string') {
        try {
            return JSON.parse(str)
        } catch (e) {
            return false
        }
    }
    return false
}
/**
 * 递归解析包含json字符串的response
 * @param res
 * @returns new response
 */
export function parseRes(res) {
    if (typeof res !== 'object') {
        return res
    }
    // 先粗暴拷贝
    const copy = JSON.parse(JSON.stringify(res))

    const keys = Object.keys(copy)

    keys.forEach(key => {
        if (Array.isArray(copy[key])) {
            copy[key] = copy[key].map(parseRes)
        } else if (typeof copy[key] === 'object' && copy[key] !== null) {
            copy[key] = Object.keys(copy[key]).map(parseRes)
        } else if (isJsonStr(copy[key])) {
            copy[key] = JSON.parse(copy[key])
        }
    })

    return copy
}
