/**
 @description: loading 提示条
 @date: 2018/4/16
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example: <Loading show={true} text="加载数据中" />
 */
import React, {Component} from 'react'
import {View, StyleSheet, ActivityIndicator} from 'react-native'
import {COLOR_PRIMARY, COLOR_WHITE_60} from './Colors'
import Text from './Text'
import {tSize} from './VStyle'

const styles = StyleSheet.create({
    loadingWrap: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: tSize(10),
        alignItems: 'center'
    },
    loadingText: {
        fontSize: tSize(14),
        marginLeft: tSize(10)
    }
})
export default class Loading extends Component {
    onPress() {
        const {onPress, loading} = this.props
        if (loading) {
            return
        }
        if (onPress) {
            onPress()
        }
    }

    render() {
        const {
            show = false,
            size = 'small',
            text = '加载数据中',
            color = COLOR_PRIMARY,
            textColor = COLOR_WHITE_60,
            style,
            textStyle
        } = this.props
        if (show) {
            let textDom
            if (text) {
                textDom = (
                    <Text
                        style={[
                            styles.loadingText,
                            {
                                color: textColor,
                                fontSize: size === 'large' ? tSize(20) : tSize(14)
                            },
                            textStyle
                        ]}>
                        {text}
                    </Text>
                )
            }
            return (
                <View style={[styles.loadingWrap, style]}>
                    <ActivityIndicator color={color} size={size} />
                    {textDom}
                </View>
            )
        }
        return null
    }
}
