import {getProjectText} from './getProjetcBind'

/**
 @description:诸葛埋点工具函数
 @date: 2017/5/19
 @author: 雷利(Rayleight@baie.com.cn)
 */

/**
 * track 追踪事件
 * @param eventName
 * @param params
 * @example   Zhugeio.track('事件名称',{'属性1':'值1','属性2':'值2'});
 */
export function track(eventName, params = {}) {

}

/**
 * 自定义时长事件
 * @param eventName
 * @desc startTrack()与endTrack()需成对出现，单独使用没有效果。
 * @example   Zhugeio.startTrack('事件名称');
 */
export function startTrack(eventName) {

}

/**
 * 使用相同的事件名称结束事件统计
 * @param eventName
 * @param params
 * @desc startTrack()与endTrack()需成对出现，单独使用没有效果。
 * @example   Zhugeio.endTrack('事件名称'，{'属性1':'值1'});
 */
export function endTrack(eventName, params = {}) {

}

/**
 * 使用identify()识别用户
 * @param userId
 * @param params
 * @example  Zhugeio.identify('用户ID',{'用户属性':'用户值'})
 */
export function identify(userId, params = {}) {

}

/**
 *  实时调试
 */
export function openDebug() {

}

/**
 *  日志输出
 */
export function openLog() {

}

/**
 * 根据路由名转换中文
 * @param page
 * @returns {*}
 */
export function getPageName(page) {
    const MAP = {
        invite: '邀请',
        auditInvite: '邀请待审核',
        myPartnerInfo: '我的分销客户',
        partnerDetail: '上阶分销客户',
        partnerScoreDetails_nowMonth: '分销客户本月积分',
        partnerScoreDetails_lastMonth: '分销客户本月积分',
        partnerScoreDetails_thisYear: '分销客户本年积分',
        b2bCart: '进货单',
        b2bOrderGoods: '订货',
        b2bSendStep1: '发货',
        addressManage: '收货地址管理',
        managePasswordLogin: '登录密码管理',
        managePasswordTrade: '交易密码管理',
        about: `关于${getProjectText('app名称')}`,
        bindMobile: '手机号',
        recharge: '充值',
        withdraw: '提现',
        myWalletBank: '银行卡',
        payBackList: '扣款通知'
    }
    return MAP[page] ? MAP[page] : page
}
