import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import configureStore, {history} from './store/configureStore'
import App from './container/App'
import { initSentry } from './utils/sentry'
import VConsole from 'vconsole';

export const store = configureStore()

const rootEl = document.getElementById('root')

initSentry()
if (process.env.CUSTOM_NODE_ENV !== 'production') {
    const vconsole = new VConsole(); // 初始化VConsole
}

if (process.env.NODE_ENV !== 'test') {
    // 兼容jest

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>,
        rootEl
    )
    // registerServiceWorker();
}
