/**
 @description: 未命名
 @date: 2019/3/21
 @author: vivi@baie.com.cn
 @example: <QuickMenu {...this.props} share={true} shareItemData={}/>
 */
import React from 'react'
import {View, Animated, Easing, Image} from 'react-native'
import {push} from 'connected-react-router'
import Draggable from 'react-draggable'
import {connect} from 'react-redux'
import TouchableOpacity from '../TouchableOpacity'
import {tSize} from '../VStyle'
import * as StyleSheet from '../StyleSheet'
import TopItemWithIcon from './TopItemWithIcon'
import whiteList from './whiteList'
import {WEBVIEW_STARCARD_URI} from '../../constants/FetchUrls'
import {needOpenOld} from '../../utils/oldProjectUrl'
import {navigate} from '../../actions/Navigation'
import {setParamsForSearch} from '../../container/Route'
import {track} from '../../utils/ZGTools'
import {userDeviceIsWebIos} from '../../VUI/StyleSheet'
import {navigatorReplace} from '../../container/Common/AppDefaultView'
import {SIGN_OUT} from '../../constants/FetchUrls'
import {getMainDomain} from '../../utils/index'
import Text from '../../VUI/Text'
import {R_GET} from '../../utils/net'
import Cookies from 'js-cookie'

const styles = StyleSheet.create({
    main: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    bgBlack: {
        backgroundColor: '#000'
    },
    navigationIcon: {
        width: tSize(35),
        height: tSize(35)
    },
    back: {
        width: tSize(43),
        height: tSize(43)
    },
    showIcon: {
        width: tSize(55),
        height: tSize(46)
    },
    navigationBox: {
        height: tSize(179),
        paddingVertical: tSize(10),
        justifyContent: 'space-between',
        position: 'absolute',
        right: 0,
        bottom: tSize(80),
        width: tSize(60),
        backgroundColor: '#fff',
        borderTopLeftRadius: tSize(10),
        borderBottomLeftRadius: tSize(10),
        iPhoneX: {
            bottom: tSize(115)
        }
    },
    menuStyle: {
        position: 'absolute',
        right: 0
    },
    mt2: {
        marginTop: 2
    },
    showIconBox: {
        position: 'absolute',
        right: tSize(59),
        bottom: tSize(155),
        iPhoneX: {
            bottom: tSize(145)
        }
    }
})

const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity)

class QuickMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowMenu: false,
            isShowModal: false,
            fadeAnim: new Animated.Value(0),
            translateValue: new Animated.Value(tSize(60))
        }
    }

    get menuMap() {
        // const {
        //     navigation: {routeName}
        // } = this.props
        const routeName = this.props.router.location.pathname

        // whiteList.includes(routeName)
        // routeName !== 'mainTabs'
        // return routeName !== 'mainTabs' && routeName !== 'AccountGuideApp' && routeName !== 'SendGuideViewApp'
        //     ? [
        //         //   {
        //         //       imgUrl: require('../img/quick_menu/icon_index.png'),
        //         //       label: '首页',
        //         //       value: 'index'
        //         //   },
        //         //   {
        //         //       imgUrl: require('../img/quick_menu/icon_my.png'),
        //         //       label: '我的',
        //         //       value: 'mine'
        //         //   },
        //           {
        //               imgUrl: require('../img/quick_menu/back.png'),
        //               label: '退出',
        //               value: 'back'
        //           }
        //           // {
        //           //     imgUrl: require('../img/quick_menu/icon_zaotuan.png'),
        //           //     label: '前往早团',
        //           //     value: 'zaotuan'
        //           // }
        //       ]
        //     : []
        if (routeName === '/b2bNewUserConfirmLevelUpSettled') {
            return [
                {
                    imgUrl: require('../img/quick_menu/back.png'),
                    label: '退出',
                    value: 'back'
                }
            ]
        } else if (routeName !== '/mainTabs' && routeName !== '/AccountGuideApp' && routeName !== '/SendGuideViewApp') {
            return [
                {
                    imgUrl: require('../img/quick_menu/icon_index.png'),
                    label: '首页',
                    value: 'index'
                },
                {
                    imgUrl: require('../img/quick_menu/icon_my.png'),
                    label: '我的',
                    value: 'mine'
                },
                {
                    imgUrl: require('../img/quick_menu/back.png'),
                    label: '退出',
                    value: 'back'
                }
                  // {
                  //     imgUrl: require('../img/quick_menu/icon_zaotuan.png'),
                  //     label: '前往早团',
                  //     value: 'zaotuan'
                  // }
            ]
        } else {
            return []
        }
    }

    changeValue = (key, value) => {
        this.setState({[key]: value})
    }

    menuPress = val => {
        const {openView, userInfo, actions, navigator} = this.props
        this.quickBtnPress()
        // if(userInfo.grade && userInfo.grade == 5){
        //     return
        // }

        const timer = setTimeout(() => {
            clearTimeout(timer)
            switch (val) {
                case 'index':
                    track('web端快捷导航', {分类名称: '首页'})
                    openView('mainTabs', {tab: 'index'})
                    break
                case 'mine':
                    track('web端快捷导航', {分类名称: '我的'})
                    openView('mainTabs', {tab: 'my'})
                    break
                case 'back':
                    track('web端快捷导航', {分类名称: '退出'})
                    localStorage.removeItem('UserIsShow')
                    localStorage.removeItem('agreement')
                    R_GET(SIGN_OUT)
                    Cookies.remove('TokenSign', {domain: getMainDomain(window.location.hostname)})
                    window.location.href = '/'
                    break
                default:
                    break
            }
        }, 300)
    }

    quickBtnPress = () => {
        const {isShowMenu, isShowModal, fadeAnim, translateValue} = this.state
        let fadeAnimValue = 0.6
        let translateToValue = 0

        if (isShowMenu) {
            fadeAnimValue = 0
            translateToValue = tSize(60)
        } else {
            this.changeValue('isShowModal', true)
        }
        Animated.parallel([
            Animated.timing(translateValue, {toValue: translateToValue, easing: Easing.linear, duration: 300}),
            Animated.timing(fadeAnim, {toValue: fadeAnimValue, easing: Easing.linear, duration: 300})
        ]).start(() => {
            this.changeValue('isShowModal', !isShowModal)
        })
        this.changeValue('isShowMenu', !isShowMenu)
    }

    render = () => {
        const {isShowMenu, isShowModal, fadeAnim, translateValue} = this.state
        const {
            appData: {quickMenu},
            userInfo
        } = this.props
        const transformStyle = {transform: [{translateX: translateValue}]}
        if (!this.menuMap.length || !quickMenu) {
            return null
        }
        if (!userInfo.grade) {
            return null
        }

        const {showBack} = this.props

        const showIcon = require(`../img/quick_menu/icon_${isShowMenu ? 'back' : 'quick'}.png`)

        if (false) {
            return (
                <Draggable axis="y">
                    <div>
                        <View style={{zIndex: 98}}>
                            <TouchableOpacity onPress={this.quickBtnPress} style={styles.showIconBox}>
                                <Text>退出</Text>
                            </TouchableOpacity>
                        </View>
                    </div>
                </Draggable>
            )
        }

        return (
            <Draggable axis="y">
                <div>
                    <View style={{zIndex: 98}}>
                        {isShowModal && (
                            <AnimatedTouchableOpacity
                                style={[styles.main, styles.bgBlack, {opacity: fadeAnim}]}
                                onPress={this.quickBtnPress}
                            />
                        )}
                        <Animated.View style={[{flexDirection: 'row'}, styles.menuStyle, transformStyle]}>
                            <TouchableOpacity onPress={this.quickBtnPress} style={styles.showIconBox}>
                                <Image style={styles.showIcon} source={showIcon} />
                            </TouchableOpacity>
                            <View style={styles.navigationBox}>
                                {this.menuMap.map(({imgUrl, label, value}) => (
                                    <TopItemWithIcon
                                        icon={imgUrl}
                                        iconStyle={value == 'back' ? styles.back : styles.navigationIcon}
                                        label={label}
                                        labelStyle={[{fontSize: tSize(10)}, styles.mt2]}
                                        onPress={() => this.menuPress(value)}
                                        style={{justifyContent: 'center'}}
                                        key={value}
                                    />
                                ))}
                            </View>
                        </Animated.View>
                    </View>
                </div>
            </Draggable>
        )
    }
}
export default connect(
    state => state,
    dispatch => ({
        openView: (routeName, _param) => {
            console.log('openView', routeName, _param)
            const param = {}
            Object.keys(_param).forEach(key => {
                if (typeof _param[key] !== 'function') {
                    param[key] = _param[key]
                }
            })

            const oldProjectUrl = needOpenOld(routeName, param)
            console.log(routeName, param, oldProjectUrl)
            // return
            if (routeName === 'webPage' && param && param.uri) {
                window.location.href = param.uri
            } else if (oldProjectUrl) {
                window.location.href = oldProjectUrl
            } else {
                dispatch(navigate(routeName))
                dispatch(push(`/${routeName}${setParamsForSearch(param)}`, param))
            }
        }
    })
)(QuickMenu)
