/**
 @description: 需要微信授权的组件需要在PrivateRoute传一个authType 然后在AuthMiddleRoute做完授权之后再render具体的页面组件
 @date: 2018/12/20
 @author: aha2mao(yanghanjia@baie.com.cn)
 */
import {View} from 'react-native'
import Cookies from 'js-cookie'
import qs from 'qs'
import React from 'react'
import PropTypes from 'prop-types'
import {isWeb, isInWechat, isWebIos} from '../VUI/VStyle'
import {wxAuth} from '../utils/WechatApi'
import {getMainDomain} from '../utils'

export default class AuthMiddleRoute extends React.Component {
    static defaultProps = {
        authType: ''
    }

    static propTypes = {
        authType: PropTypes.oneOf(['Yunchuang', 'BaieAPP', 'MengQi','Pinjiankang'])
    }

    constructor(props) {
        // 只考虑页面级别的组件的话props应该是不用继承了
        super(props)
        this.state = {
            renderEmpty: true
        }
    }

    componentDidMount = () => {
        // 根据公众号支付场景（易宝、环迅、分账通）决定哪个公众号授权
        const {authType} = this.props
        if (authType === '') {
            return
        }
        if (isWeb && isInWechat) {
            setTimeout(() => {
                // 明明query加了refreshWx微信还是取不到？？？ 200ms顶住！
                const query = qs.parse(global.location.search.slice(1))
                // 如果是授权回调回来的 取search里的openid、appid存到cookie (key以授权类型为前缀)
                // BaieAPP_openid、Yunchuang_openid
                if (query.oauth === 'wechat' && query.openid && query.appid) {
                    // 微信授权回来 帮我们把支付页重载的目的也达成了
                    this.setCookie(`${authType}_openid`, query.openid)
                    this.setCookie(`${authType}_appid`, query.appid)
                    this.setState({renderEmpty: false})
                } else if (this.isNeedTriggerAuth()) {
                    // cookie取不到openid, 去授权 然后走第一个if的分支
                    wxAuth(global.location.href, authType).then(response => {
                        global.location.replace(response.data.oauthUrl)
                    })
                } else if (!query.refreshWx && isWebIos) {
                    // ios 环境下，cookie取到了openid 但query没有refreshWx 需要手动reload且加上refreshWx标记
                    const {origin, pathname} = global.location
                    global.location.replace(
                        `${origin}${pathname}?${qs.stringify({
                            ...query,
                            refreshWx: 1
                        })}`
                    )
                } else {
                    this.setState({renderEmpty: false})
                }
            }, 200)
        } else {
            this.setState({renderEmpty: false})
        }
    }

    isNeedTriggerAuth = () => {
        const {authType} = this.props
        // 如果授权类型为空 则不需要进行授权 render Component
        if (authType === '') {
            return false
        }
        return isWeb && isInWechat && !Cookies.get(`${authType}_openid`)
    }

    setCookie = (key, value) => {
        Cookies.set(key, value, {expires: 365, domain: getMainDomain(global.location.hostname)})
    }

    render() {
        const {component: Component} = this.props
        return this.state.renderEmpty ? <View /> : <Component {...this.props} />
    }
}
