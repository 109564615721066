/**
 @description: 系统内用到的颜色
 @date: 2018/1/8
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */

export const COLOR_PRIMARY = '#FCD000'
export const COLOR_PJK = '#1D9248'
export const PRIMARY_COLOR = COLOR_PRIMARY
export const PRIMARY_COLOR_M = '#f43131'
export const COLOR_YELLOW = '#F9B337'
export const COLOR_PINK = "#FE4543"
export const COLOR_WARNING = "#FE4543"

// tips： COLOR_WHITE_ 后面的数字表示有多白，越大越白；越小越黑；
export const COLOR_WHITE_96 = '#F4F4F4'
export const COLOR_WHITE_92 = '#EAEAEA'
export const COLOR_WHITE_86 = '#DDDDDD'
export const COLOR_WHITE_82 = '#E4E4E4'
export const COLOR_WHITE_80 = '#CCCCCC'
export const COLOR_WHITE_74 = '#BDBDBD'
export const COLOR_WHITE_60 = '#999999'
export const COLOR_WHITE_40 = '#666666'
export const COLOR_WHITE_24 = '#333333'

export const lightText = '#9e9e9e'
export const grayText = '#999'
export const green = '#339925'

export const COLOR_BLUE = '#579BD6'
export const COLOR_RED = '#E95741'
export const COLOR_GREEN = '#5ECF5E'
export const COLOR_BROWN = '#C9945A'
export const LINK_COLOR2 = '#ff9900'

export const disableColor = COLOR_WHITE_80
export const disableText = '#BBBBBB'
export const normalText = COLOR_WHITE_24
export const labelText = COLOR_WHITE_60
export const linkColor = '#418ce3'
export const darkText = COLOR_WHITE_24
export const borderColor = COLOR_WHITE_92
export const pageBackgroundColor = COLOR_WHITE_96
export const placeholderText = COLOR_WHITE_80
export const BTN_PRIMARY_BG = PRIMARY_COLOR
export const goodsAreaBg = '#f6f6f6'
export const borderColorLight = '#f8f8f8'

export const c_333 = '#333333'
export const c_666 = '#666666'
export const c_999 = '#999999'
export const c_eaeaea = '#eaeaea'
export const c_fff = '#ffffff'
export const c_blue = '#579BD6'
export const c_bg = '#f4f4f4'
export const c_ccc = '#cccccc'
export const c_primary = '#ea5503'
export const c_f4f4f4 = '#f4f4f4'
export const c_red = '#F43030'
export const c_green = '#41BC41'

export const FONT_COLOR_IN_PRIMARY = c_333
export const FONT_COLOR_IN_DISABLE = c_fff

export const pageHeaderBackgroundColor = '#5d6b85'
