/**
 @description: 文本类相关的配置
 @date: 2018/2/28
 @author: 雷利(Rayleight@baie.com.cn)
 */

//============  重要说明  ============
//假如有一段比较长的文本，那么也应该拆分到 `./extra/` 目录下；
//建一个 text_ 开头的 js，比如 `text_example.js`
//  50字以上的为比较长的文本
//============  end  ============

import {GLOBAL_PROJECT_TEXT} from "../global_config";

export default GLOBAL_PROJECT_TEXT