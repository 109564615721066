/**
 @description: 解决移动滑动点击
 @date: 2018/5/18
 @author: adultcn(pangjianan@baie.com.cn)
 @example: <TouchableOpacity onPress={}>文本组件</TouchableOpacity>
 */
import React, {Component} from 'react'

import {flatten} from './StyleSheet'

export default class TouchableOpacity extends Component {
    constructor(props) {
        super(props)

        this.triggerOnPress = this.triggerOnPress.bind(this)
    }

    triggerOnPress(e) {
        const {disabled = false} = this.props

        if (disabled) {
            return
        }
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        const {onPress} = this.props
        if (onPress) {
            onPress()
        }
    }

    render() {
        const {style, children, hitSlop, hide,isShow=false} = this.props
        if (hide) {
            return null
        }
        let hitSlopDom = null

        if (hitSlop) {
            const {left = 0, top = 0, right = 0, bottom = 0} = hitSlop
            hitSlopDom = <div style={{position: 'absolute', left: -left, top: -top, right: -right, bottom: -bottom}} />
        }

        let resultStyle = flatten(style)
        resultStyle = {...resultStyle} // 这一行是为了修复 object is not extensible bug

        // 由于web不支持 paddingVertical 这些写法，需要修改为paddingTop这中写法
        const transFormConfig = {
            paddingVertical: ['paddingTop', 'paddingBottom'],
            paddingHorizontal: ['paddingLeft', 'paddingRight'],
            marginVertical: ['marginTop', 'marginBottom'],
            marginHorizontal: ['marginLeft', 'marginRight']
        }
        for (const configKey in transFormConfig) {
            if (resultStyle && resultStyle[configKey]) {
                for (let i = 0; i < transFormConfig[configKey].length; i++) {
                    let afterKey = transFormConfig[configKey][i]
                    try {
                        resultStyle[afterKey] = resultStyle[configKey]
                    } catch (e) {
                        console.log(e, resultStyle)
                    }
                }
            }
        }

        return (
            <div className={isShow?'TouchableOpacityMore':'TouchableOpacityM'} style={resultStyle} onClick={this.triggerOnPress}>
                {hitSlopDom}
                {children}
            </div>
        )
    }
}
