/**
 @description: sentry 相关方法
 @date: 2019-07-02
 @author: 雷利(Rayleight@baie.com.cn)
 */

import {version} from '../../package.json'


export function initSentry() {

}

/**
 * 设置用户属性
 * @param id
 * @param username
 */
export function setSentryUser({id, username}) {

}

/**
 * 设置tag
 * @param key
 * @param value
 */
export function setSentryTag(key, value) {

}

/**
 * 添加一个事件埋点
 * @param category
 * @param message
 * @param level
 */
export function addBreadcrumb(category, message, level = Sentry.Severity.Info) {

}

/**
 * 捕获错误
 * @param err
 */
export function captureException(err) {

}

/**
 * 捕获错误
 * @param msg | string
 * @param level
 */
export function captureMessage(msg, level = Sentry.Severity.Warning) {

}

/**
 * 处理接口返回的信息
 * @param response
 */
export function traceResponseError(response) {

}
