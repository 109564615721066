/**
 @description: 定制版StyleSheet
 @date: 2018-1-10
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import {StyleSheet, Platform} from 'react-native'

// See https://mydevice.io/devices/ for device dimensions
export const isIPhoneX = false

export const isAndroid = Platform.OS === 'android'
export const isWeb = Platform.OS === 'web'
export const isIos = Platform.OS === 'ios'

const userAgent = window.navigator.userAgent
const locationSearch = window.location.search
let isInAppWebview = /platform=baieapp/i.test(locationSearch)

// webview中如果触发了 location跳转 这个 location.search 上的 platform参数 极有可能丢失了，那么这里使用sessionStorage来存下来
if (isInAppWebview) {
    window.localStorage.setItem('__IN_APP_WEBVIEW', 'YES')
}
if (window.localStorage.getItem('__IN_APP_WEBVIEW') === 'YES') {
    isInAppWebview = true
}

export const isInWechat = /micromessenger/i.test(userAgent)
export const isInApp = isInAppWebview
export const isWebIos = /iPhone|iPad|iPod/i.test(userAgent)

export const userDeviceIsInWechat = /micromessenger/i.test(userAgent) // 是否在 微信中访问 web项目
export const userDeviceIsWebAndroid = /Android/i.test(userAgent) // 是否在 安卓浏览器（包含各种app内的webview）中访问web项目
export const userDeviceIsWebIos = /iPhone|iPad|iPod/i.test(userAgent) // 是否在 iOS浏览器（包含各种app内的webview）中访问web项目
export const userDeviceIsInAppWebview = isInAppWebview // 用户正在 百e云创app的webview内访问

export function create(styles) {
    const platformStyles = {}
    Object.keys(styles).forEach(name => {
        let {ios, android, iPhoneX, web, wechat, ...style} = {...styles[name]}
        if (ios && Platform.OS === 'ios') {
            style = {...style, ...ios}
        }
        if (isIPhoneX && iPhoneX) {
            style = {...style, ...iPhoneX}
        }
        if (isWeb && web) {
            style = {...style, ...web}
        }
        if (isInWechat && wechat) {
            style = {...style, ...wechat}
        }
        if (android && Platform.OS === 'android') {
            style = {...style, ...android}
        }
        platformStyles[name] = style
    })
    return StyleSheet.create(platformStyles)
}

export function flatten(style) {
    return StyleSheet.flatten(style)
}

export function parseStyleToClassName(style) {
    return css.apply(this,transStyleToArray(style))
}
