/**
 @description: 404
 @date: 2018/9/15
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import React, {Component} from 'react'

import {View, Image} from 'react-native'

import AppHeaderWithBack from '../../VUI/AppHeaderWithBack'
import * as StyleSheet from '../../VUI/StyleSheet'
import Text from '../../VUI/Text'
import Button from '../../VUI/Button'

import {COLOR_WHITE_60, PRIMARY_COLOR} from '../../VUI/Colors'
import VStyle, {tSize} from '../../VUI/VStyle'
import {track} from '../../utils/ZGTools'
import {getProjectText} from '../../utils/getProjetcBind'

const styles = StyleSheet.create({
    img: {
        width: tSize(220),
        height: tSize(130),
        marginTop: tSize(140)
    },
    text: {
        color: COLOR_WHITE_60,
        fontSize: tSize(15),
        marginTop: tSize(20)
    }
})

export default class NotFoundView extends Component {
    constructor(props) {
        super(props)
        this.closeView = this.closeView.bind(this)
        this.replacePage = this.replacePage.bind(this)
    }

    componentDidMount() {
        const {location} = this.props
        try {
            track('进入404页面', {
                path: location.pathname
            })
        } catch (e) {}
        console.log(location)
    }

    closeView() {
        this.props.navigator.pop()
    }

    replacePage(pageName) {
        this.props.navigator.replace({[pageName]: {}})
    }

    render() {
        const {type} = this.props.param

        let desc = '抱歉！您访问的页面失联了…'
        if (type === 'retailConfirm') {
            desc = `链接已失效，请联系${getProjectText('用户集合名称')}重新发送确认链接`
        }

        return (
            <View style={VStyle.container_bg}>
                <AppHeaderWithBack title="404" closeView={this.closeView} />
                <View style={{alignItems: 'center'}}>
                    <Image style={styles.img} source={require('../../img/app/err_404.png')} />
                    <Text style={styles.text}>{desc}</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: tSize(30)}}>
                        <Button
                            type="bordered"
                            color={PRIMARY_COLOR}
                            caption="返回首页"
                            onPress={this.replacePage.bind(this, 'mainTabs')}
                        />
                    </View>
                </View>
            </View>
        )
    }
}
