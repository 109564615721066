/**
 @description: 跨平台通用的函数
 @date: 2018/3/26
 @author: 雷利(Rayleight@baie.com.cn)
 */
import {Platform} from 'react-native'
import packageInfo from '../../package.json'
import {URI_GET, USER_ADDRESS_DETAIL, USER_ADDRESS_LIST, USER_ADDRESS_PRIORITY_GET} from '../constants/FetchUrls'
import {R_GET, R_confirm} from './index'
import {isWeb} from '../VUI/VStyle'
import * as AppConfig from '../constants/AppConfig'

const USER_AGENT_IOS = `Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1 BaiE_APP V${packageInfo.version}`
const USER_AGENT_ANDROID = `Mozilla/5.0 (Linux; Android 6.0.1; SM-C7000 Build/MMB29M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/55.0.2883.91 Mobile Safari/537.36 Language/zh_CN BaiE_APP V${packageInfo.version}`

export const USER_AGENT = Platform.OS === 'ios' ? USER_AGENT_IOS : USER_AGENT_ANDROID

/**
 * 判断字符串是否为纯数字
 * @param str
 * @returns {boolean}
 */
export function isNumber(str) {
    return /^\d+$/.test(str)
}

/**
 * 转换为价格格式
 * @param number - 需要处理的数字
 * @param length - 小数点后面的位置
 * @param toFixed - 是否强制占满小数点后面的位置
 * @returns {*}
 */
export function currency(number, length = 2, toFixed = true) {
    if (isNaN(number)) {
        number = 0
    }
    const temp = Math.round(number * Math.pow(10, length)) / Math.pow(10, length)
    return toFixed ? temp.toFixed(2) : temp
}

/**
 * 转换为价格格式
 * @param number
 * @returns {*}
 */
export function currency2(number) {
    if (number && number.toFixed) {
        return number.toFixed(2).split('.')
    }
    return ['0', '00']
}

/**
 * 返回数字
 * @param number
 * @returns {*}
 */
export function toNumber(number) {
    const num = parseFloat(number)
    if (isNaN(num)) {
        return 0
    }
    return num
}

/**
 * 对象转换为url参数形式的字符串
 * @param obj
 * @returns string
 */
export function parseParam(obj) {
    try {
        return Object.keys(obj)
            .map(k => `${k}=${encodeURIComponent(obj[k])}`)
            .join('&')
    } catch (e) {
        return ''
    }
}

Date.prototype.Format = function(fmt) {
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length))
    for (const k in o)
        if (new RegExp(`(${k})`).test(fmt))
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length))
    return fmt
}

/**
 * 判断是不是手机号
 * @param number
 * @returns {boolean}
 */
export function isMobile(number) {
    return /^1\d{10}$/.test(`${number}`)
}

/**
 * 判断是不是身份证号
 * @param number
 * @returns {boolean}
 */
export function isIdCard(number) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(`${number}`)
}

/**
 * 判断是不是手机验证码
 * @param number
 * @returns {boolean}
 */
export function isMobileCaptcha(number) {
    return /^\d{6}$/.test(`${number}`)
}

/**
 * 判断会员名格式是不是正确
 * @param username
 * @returns {boolean}
 */
export function isUserName(username) {
    return /^[\w\u4e00-\u9fa5]{2,19}$/.test(`${username}`)
}

/**
 * 创建时间
 * @param string - yyyy-MM-DD hh:mm:ss
 * @returns Date
 */
export function getDate(string) {
    if (typeof string === 'number') {
        return new Date(string)
    }
    if (!string || !string.replace) {
        return string
    }
    string = string.replace(/[-:]/g, ' ').split(' ')
    const date = new Date(string[0], string[1] - 1, string[2], string[3], string[4], string[5])
    return date
}

/**
 * 判断是否需要实名认证然后跳转
 * @param pageName
 * @param userInfo
 * @param param
 * @param navigation
 */
export function checkWalletAndOpen(pageName, userInfo, param, navigation) {
    const {walletChannel, grade, walletInfo, bankCode, walletOpenStatus} = userInfo
    const {status} = walletInfo
    if (walletChannel === 'yeepay') {
        if (
            pageName === 'b2bOrderGoods' ||
            pageName === 'replaceGoodsIndex' ||
            pageName === 'b2bSendStep1' ||
            pageName === 'recharge' ||
            pageName === 'myConsume' ||
            pageName === 'myAsset' ||
            pageName === 'myStock' ||
            pageName === 'scoreSummary' ||
            pageName === 'myPartner' ||
            pageName === 'withdraw'
        ) {
            if (grade && grade < 3 && pageName !== 'invite') {
                return navigation.navigate(pageName, param)
            }
            if (isNeedAuthWallet(userInfo)) {
                return R_confirm({
                    content: '为了您的资金安全，请先进行\r\n百e资格认证再访问此页面',
                    yesText: '去认证',
                    yes: () => {
                        navigation.navigate('newWalletConfirm', {isCompany: false})
                    }
                })
            }
        }
        if (pageName === 'invite') {
            if (isNeedAuthWallet(userInfo)) {
                return navigation.navigate('newWalletConfirm', {isCompany: false})
            }
        }
    }

    if (walletChannel === 'TFS') {
        if (pageName === 'b2bOrderGoods' || pageName === 'b2bSendStep1' || pageName === 'myStock') {
            if (isNeedAuthWallet(userInfo)) {
                if (status !== '正常' || bankCode == null || bankCode == '' || !bankCode) {
                    return navigation.navigate('newWalletConfirm', {isCompany: false, toPage: pageName, ...param})
                }
                return R_confirm({
                    content:
                        '为提升您的支付体验，现需要您重新开通厦门国际银行账户。开户成功后，原有账户余额将自动提现至开户银行卡。请注意查看银行短信，必要时可查看交易记录。',
                    yesText: '去开户',
                    yes: () => {
                        navigation.navigate('newWalletConfirm', {isCompany: false, toPage: pageName, ...param})
                    }
                })
            }
        }
        if (
            pageName === 'invite' ||
            pageName === 'replaceGoodsIndex' ||
            pageName === 'recharge' ||
            pageName === 'myConsume' ||
            pageName === 'myAsset' ||
            pageName === 'myPartner' ||
            pageName === 'withdraw' ||
            pageName === 'newWalletIndex' ||
            pageName === 'newBankCard' ||
            pageName == 'rechargeNew'
        ) {
            if (isNeedAuthWallet(userInfo)) {
                if (status !== '正常' || bankCode == null || bankCode == '' || !bankCode) {
                    return navigation.navigate('newWalletConfirm', {isCompany: false, toPage: pageName, ...param})
                }
                return R_confirm({
                    content:
                        '为提升您的支付体验，现需要您重新开通厦门国际银行账户。开户成功后，原有账户余额将自动提现至开户银行卡。请注意查看银行短信，必要时可查看交易记录。',
                    yesText: '去开户',
                    yes: () => {
                        navigation.navigate('newWalletConfirm', {isCompany: false, toPage: pageName, ...param})
                    }
                })
            }
        }
    }
    navigation.navigate(pageName, param)
}

/**
 * 判断是否需要实名认证
 * @param userInfo
 * @returns {boolean}
 */
export function isNeedAuthWallet(userInfo) {
    const {walletChannel, walletOpenStatus, bankCode} = userInfo
    /*
     * 需要判断是否是分帐通用户
     * 若是分帐通用户 判断分帐通是否开通
     * 若是易宝用户 判断银行卡信息
     * */
    const {status} = userInfo.walletInfo
    if (walletChannel === 'yeepay') {
        if (status !== '正常' || bankCode == null || bankCode == '' || !bankCode) {
            return true
        }
    }
    if (walletChannel === 'TFS') {
        if (!walletOpenStatus) {
            return true
        }
    }
    return false
}

function removeEmoji(str, params) {
    if (params && params.__emoji) {
        return str
    }
    if (str && str.replace) {
        /* eslint-disable */
        // let newStr = str.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/gm, "")//替换 emoji 表情(不支持ios 10.2后新增的emoji)
        let newStr = str.replace(
            /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gim,
            ''
        ) // 替换 emoji 表情
        /* eslint-enable */
        if (newStr == '') {
            newStr = ''
        }
        return newStr
    }
    return str
}

/**
 * 跳转到订单详情
 * @param navigator
 * @param externalNo
 * @param ifClearing3Flg
 */
export function goToDetail(navigator, externalNo, ifClearing3Flg) {
    return new Promise(function(resolve, reject) {
        getURIRedirectInfo(externalNo, ifClearing3Flg)
            .then(pageInfo => {
                navigator.push({
                    [pageInfo.type]: pageInfo.param
                })
                resolve(pageInfo)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 根据流水号，判断要打开的订单详情
 * @param externalNo
 * @param ifClearing3Flg
 */
export function getURIRedirectInfo(externalNo, ifClearing3Flg = false) {
    return new Promise(function(resolve, reject) {
        if (externalNo && externalNo.indexOf && externalNo.indexOf('refund') !== -1) {
            return resolve({
                type: 'b2bRefundDetail',
                param: {
                    id: externalNo
                }
            })
        }
        R_GET(URI_GET, {externalNo, ifClearing3Flg})
            .then(response => {
                const {ordersCode} = response.data
                switch (response.data.code) {
                    case '0': {
                        resolve({
                            type: 'b2bOrderDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '1':
                    case '11':
                    case '3': {
                        resolve({
                            type: 'b2bPartnerOrderDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '2': {
                        resolve({
                            type: 'b2bPartnerOrderClearingDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '4': {
                        resolve({
                            type: 'b2bPartnerOrderClearingFinishDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '00':
                    case '05':
                    case '001': {
                        resolve({
                            type: 'b2cOrderDetail',
                            param: {
                                id: ordersCode,
                                type: 'forBuyer'
                            }
                        })
                        break
                    }
                    case '01':
                    case '03':
                    case '07': {
                        resolve({
                            type: 'b2cOrderDetail',
                            param: {
                                id: ordersCode,
                                type: 'forSeller'
                            }
                        })
                        break
                    }
                    case '02': {
                        resolve({
                            type: 'b2cOrderClearingDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '04':
                    case '06': {
                        resolve({
                            type: 'b2cOrderClearingFinishDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '10': {
                        resolve({
                            type: 'b2bSendDetail',
                            param: {
                                id: ordersCode
                            }
                        })
                        break
                    }
                    case '12': {
                        resolve({
                            type: 'stockMove',
                            param: {
                                numberCode: externalNo
                            }
                        })
                        break
                    }
                    default:
                        reject(`暂无详情:${response.data.code}`)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 数字千分位分割
 * @param num
 * @param toFixed - 最多几位小数
 * @returns {*}
 */
export function numberFormat(num, toFixed) {
    if (toFixed && num && num.toFixed) {
        // 这里 可以刻意 != 的，用于判断num是否为整数
        if (parseInt(num, 10) != num) {
            num = num.toFixed(toFixed)
        }
    }
    try {
        if (toFixed === 0) {
            num = parseInt(num, 10)
        } else {
        }
    } catch (e) {}

    if (num && num.toString) {
        const num_str = num.toString()
        const num_split = num_str.split('.') // 针对有小数的情况
        let num_1 = num_split[0].replace(/(?=(?!^)(\d{3})+$)/g, ',') // 整数部分
        if (num_split.length > 1) {
            num_1 += `.${num_split[1]}`
        }
        return num_1
    }
    return '0'
}

/**
 * 获取用户地址列表
 * @param nowCheckedId - 当前选中的地址id
 * @returns {Promise<{ addressChecked:Object,addressCheckedId: number}>}
 */
export async function getUserAddressList(nowCheckedId) {
    const priority = await getPriorityAddress()
    const nowCheckedAddress = await checkIsAddressExist(nowCheckedId)

    let returnAddress = {id: 0}

    // 如果nowCheckedId的地址存在，那么就返回这个地址， address 属性 已被删除，后面都用 addressChecked
    // 如果nowCheckedId的地址不存在， 那么就去取默认地址
    // 如果默认地址还不存在，那么就去取地址列表

    if (nowCheckedAddress.id) {
        returnAddress = nowCheckedAddress
    } else if (priority.id) {
        returnAddress = priority
    } else {
        const page1AddressList = await getAddressList()
        if (page1AddressList.length) {
            returnAddress = page1AddressList[0]
        }
    }

    return {
        addressCheckedId: returnAddress.id,
        addressChecked: returnAddress,
        address: [returnAddress]
    }
}

/**
 * 获取用户的默认地址
 * @returns {Promise<Object>}
 */
function getPriorityAddress() {
    return new Promise(function(resolve, reject) {
        R_GET(USER_ADDRESS_PRIORITY_GET)
            .then(response => {
                resolve(filterAddressInfo(response))
            })
            .catch(() => {
                resolve({})
                // 接口请求失败，返回空的，逻辑判断在 调用处
            })
    })
}

/**
 * 检测地址是否存在
 * @param id
 * @returns {Promise<any>}
 */
function checkIsAddressExist(id) {
    return new Promise(function(resolve, reject) {
        R_GET(USER_ADDRESS_DETAIL, {id}, true)
            .then(response => {
                if (response.error_code === '1') {
                    resolve(filterAddressInfo(response))
                } else {
                    resolve({})
                }
            })
            .catch(() => resolve({})) // 接口请求失败，返回空地址
    })
}

function filterAddressInfo(address) {
    const {id, username, province, city, area, detailaddress, tel, cardNo, priority} = address
    return {id, username, province, city, area, detailaddress, tel, cardNo, priority}
}

/**
 * 获取用户地址列表
 * @returns {Promise<any>}
 */
function getAddressList() {
    return new Promise(function(resolve, reject) {
        R_GET(USER_ADDRESS_LIST, {})
            .then(response => {
                resolve(response.items)
            })
            .catch(() => resolve([]))
    })
}

/**
 * 判断是否位正确的token
 * @param token
 * @returns {boolean}
 */
export function isRightToken(token) {
    return !!(token && token.length > 16)
}
/* 获取地址的参数 值
 * @param url
 * @returns {*}
 */
export function getUrlRequest(url) {
    try {
        const str = url.split('?')[1]
        const theRequest = {}
        if (str.indexOf('&') !== -1) {
            const strs = str.split('&')
            for (let i = 0; i < strs.length; i++) {
                theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
            }
        } else {
            const key = str.substring(0, str.indexOf('='))
            const value = str.substr(str.indexOf('=') + 1)
            theRequest[key] = decodeURIComponent(value)
        }
        return theRequest
    } catch (e) {}
    return {}
}

/**
 * 时间获取毫秒数： "2018-09-10 15:25:33" => 1536621933000
 * @param timeStr
 * @returns {number}
 */
export function getTimeNumber(timeStr) {
    return new Date(timeStr.replace(/-/gim, '/')).getTime()
}

/**
 * 获取经销商等级描述文本
 * @param grade
 * @returns {*}
 */
export const {getUserGradeText} = AppConfig
export const getUserGrade = AppConfig.getGradeInfo

/**
 * alert
 * @param config
 */
export function R_alert(config) {}

/**
 * 判断是否分帐通用户
 * @param userInfo
 * @returns {boolean}
 */
export function isTFSUser(userInfo) {
    return userInfo && userInfo.walletChannel === 'TFS'
}
