/**
 @description: 按钮
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example: <Button loading={isSaving} onPress={this.save.bind(this)} style={{}} buttonStyle={{}} caption="确定"/>
 */
import React, {Component} from 'react'
import {View, Image, StyleSheet, ActivityIndicator} from 'react-native'

import Text from '../VUI/Text'
import TouchableOpacity from '../VUI/TouchableOpacity'
import {TRUE_ONE_PIXEL, tSize} from '../VUI/VStyle'
import {FONT_COLOR_IN_PRIMARY} from '../VUI/Colors'
import {COLOR_WHITE_24, BTN_PRIMARY_BG, PRIMARY_COLOR, lightText, linkColor, labelText} from '../VUI/Colors'

const HEIGHT = tSize(50)

var styles = StyleSheet.create({
    container_default: {
        height: HEIGHT - tSize(10)
    },
    container_lg: {
        height: HEIGHT - tSize(5)
    },
    container_sm: {
        height: HEIGHT - tSize(22)
    },
    container_ssm: {
        height: HEIGHT - tSize(28)
    },
    container_xs: {
        height: HEIGHT - tSize(33)
    },
    container_bottom: {
        height: HEIGHT
    },
    button: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: tSize(10),
        backgroundColor: '#fff'
    },
    button_sm: {
        paddingHorizontal: tSize(10),
        borderRadius: tSize(5)
    },
    button_xs: {
        paddingHorizontal: 0,
        borderRadius: tSize(3)
    },
    button_bottom: {
        paddingHorizontal: 0,
        borderRadius: 0
    },
    button_link: {
        backgroundColor: 'transparent'
    },
    border: {
        borderWidth: TRUE_ONE_PIXEL,
        borderColor: '#bbb',
        borderRadius: tSize(5)
    },
    button_bordered: {
        backgroundColor: 'transparent'
    },
    primaryButton: {
        borderRadius: tSize(5),
        backgroundColor: BTN_PRIMARY_BG
    },
    icon: {
        marginRight: tSize(5),
        width: tSize(18),
        height: tSize(18)
    },
    caption: {
        letterSpacing: 1,
        fontSize: tSize(15)
    },
    caption_sm: {
        fontSize: tSize(13)
    },
    caption_ssm: {
        fontSize: tSize(14)
    },
    caption_xs: {
        fontSize: tSize(10)
    },
    caption_lg: {
        fontSize: tSize(18)
    },
    caption_link: {
        color: '#108ee9'
    },
    caption_flat: {
        color: PRIMARY_COLOR
    },
    caption_bottom: {
        fontSize: tSize(18)
    },
    button_flat: {},
    primaryCaption: {
        color: 'white'
    },
    secondaryCaption: {
        color: lightText
    },
    loading: {
        marginRight: tSize(5)
    },
    disableStyle: {
        opacity: 0.8,
        backgroundColor: '#c6c6c6'
    }
})

export default class Button extends Component {
    onPress() {
        const {onPress, loading, disable, onDisablePress} = this.props
        if (loading) {
            return
        }
        if (disable) {
            if (onDisablePress) {
                onDisablePress()
            }
        } else {
            if (onPress) {
                onPress()
            }
        }
    }

    render() {
        const {
            caption,
            loadingCaption,
            icon,
            type = 'primary',
            size = 'default',
            loading,
            style,
            buttonStyle,
            textStyle,
            color,
            disable,
            onDisablePress
        } = this.props
        let captionText = caption

        let textColor = color
        if (!textColor) {
            if (type == 'primary') {
                textColor = FONT_COLOR_IN_PRIMARY
            } else {
                textColor = COLOR_WHITE_24
            }
        }
        if (type == 'link') {
            textColor = linkColor
        }
        let iconImage
        if (icon && !loading) {
            iconImage = <Image source={this.props.icon} resizeMode="stretch" style={styles.icon} />
        }

        let content
        let loadingDom
        let disableStyle
        let disableText
        if (loading) {
            captionText = loadingCaption || caption
            disableStyle = styles.disableStyle
            if (size != 'xs') {
                loadingDom = (
                    <ActivityIndicator animating={true} style={styles.loading} color={textColor} size="small" />
                )
            }
        }
        if (disable) {
            disableStyle = styles.disableStyle
        }
        if (type !== 'primary') {
            if (type !== 'common') {
                disableStyle = {}
            }
        }
        if (type == 'common' && disable) {
            disableText = {
                color: FONT_COLOR_IN_PRIMARY
            }
        }
        if (type === 'primary') {
            content = (
                <View
                    style={[styles.button, styles.primaryButton, styles[`button_${size}`], buttonStyle, disableStyle]}>
                    {iconImage}
                    {loadingDom}
                    <Text
                        style={[
                            styles.caption,
                            styles.primaryCaption,
                            styles[`caption_${size}`],
                            {color: textColor},
                            textStyle
                        ]}>
                        {captionText}
                    </Text>
                </View>
            )
        } else if (type == 'common') {
            let border = styles.border
            if (disable) {
                border = {}
            }
            let primaryBorderedStyle = {}
            if (textColor === PRIMARY_COLOR) {
                primaryBorderedStyle.borderWidth = 1
            }
            content = (
                <View
                    style={[
                        styles.button,
                        border,
                        styles[`button_${size}`],
                        styles.button_border,
                        {borderColor: textColor},
                        primaryBorderedStyle,
                        buttonStyle,
                        disableStyle
                    ]}>
                    {iconImage}
                    {loadingDom}
                    <Text
                        style={[
                            styles.caption,
                            styles.secondaryCaption,
                            styles[`caption_${size}`],
                            styles.caption_border,
                            {color: textColor},
                            textStyle,
                            disableText
                        ]}>
                        {captionText}
                    </Text>
                </View>
            )
        } else {
            let border = type === 'bordered' && styles.border
            let primaryBorderedStyle = {}
            if (textColor === PRIMARY_COLOR) {
                primaryBorderedStyle.borderWidth = 1
            }
            content = (
                <View
                    style={[
                        styles.button,
                        border,
                        styles[`button_${size}`],
                        styles[`button_${type}`],
                        {borderColor: textColor === '#333' ? labelText : textColor},
                        primaryBorderedStyle,
                        buttonStyle,
                        disableStyle
                    ]}>
                    {iconImage}
                    {loadingDom}
                    <Text
                        style={[
                            styles.caption,
                            styles.secondaryCaption,
                            styles[`caption_${size}`],
                            styles[`caption_${type}`],
                            {color: textColor},
                            textStyle
                        ]}>
                        {captionText}
                    </Text>
                </View>
            )
        }
        if (disable && !onDisablePress) {
            return <View style={[styles[`container_${size}`], style]}>{content}</View>
        }

        return (
            <TouchableOpacity
                accessibilityTraits="button"
                onPress={this.onPress.bind(this)}
                activeOpacity={0.5}
                style={[styles[`container_${size}`], style]}>
                {content}
            </TouchableOpacity>
        )
    }
}
