/**
 * @author hoe（yanghanjia@baie.com.cn）
 * @date 2019-08-17 09:55
 * @Description: 托管
 */
import * as types from '../constants/ActionTypes'

const initialState = {
    sellerCount: 0,
    userCount: 0,
    unUsedCount: 0,
    redpot: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HOSTING_RECEIVE_REDPOT: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}
