/**
 @description: 图片
 @date: 2019/2/13
 @author: Adultcn(pangjianan@baie.com.cn)
 @example:<Image />
 */
import React, {Component} from 'react'
import {Image as RNImage} from 'react-native'
import {imgCompress} from '../utils/index'
import {flatten} from './StyleSheet'

export default class Image extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showDefault: true,
            error: false
        }
    }

    render() {
        const {showDefault, error} = this.state
        let {style, source, width, height, noCompress = false, showLoadingBg = true, loadingBg = '#D8D8D8'} = this.props
        // noCompress : 是否禁用自动的图片压缩

        const loadingBgStyle = {backgroundColor: loadingBg}
        let styleObj = flatten(style)
        if (!width) {
            width = styleObj.width
        }
        if (!height) {
            height = styleObj.height
        }

        let extraStyle = {}

        let ratio = width / height
        let defaultImage = require('./img/image_default/default_img_d_n.png')
        let defaultErrorImage = require('./img/image_default/image_e_n.png')
        if (ratio > 1.75) {
            defaultImage = require('./img/image_default/default_img_d_w.png')
            defaultErrorImage = require('./img/image_default/image_e_w.png')
        } else if (ratio < 0.75) {
            defaultImage = require('./img/image_default/default_img_d_h.png')
            defaultErrorImage = require('./img/image_default/image_e_h.png')
        }

        if (typeof source === 'number') {
            // 通过require方式的 不用转换
        } else {
            if (typeof source === 'string') {
                // 兼容直接传入 图片url 的写法
                source = {uri: source}
            }

            if (!source || !source.uri) {
                source = defaultImage
                extraStyle = {...loadingBgStyle}
            } else {
                source = {
                    uri: noCompress ? source.uri : imgCompress(source.uri, width, height)
                }
            }
        }

        if (showDefault) {
            source = defaultImage
            extraStyle = {...loadingBgStyle}
        }
        if (error) {
            source = defaultErrorImage
            extraStyle = {...loadingBgStyle}
        }
        if (!showLoadingBg) {
            delete extraStyle.backgroundColor
        }

        return (
            <RNImage
                {...this.props}
                resizeMode="cover"
                style={[extraStyle, style]}
                onLoadEnd={() => this.setState({showDefault: false})}
                onError={() => this.setState({error: true})}
                source={source}
            />
        )
    }
}
