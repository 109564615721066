/**
 @description: description
 @date: 2018/4/8
 @author: 雷利(Rayleight@baie.com.cn)
 */
import Cookies from 'js-cookie'
import {Platform} from 'react-native'
import * as qs from 'qs'
// eslint-disable-next-line
import packageInfo from '../../package.json'

import {setToken} from '../actions/App'
import {setIPayInfo} from '../actions/IPayInfo'
import {store} from '../index'
import {getValue} from '../project_config/index'
import {
    NEW_WALLET_INFO_QUERY,
    USER_INFO,
    MARKETER_USERINFO_GET, // 获取星探登记信息
    USER_WALLET_INFO,
    MARKETER_WSTEAM_GET,
    USERSERVICETAG_GET,
    I_GET_EPITHID,
    LOGOUT_URL,
    ORDER_WEB_ORDERS_PAYMENT_SIGN
} from '../constants/FetchUrls'
import {identify} from './ZGTools'
import {getMainDomain, isRightToken, R_alert} from './index'
import {captureException, setSentryTag, setSentryUser, traceResponseError} from './sentry'

window.global = window.global || window
const TIMEOUT = 60 * 1000
const USER_AGENT_IOS = `Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1 BaiE_APP V${packageInfo.version}`
const USER_AGENT_ANDROID = `Mozilla/5.0 (Linux; Android 6.0.1; SM-C7000 Build/MMB29M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/55.0.2883.91 Mobile Safari/537.36 Language/zh_CN BaiE_APP V${packageInfo.version}`

export const USER_AGENT = Platform.OS === 'ios' ? USER_AGENT_IOS : USER_AGENT_ANDROID
const EMOJI_REPLACE_TEXT = '您输入了系统无法识别的表情字符，请更换'

const ERROR_TIPS_DEFAULT = '网络异常，请稍后再试'

const FETCH_ERROR_MAP = {
    'Network request failed': '网络未连接，请检查网络设置'
}

/**
 * 基于 fetch 封装的 GET请求
 * @param url
 * @param params
 * @param noPre - 不判断error_code
 * @returns {Promise}
 */
export function R_GET(url, params = {}, noPre) {
    return _FETCH(url, params, 'GET', noPre)
}

/**
 * 基于 fetch 封装的 POST请求
 * @param url
 * @param data
 * @param noPre
 * @returns {Promise}
 */
export function R_POST(url, data = {}, noPre) {
    return _FETCH(url, data, 'POST', noPre)
}

/**
 * fetch 封装
 * @param url
 * @param param
 * @param method
 * @param noPre - enum("v3",true)  v3:会更新服务器时间，true：直接resolve(response)
 * @returns {*}
 * @private
 */
function _FETCH(url, param = {}, method = 'GET', noPre = false) {
    if (url == undefined) {
        return Promise.reject('请求地址为空')
    }

    const params = {...param} // 避免修改入参param
    // 统一加上渠道接口
    // let channelMarketingCode = getValue('渠道', 'text')
    // try {
    //     params.channelMarketingCode = channelMarketingCode
    // } catch (e) {
    //     params = {channelMarketingCode}
    // }

    // 统一加上统计信息
    // try {
    //     params.TRU = global.__userId || 0 //当前用户id
    //     params.TREF = global.__prevPageName || "init" //调用的页面之前页面
    //     params.TFROM = global.__nowPageName || "init" //调用的页面
    //
    // } catch (e) {
    // }

    return new Promise(function(resolve, reject) {
        setTimeout(() => {
            reject('网络请求超时，请检查网络设置')
        }, TIMEOUT)

        _fetch()

        if (global.token === 'logout') {
            reject('已退出登录')
        }

        function _fetch() {
            if (
                !params.token &&
                (global.token && global.token.length && global.token !== 'init' && global.token !== 0)
            ) {
                params.token = global.token
                params.xxl_sso_sessionid = global.token
            }
            let headerData = {}
            if (params.headers) {
                headerData = params.headers
                delete params.headers
            }
            let requestBody = qs.stringify(params)

            if (requestBody.match(encodeURIComponent(EMOJI_REPLACE_TEXT)) !== null) {
                // 判断是否有参数值为 纯emoji
                return reject(EMOJI_REPLACE_TEXT)
            }

            requestBody = qs.parse(requestBody)

            requestBody = qs.stringify(requestBody, { arrayFormat: 'repeat' })
            if (Object.keys(headerData).length) {
                requestBody = JSON.stringify(params)
            }
            let responseStatus = 200
            let fetch_url = url
            // authorize(
            //     function(credential) {
            const signatureHeader = 'X-Baie-Authorization'

            let fetch_param = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'User-Agent': USER_AGENT,
                    ...headerData
                },
                body: requestBody
            }

            if (noPre === 'authorize') {
                fetch_param = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'User-Agent': USER_AGENT
                        // [signatureHeader]: credential
                    },
                    body: JSON.stringify(params)
                }
            }

            if (method === 'GET') {
                if (fetch_url.search(/\?/) === -1) {
                    fetch_url += `?${requestBody}`
                } else {
                    fetch_url += `&${requestBody}`
                    requestBody = fetch_url.split('?')[1] + requestBody // fetch_url 本身有参数的话，也需要参与签名，不过这是不规范的用法
                }
                fetch_param = {
                    method: 'GET',
                    credentials: 'omit',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'text/plain',
                        'User-Agent': USER_AGENT
                        // [signatureHeader]: credential
                    }
                }
            }

            let serverTime = Date.now()
            fetch(fetch_url, fetch_param)
                .then(response => {
                    try {
                        serverTime = response.headers.map.date[0]
                        global.__serverTime = response.headers.map.date[0]
                    } catch (e) {}
                    responseStatus = response.status
                    return response.json()
                })
                .then(response => {
                    // traceResponseError(response)
                    // 收到 当前操作人数过多请稍后再试  的message 时， 延迟5秒 reject ，降低服务器 压力
                    if (response && response.message === '当前操作人数过多请稍后再试') {
                        setTimeout(() => {
                            if (noPre) {
                                resolve({
                                    error_code: 'global.failed',
                                    message: '活动太火爆了，请重试'
                                })
                            } else {
                                reject('活动太火爆了，请重试')
                            }
                        }, rd(1, 5) * 1000)
                        return
                    }

                    if (noPre) {
                        if (noPre == 'v2') {
                            // 新版接口，外面不套一层数据了，错误也是返回错误码

                            if (response && response.error_code == 1) {
                                resolve(response)
                            } else if (response && response.error_code == 'errors.nologin') {
                                store.dispatch(setToken(0))
                                reject(response.message)
                            } else {
                                reject(response)
                            }
                        } else {
                            if (response && response.retCode == 'NPE000002') {
                                // userToken失效

                                getIPayInfo(global.token).then(iPayInfo => {
                                    store.dispatch(setIPayInfo(iPayInfo))
                                })
                                let _result = {
                                    data: [],
                                    ...response
                                }
                                resolve(_result)
                            } else {
                                let _result = {
                                    data: [],
                                    ...response
                                }
                                resolve(_result)
                            }
                            // 不需要预处理，调用这个方法的地方自己判断
                            resolve(response)
                        }
                        return
                    }
                    if (response && response.error_code == 1) {
                        let _result = response
                        if (!response.data) {
                            // 兼容mock 服务器
                            _result = {
                                data: response,
                                ...response,
                                serverTimeOffset: serverTime - Date.now()
                            }
                        }
                        resolve(_result)
                    } else if (response.code == 501) {
                        store.dispatch(setToken(0))
                        reject(response.message)
                    } else if (response && response.error_code == 'errors.nologin') {
                        store.dispatch(setToken(0))
                        reject(response.message)
                    } else if (response && response.message) {
                        reject(response.message)
                    } else {
                        reject(ERROR_TIPS_DEFAULT)
                    }
                })
                .catch(err => {
                    captureException(err)
                    if (noPre === 'v3') {
                        try {
                            global.__serverTime = 0
                        } catch (e) {}
                    }
                    const errMsg = _parseErrorMsg(err, responseStatus)
                    reject(errMsg)
                })
            // },
            // params,
            // {arrayFormat: 'repeat'}
            // )
        }
    })
}

/**
 * fetch for cms
 * @param url
 * @param params
 * @param noPre - enum("v3",true)  v3:会更新服务器时间，true：直接resolve(response)
 * @returns {*}
 * @private
 */
export function R_GET_CMS(url, params = {}) {
    if (url == undefined) {
        return Promise.reject('请求地址为空')
    }
    return new Promise(function(resolve, reject) {
        setTimeout(() => {
            reject('网络请求超时，请检查网络设置')
        }, TIMEOUT)

        _fetch()

        function _fetch() {
            const requestBody = qs.stringify(params)

            let fetch_url = url
            const fetch_param = {
                method: 'GET',
                credentials: 'omit',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'text/plain'
                }
            }

            if (fetch_url.search(/\?/) === -1) {
                fetch_url += `?${requestBody}`
            } else {
                fetch_url += `&${requestBody}`
            }

            fetch(fetch_url, fetch_param)
                .then(response => {
                    try {
                        global.__serverTime = response.headers.map.date[0]
                    } catch (e) {}
                    return response.json()
                })
                .then(response => {
                    traceResponseError(response)
                    if (response && (response.error_code == 1 || !response.error_code)) {
                        // cms接口有可能没有error_code，所以error_code不存在或者为1的时候resolve(response)
                        resolve(response)
                    } else {
                        reject(ERROR_TIPS_DEFAULT)
                    }
                })
                .catch(err => {
                    captureException(err)
                    reject(ERROR_TIPS_DEFAULT)
                })
        }
    })
}

/**
 * 处理错误信息
 * @param err
 * @returns {*}
 * @private
 */
function _parseErrorMsg(err, status = 200) {
    let errText = err
    if (errText && errText.message) {
        errText = errText.message
    }
    if (FETCH_ERROR_MAP[errText]) {
        errText = FETCH_ERROR_MAP[errText]
    }

    if (errText && errText.match) {
        if (errText.match('Uncaught SyntaxError') != null) {
            errText = ERROR_TIPS_DEFAULT
        }
        if (errText.match('Unexpected token') != null) {
            errText = ERROR_TIPS_DEFAULT
        }
        if (errText.match('JSON Parse error') != null) {
            errText = ERROR_TIPS_DEFAULT
        }
        if (errText.match('timeout') != null) {
            errText = ERROR_TIPS_DEFAULT
        }
        if (errText.match('Failed to fetch') != null) {
            errText = ERROR_TIPS_DEFAULT
        }
    }

    if (typeof errText === 'object') {
        errText = JSON.stringify(errText)
    }
    return errText
}

function removeEmoji(str, params) {
    if (params && params.__emoji) {
        return str
    }
    if (str && str.replace) {
        let newStr = str.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/gm, '') // 替换 emoji 表情
        if (newStr == '') {
            newStr = EMOJI_REPLACE_TEXT
        }
        return newStr
    }
    return str
}

export function getUserInfo(token = false) {
    return new Promise(function(resolve, reject) {
        if (!isRightToken(global.token) && !isRightToken(token)) {
            reject('请先登录')
            return
        }
        const tokenParam = {}
        if (token) {
            tokenParam.token = token
        }
        Promise.all([
            R_GET(USER_INFO, {xxl_sso_sessionid: token})
            // getNewWalletInfo(tokenParam),
            // R_GET(I_GET_EPITHID, tokenParam) // iEnglish用户等级
        ])
            .then(response => {
                let {
                    userId = 'test',
                    username,
                    imgUrl,
                    rankNum = 1,
                    status,
                    wechat,
                    phone,
                    realName,
                    sellerId,
                    bankCode,
                    disabled,
                    numberCode,
                    numberDesc,
                    userDesc,
                    mobile,
                    openId,
                    expireTime,
                    capacityId,
                    trainFeeExpireTime
                } = response[0].data

                if (disabled === true) {
                    global.token = 'logout'
                    reject('账号已被冻结，请联系客服')
                    return
                }
                if (status === '审核中') {
                    rankNum = ''
                }
                if (status === '审核成功' && rankNum == 0) {
                    // 主动申请成功， 等级有延迟？
                    console.log(response)
                    rankNum = 1
                }

                // const walletNew = response[1].data
                // const {userEpithetId = 0} = response[2].data
                // if(!userEpithetId || userEpithetId < 1){
                //     // 非经销商不能使用app
                //     logout(token)
                //     global.token = 0
                //     reject("不是经销商")
                //     return
                // }
                const result = {
                    userId,
                    userName: username,
                    avatar: imgUrl,
                    grade: rankNum, // 经销商等级
                    wechat,
                    mobile,
                    realName,
                    status,
                    sellerId,
                    bankCode,
                    numberCode,
                    numberDesc,
                    userDesc,
                    openId,
                    expireTime,
                    capacityId,
                    trainFeeExpireTime
                    // walletChannel: walletNew.channel,
                    // walletOpenStatus: walletNew.status,
                    // openStatus: walletNew.openStatus, // 港澳台开户状态
                    // ifIdCardType: walletNew.ifIdCardType,
                    // idCardType: walletNew.idCardType,
                    // //userEpithetId: parseInt(userEpithetId),
                }
                for (const key in result) {
                    if (result[key] === null) {
                        result[key] = ''
                    }
                }

                // console.log('result')
                // console.log(result)

                // // 给老web用
                // syncCookie('grade', rankNum)
                // syncCookie('status', status)
                // syncCookie('avatar', imgUrl)
                // syncCookie('realName', realName)
                // syncCookie('shopSellerId', sellerId)
                // syncCookie('userSignId', userId)
                // syncCookie('userName', username)
                // syncCookie('isTFS', walletNew.channel === 'TFS' ? 'YES' : 'NO')
                //
                // // 诸葛身份识别
                // identify(`${userId}`, {
                //     userName: `${username}`,
                //     avatar: `${imgUrl}`,
                //     grade: `${userEpithetId}`, // iEnglish用户等级
                //     wechat: `${wechat}`,
                //     mobile: `${phone}`,
                //     realName: `${realName}`,
                //     status: `${status}`,
                //     sellerId: `${sellerId}`
                // })
                //
                // setSentryUser({id: userId, username})
                // setSentryTag('user_grade', userEpithetId)
                //
                resolve(result)
            })
            .catch(reject)
    })

    function logout(token) {
        R_POST(LOGOUT_URL, {token}).catch(() => {})
    }

    function getWalletInfo(tokenParam) {
        // 这个工具函数的作用是判断易宝支付的开户情况
        return new Promise(function(resolve, reject) {
            R_GET(USER_WALLET_INFO, tokenParam)
                .then(response => {
                    resolve(response)
                })
                .catch(e => {
                    resolve({data: {status: '获取信息失败'}})
                })
        })
    }

    /**
     * 分账通信息
     * @param tokenParam
     * @returns {Promise}
     */
    function getNewWalletInfo(tokenParam) {
        return new Promise(function(resolve, reject) {
            R_GET(NEW_WALLET_INFO_QUERY, tokenParam)
                .then(response => {
                    resolve(response)
                })
                .catch(e => {
                    resolve({data: {status: '获取信息失败', channel: 'TFS', ifIdCardType: true}})
                })
        })
    }

    /**
     * 是否微商团队
     * @param tokenParam
     * @returns {Promise}
     */
    function getWSInfo(tokenParam) {
        return new Promise(function(resolve, reject) {
            R_GET(MARKETER_WSTEAM_GET, tokenParam)
                .then(response => {
                    // resolve(true)
                    resolve(response.ifWsTeam)
                })
                .catch(e => {
                    resolve(false)
                })
        })
    }

    /**
     * 是否星探信息
     * @param tokenParam
     * @returns {Promise}
     */
    function getStarInfo(tokenParam) {
        return new Promise(function(resolve, reject) {
            R_GET(MARKETER_USERINFO_GET, tokenParam)
                .then(response => {
                    // resolve(true)
                    resolve(response.data)
                })
                .catch(e => {
                    resolve({})
                })
        })
    }
}

export function getIPayInfo(token = false) {
    return null
    return new Promise(function(resolve, reject) {
        if (!isRightToken(global.token) && !isRightToken(token)) {
            reject('请先登录')
            return
        }
        const tokenParam = {}
        if (token) {
            tokenParam.token = token
        }
        console.log(tokenParam)
        Promise.all([R_GET(ORDER_WEB_ORDERS_PAYMENT_SIGN, tokenParam, 'authorize')])
            .then(response => {
                let {userToken} = response[0]

                const result = {
                    userToken
                }
                for (const key in result) {
                    if (result[key] === null) {
                        result[key] = ''
                    }
                }
                resolve(result)
            })
            .catch(reject)
    })
}

function syncCookie(key, value) {
    Cookies.set(key, value, {expires: 365, domain: getMainDomain(window.location.hostname)})
}

/**
 * url跳转
 * noreferrer--防止风控
 */
export function noRefOpen(href) {
    window.location.replace(href)
    // var temp=document.createElement("a")
    // temp.setAttribute("rel","noreferrer")
    // temp.setAttribute("href",href)
    // temp.click()
}

/**
 * JS获取n至m随机整数
 */
function rd(n, m) {
    const c = m - n + 1
    return Math.floor(Math.random() * c + n)
}

/**
 * 替换页面参数
 * @param newState
 */
export function replacePageUrlParam(newState) {
    const keys = Object.keys(newState)
    const params = keys.map(key => `${key}=${newState[key]}`)
    history.replaceState(newState, '', keys.length > 0 ? `?${params.join('&')}` : '')
}
