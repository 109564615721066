/**
 @description: 数据持续存储
 @date: 2016-9-23
 @author: Rayleigh(Rayleigh@Nero-zou.com)
 */
import Cookies from 'js-cookie'
import {encode, decode} from './Crypto'

import {LOGIN_INFO} from '../constants/StorgeKeys'

/**
 * 保存数据
 * @param key
 * @param string
 * @param isEncode - 是否需要加密
 * @returns {Promise}
 */
export function saveItem(key, string, isEncode = false) {
    return new Promise(function(resolve, reject) {
        if (typeof string === 'object') {
            string = JSON.stringify(string)
        }
        if (isEncode) {
            string = encode(string)
        }
        try {
            localStorage.setItem(key, string)
            resolve()
        } catch (e) {
            console.log(e)
            reject(e)
        }
    })
}

/**
 * 读取数据
 * @param key
 * @param isDecode - 是否需要解密
 * @returns {Promise}
 */
export function getItem(key, isDecode) {
    return new Promise(function(resolve, reject) {
        try {
            let data = localStorage.getItem(key)
            if (isDecode && data !== null) {
                data = decode(data)
            }
            resolve(data)
        } catch (e) {
            reject(e)
        }
    })
}

/**
 * 删除数据
 * @param key
 * @returns {Promise}
 */
export function removeItem(key) {
    if (key === LOGIN_INFO) {
        Cookies.remove('TokenSign')
        Cookies.remove('code')
    }
    return new Promise(function(resolve, reject) {
        if (key instanceof Array) {
            key.forEach(i => {
                localStorage.removeItem(i)
            })
        } else {
            localStorage.removeItem(key)
        }
        resolve()
    })
}

/**
 * 清空数据
 * @returns {Promise}
 */
export function clearAllItem() {
    return new Promise(function(resolve, reject) {
        localStorage.clear()
        resolve()
    })
}
