/**
 @description: route 默认值
 @date: 2016/9/23
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import React, {Component} from 'react'

import {View} from 'react-native'
import {connect} from 'react-redux'
/* import {NavigationActions} from 'react-navigation' */

import {bindActionCreators} from 'redux'
import * as StyleSheet from '../../VUI/StyleSheet'
import {getItem} from '../../utils/Storage'
import {USER_INFO, APP_DATA} from '../../constants/StorgeKeys'
import * as AppActions from '../../actions/App'
import {setUserInfo} from '../../actions/UserInfo'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    }
})

class AppDefaultView extends Component {
    constructor(props) {
        super(props)
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
        this.goToLogin = this.goToLogin.bind(this)
        this.goToMainTabs = this.goToMainTabs.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token == 0 && this.props.token != 0) {
            this.goToLogin()
        }
    }

    componentDidMount() {
        const {actions} = this.props
        getItem(USER_INFO)
            .then(oldDataString => {
                let userInfo = JSON.parse(oldDataString)
                if (userInfo && userInfo.userId !== undefined) {
                    global.__userId = userInfo.userId
                    actions.setUserInfo(userInfo)
                }
                getAppData()
            })
            .catch(() => {
                getAppData()
            })

        const getAppData = () => {
            getItem(APP_DATA)
                .then(oldDataString => {
                    let oldData = JSON.parse(oldDataString)
                    if (oldData && oldData.token != undefined) {
                        if (oldData && oldData.shareInfo) {
                            oldData.shareInfo = {}
                        }
                        actions.setAppData(oldData)
                        if (oldData.token === 0 || oldData.token === 'logout' || oldData.token === 'init') {
                            this.goToLogin()
                        } else {
                            global.token = oldData.token
                            this.goToMainTabs()
                        }
                    } else {
                        actions.setAppData({token: 0})
                        // this.goToLogin()
                    }
                })
                .catch(err => {
                    this.goToLogin()
                })
        }
    }

    goToLogin() {
        navigatorReplace(this.props.navigation, 'login', {})
    }

    goToMainTabs() {
        navigatorReplace(this.props.navigation, 'mainTabs', {})
    }

    render() {
        return <View style={styles.container} />
    }
}

function mapStateToProps(state) {
    return {
        token: state.appData.token,
        userInfo: state.userInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...AppActions, setUserInfo}, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDefaultView)

export function navigatorReplace(navigation, routeName, params = {}) {
    navigation.replace({[routeName]: params})
}
