/**
 @description: 跨系统跳转处理工具函数
 @date: 2019-07-03
 @author: 雷利(Rayleight@baie.com.cn)
 @description:  为什么要用wormhole.js？
 为了解决跨系统跳转过程中，链路长，逻辑不清晰的问题；
 整体思路：在开始时把returnUrl存起来，在结束时把returnUrl取出来拼上额外的参数去跳转；
 顺带解决的问题：跳转至第三方系统returnUrl参数过长的问题；
 */
/* eslint-disable */

import {saveItem, getItem, removeItem} from './Storage'
import {captureMessage, captureException} from './sentry'

const STORAGE_KEY_FOR_SELF = 'WORMHOLE_DATA__SELF'
/*
    ======== STORAGE_KEY_FOR_SELF 说明 ========
    给自己系统回跳用，比如要跳到 易宝收银台，支付成功后再跳到支付成功页
    createLink会把 returnRouteParam 存到 Storage里，然后把 /hub 最为returnUrl传给易宝；
    当从易宝收银台回到/hub页面时， 会调用 goToNext 来跳到记录的returnRouteParam
*/

const STORAGE_KEY_FOR_OTHER = 'WORMHOLE_DATA__OTHER'

/*
    ======== STORAGE_KEY_FOR_OTHER 说明 ========
    用于记录从别的系统跳过来时，地址上的returnUrl
    saveLandingPageReturnUrl方法会把returnUrl存到Storage中
    本系统内执行了n个方法或者跳了几个n个页面，到了需要跳回 returnUrl时；
    调用 goToLandingPageReturnUrl 方法，会把returnUrl取出来，跳过去
 */

/**
 * 创建跳转地址，同时把回调地址保存到localstorage中
 * @param targetUrl
 * @param returnRouteName
 * @param returnRouteParam
 * @returns {Promise<any>}
 */
export function createLink(targetUrl, returnRouteName = 'index', returnRouteParam = {}) {
    return new Promise(function (resolve, reject) {
        saveItem(STORAGE_KEY_FOR_SELF, {
            routeName: returnRouteName,
            param: returnRouteParam
        })
        .then(() => {
            let jumpUrl = targetUrl
            if (jumpUrl.indexOf('?') === -1) {
                jumpUrl += '?'
            } else {
                jumpUrl += '&'
            }
            if (jumpUrl.indexOf('returnUrl=') !== -1) {
                // targetUrl 不能含有returnUrl参数
                captureMessage(`wormHole => createLink ： targetUrl参数不能带returnUrl。targetUrl:${targetUrl}`)
            }

            if (typeof location !== 'undefined') {
                //web才需要 returnUrl 跳回来
                jumpUrl += `returnUrl=${location.origin}/hub`
            }
            resolve(jumpUrl)
        })
        .catch(err => {
            console.log(err)
            captureException(err)
            reject('本地存储空间不足，无法创建链接')
        })
    })
}

/**
 * /hub 页面调用这个方法来决定跳到哪
 * @param navigator
 * @param params
 */
export function goToNext(navigator, params) {
    getItem(STORAGE_KEY_FOR_SELF)
    .then(data => {
        // 数据拿到后把旧的删除了
        removeItem(STORAGE_KEY_FOR_SELF).catch(() => {
        })

        const routeConfig = JSON.parse(data)

        if (params.cash_result === 'success') {
            if (!routeConfig.routeName) {
                throw new Error('routeConfig error')
            }
            navigator.replace({
                [routeConfig.routeName]: {
                    ...routeConfig.param,
                    ...params
                }
            })
        } else {
            navigator.replace({
                payFailed: {
                    cash_result: params.cash_result
                }
            })
        }
    })
    .catch(() => {
        // 默认关闭页面
        navigator.pop()
    })
}

/**
 * 保存从来源系统传过来的returnUrl
 * @returns {Promise<any>}
 * @description 可以在具体的页面触发，也可以全局处理
 */
export function saveLandingPageReturnUrl() {
    return new Promise(function (resolve, reject) {
        const params = getParamFromSearch(location.search)
        if (!params.returnUrl) {
            // 没有returnUrl 不需要处理
            return
        }
        saveItem(STORAGE_KEY_FOR_OTHER, params.returnUrl).then(() => {
            resolve()
        })
    })
}

/**
 * 跳回来源系统传过来的returnUrl
 * @param extraParam - 给returnUrl加上额外的参数
 * @returns {Promise<any>}
 * @description 在流程结束时触发
 */
export function goToLandingPageReturnUrl(extraParam = {}) {
    return new Promise(function (resolve, reject) {
        getItem(STORAGE_KEY_FOR_OTHER).then(returnUrl => {
            if (!returnUrl) {
                reject('下一步地址为空')
            } else {
                if (returnUrl.indexOf('http') === 0) {
                    window.location.replace(addParamToUrl(returnUrl, extraParam))
                    resolve()
                } else {
                    reject('下一步地址为错误')
                }
            }
        })
    })
}

/**
 * 拼接url和参数
 * @param url
 * @param param
 * @returns {string}
 */
export function addParamToUrl(url, param = {}) {
    if (!url) {
        return ''
    }
    const urlInfo = url.split('?')
    let jumpUrl = urlInfo[0]
    let paramList = []
    let urlParam = getParamFromSearch('?' + (urlInfo[1] ? urlInfo[1] : ''))
    let allParam = {
        ...urlParam,
        ...param
    }

    for (const paramKey in allParam) {
        if (allParam.hasOwnProperty(paramKey)) {
            paramList.push(`${paramKey}=${encodeURIComponent(allParam[paramKey])}`)
        }
    }
    if (paramList.length > 0) {
        jumpUrl += '?'
    }
    return jumpUrl + paramList.join('&')
}

function getParamFromSearch(search) {
    let result={}
    if (!search) {
        return result
    }
    let params = search.substr(1)
    if (params !== '') {
        let temp = decodeURI(params).split('&')
        for (let i = 0; i < temp.length; i++) {
            let obj = temp[i]
            let key = obj.split('=')
            let value = key[1]
            try {
                value = decodeURIComponent(value)
            } catch (e) {
            }
            result[key[0]] = value
        }
    }
    return result
}

/* eslint-enable */
