export default [
    // 旗舰店
    'daPeiGoodsList', // 旗舰店列表
    'daPeiGoodsDetail', // 旗舰店商品详情
    // 联系我们
    'contactUsWord', // 所有tab（联系我们/系统反馈/违规举报/投诉受理/点赞通道）
    // 报表中心
    // '', // 规则说明
    'fulfilNewCenter', // 报表中心
    'fulfilNewList1', // 各指数列表
    'fulfilNewList2', // 指数详情
    'fulfilTimeSlot', // 自定义查询
    // 上市股权权益
    'equityHelp', // 帮助说明
    'listedEquityIndex', // 上市股权权益
    'listedEquityIntent', // 上市股权协议
    'equity', // 股权基金
    // '', // 股权意向书
    // '', // 股权确认书
    // 订单中心
    'b2bOrderList', // 我的订货列表
    'b2bGroupOrderDetail', // 组合订单详情
    'b2cOrdersForBuyer', // 我的订单
    'b2bOrderDetail', // 我的订单详情
    'b2bPartnerOrderList', // 分销订单
    'b2bPartnerOrderClearingFinishDetail', // 分销订单详情
    'b2cOrders', // 发货订单
    'b2bSendDetail', // 发货订单详情
    'b2bSendList', // 商城订单
    'b2cOrdersForBuyer', // 旗舰店订单
    'b2cOrderDetail', // 商城订单详情 旗舰店订单详情
    'replaceGoodsRecordList', // 换货订单
    'replaceGoodsRecordDetail', // 换货订单详情
    // 钱包
    'newWalletIndex', // 我的钱包
    'billList', // 账单
    'payBackList', // 扣款通知
    'newBankCard', // 银行卡
    'bankCardAdd', // 添加银行卡
    'myConsume', // 可提现余额+预付货款余额
    'walletLastYear', // 2018收益
    // 消息
    'messageList', // 消息列表（全部tab）
    // 云商城
    // 'mall', // 首页
    'mallBeautyList', // 爆款美妆
    'mallCollectionList', // 超值套组
    'mallGoodsList', // 全部商品
    'daPeiGoodsDetail', // 套组详情
    'b2cGoodsDetail', // 单品详情
    // 换货
    'replaceGoodsIntro', // 换货详情
    // 邀请
    'invite', // 邀请分销客户
    'inviteEspecialGoods', // 特邀礼包
    'inviteGoods', // 邀请礼包
    'partnerList', // 分销客户
    'partnerScoreDetails', // 月度积分
    'partnerScoreDetails', // 年度积分
    // 服务费
    // 'reward_rules.html', // 规则说明
    'rewardCalc', // 动态预估
    'retailRewardIndex', // 服务费发放
    'retailRewardDetail', // 动销结算任务
    'retailProvideList', // 发放记录
    'retailTaskClearingInfo', // 动销明细
    'retailTaskList', // 动销任务（列表）
    'retailTaskDetail', // 动销任务（详情）
    'rebate', // 历史市场服务费
    'rebateMonthList', // 月度教育奖列表
    'rebateMonthDetail', // 月度教育奖详情
    'rebateMonthServiceList', // 月度服务费列表
    'rebateMonthServiceDetail', // 月度服务费列详情
    'rebateYearList', // 年度教育列表
    'rebateMonthDetail', // 年度教育详情
    'appSetting', //
    'myInfo',
    /* 自购成功页 start */
    'sendStep3',
    'selfBuyingOrderSuccess',
    'b2bOrderSuccess'
    /* 自购成功页 finish */
]
