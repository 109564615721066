/**
 @description: 需要跳转到老项目(+其他项目)的路径
 @date: 2018/5/7
 @author: 雷利(Rayleight@baie.com.cn)
 */
import {B2B_BASE_URL, INFO_BASE_URL, TICKET_BASE_URL, LIVE_BASE_URL} from '../constants/FetchUrls'

/**
 * 如果有碰到需要跳到旧项目的路由，打开旧项目地址
 * @param routeName
 * @param params
 * @returns {*}
 */
export function needOpenOld(routeName, params) {
    const oldRouteList = {
        // legalize/qualification根据状态跳转到对应状态页面
        withdrawOpenStep1: `${B2B_BASE_URL}legalize/qualification`,
        withdrawOpenStep2: `${B2B_BASE_URL}legalize/qualification_success`,
        withdrawOpenStep3: `${B2B_BASE_URL}legalize/qualification`,
        stockMove: `${B2B_BASE_URL}inventory/transfer_detail?numberCode=${params.numberCode}`,
        zxDetail: `${INFO_BASE_URL}article/detail?articleId=${params.id}&shopsellerid=${params.shopsellerid}&realm=${params.realm}&channelId=${params.channelId}&parentId=${params.parentId}`,
        honorDetail: `${INFO_BASE_URL}article/detail?articleId=${params.id}&shopsellerid=${params.shopsellerid}&realm=${params.realm}&channelId=${params.channelId}&parentId=${params.parentId}`,
        liveHonorDetail: `${LIVE_BASE_URL}honor/detail?articleId=${params.id}&channelId=${params.channelId}&parentId=${params.parentId}`,
        liveHonorList: `${LIVE_BASE_URL}honor/list`,
        AppZixunDetailLive: `${LIVE_BASE_URL}article/detail?articleId=${params.id}`,
        xtDetail: `${INFO_BASE_URL}article/detail?articleId=${params.id}&shopsellerid=${params.shopsellerid}&realm=${params.realm}&channelId=${params.channelId}&parentId=${params.parentId}`,
        zixun: `${INFO_BASE_URL}article/list?channelId=${params.parentId}&channelName=${params.title}`,
        ticketSysIndex: `${TICKET_BASE_URL}index`,
        collegeTicketMine: `${TICKET_BASE_URL}collegeTicketMine`,
        collegePack: `${TICKET_BASE_URL}collegePack?id=${params.id}`,
        collegeTicketMineTicketDetail: `${TICKET_BASE_URL}collegeTicketMineTicketDetail?id=${params.ticketId}`,
        collegeCourseDetail: `${TICKET_BASE_URL}collegeCourseDetail?courseId=${params.courseId}`,
        cash: `${params.url}`
    }
    if (oldRouteList[routeName]) {
        return oldRouteList[routeName]
    }

    return false
}
