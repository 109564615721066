/**
 @description: 支付token模块的actions
 @date: 2019/12/18
 @author: Jimbei(Jimbei@baie.com.cn)
 */
import * as types from '../constants/ActionTypes'

/**
 * 设置个人中心数据
 * @param user_info
 * @returns {{type, newData: *}}
 */
export function setIPayInfo(iPayInfo) {
    return {
        type: types.SET_IPAY_INFO,
        iPayInfo
    }
}

/**
 * 更新新支付token
 * @param newData
 * @returns {{type, newData: *}}
 */
export function updateIPayInfo(newData) {
    return {
        type: types.UPDATE_IPAY_INFO,
        newData
    }
}
//
// /**
//  * 退出登录
//  * @returns {{type, newData: *}}
//  */
// export function logout() {
//     return {
//         type: types.LOGOUT
//     }
// }

