/**
 @description: 未命名
 @date: 2018/3/9
 @author: Buggy(chenyuanhui@baie.com.cn)
 */
import React, {Component} from 'react'
import {View, Image} from 'react-native'
import * as StyleSheet from './StyleSheet'
import Text from './Text'
import TouchableOpacity from './TouchableOpacity'
import {isInApp, isInWechat, windowWidth} from './VStyle'
import * as COLORS from './Colors'
import Toast from './Toast'

let status_bar_height = 0
export const STATUS_BAR_HEIGHT = status_bar_height
export const HEADER_HEIGHT = isInApp || isInWechat ? 0 : 50 // app 微信内没有标题

const styles = StyleSheet.create({
    header: {
        backgroundColor: 'transparent',
        height: HEADER_HEIGHT,
        width: Math.min(windowWidth, 750),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: STATUS_BAR_HEIGHT,
        position: 'relative'
    },
    titleText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold'
    },
    leftItem: {
        flex: 1,
        alignItems: 'flex-start'
    },
    centerItem: {
        flex: 5,
        alignItems: 'center'
    },
    rightItem: {
        position: 'absolute',
        right: 0,
        // bottom: 0,
        padding: 0,
        top: 0,
        height: 50,
        justifyContent: 'center',
        paddingHorizontal: 15
    },
    itemWrapper: {
        padding: 11
    },
    itemText: {
        letterSpacing: 1,
        fontSize: 16,
        color: 'white'
    }
})

class AppHeader extends Component {
    constructor(props) {
        super(props)
        this.showExtra = this.showExtra.bind(this)
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const {title} = nextProps
        if (title !== this.props.title) {
            updatePageTitle(title)
        }
    }

    componentDidMount() {
        const {title} = this.props
        updatePageTitle(title)
    }

    showExtra() {
        const {extraItems} = this.props
        if (!extraItems || !extraItems.map) {
            return
        }
        let buttons = extraItems.map(item => item.title)
        buttons.push('取消')
        // TODO 需要浮动对话框
    }

    render() {
        if (isInWechat || isInApp) {
            return null
        }
        const {leftItem, title, rightItem, rightItemStyle = {}, foreground, extraItems = false, rightColor} = this.props
        let titleColor = '#161616'
        let itemsColor = COLORS.PRIMARY_COLOR
        if (rightColor) {
            itemsColor = rightColor
        }
        if (foreground === 'dark') {
            itemsColor = titleColor = 'white'
        }
        const content =
            React.Children.count(this.props.children) === 0 ? (
                <Text numberOfLines={1} style={[styles.titleText, {color: titleColor}]}>
                    {title}
                </Text>
            ) : (
                this.props.children
            )
        let extraItemDom
        if (extraItems) {
            extraItemDom = (
                <TouchableOpacity style={[styles.rightItem, rightItemStyle]} onPress={this.showExtra}>
                    <Image style={{width: 20, height: 20, margin: 5}} source={require('./img/header_more.png')} />
                </TouchableOpacity>
            )
        }
        return (
            <View className="app-header" style={[styles.header, this.props.style]}>
                <View style={styles.leftItem}>
                    <ItemWrapper color={itemsColor} item={leftItem} />
                </View>
                <View
                    accessible={true}
                    accessibilityLabel={title}
                    accessibilityTraits="header"
                    style={styles.centerItem}>
                    {content}
                </View>
                <View style={{flex: 1}} />
                {extraItemDom}
            </View>
        )
    }
}

class ItemWrapper extends Component {
    render() {
        const {item, color, style, isRight = false} = this.props
        if (!item) {
            return null
        }

        let content
        const {title, icon, layout, onPress} = item

        if (layout !== 'icon' && title) {
            content = (
                <Text numberOfLines={1} style={[styles.itemText, {color}]}>
                    {title.toUpperCase()}
                </Text>
            )
        } else if (icon) {
            if (isRight) {
                content = <Image source={icon} style={{height: 20, width: 20}} />
            } else {
                content = <Image source={icon} style={{height: 19, width: 12}} />
            }
        }

        return (
            <TouchableOpacity
                accessibilityLabel={title}
                accessibilityTraits="button"
                onPress={onPress}
                style={[styles.itemWrapper, style]}>
                {content}
            </TouchableOpacity>
        )
    }
}

let Header = AppHeader
export default Header

function updatePageTitle(title) {
    if (!title) {
        title = 'iEnglish服务商管理平台'
    }

    document.title = title

    // 第一次进入 触发太快会有问题 先关闭
    // if(isInApp){
    //     try {
    //         window.postMessage(JSON.stringify({
    //             type:"updatePageTitle",
    //             title,
    //         }))
    //     }catch (e){
    //         Toast.show(e)
    //     }
    // }
}
