/**
 @description: 本地持续存储用到的key
 @date: 2018/3/26
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */

export const LOGIN_INFO = 'LOGIN_INFO'
export const USER_INFO = 'USER_INFO'
export const APP_DATA = 'APP_DATA'
export const MALL_SHOP_INFO = 'MALL_SHOP_INFO'
export const ORDER_INFO_YIBAO_PAY = 'ORDER_INFO_YIBAO_PAY'
export const CODE_PUSH_SYNC_TIME = 'CODE_PUSH_SYNC_TIME'
export const SHOW_LIVE_INTRO = 'SHOW_LIVE_INTRO'
export const OPEN_ID = 'OPEN_ID'
export const LATEST_IGNORE_VERSION = 'LATEST_IGNORE_VERSION_V2' // 最近一次关闭的更新提示弹窗

export const LATEST_IGNORE_IIACH = 'LATEST_IGNORE_IIACH' // 最近一次关闭个体工商认证
export const HAS_COMPLETE_STORE = 'HAS_COMPLETE_STORE' // 是否已经完成线下店铺的申请

export const IF_SEE_EXCHANGE_TIP = 'IF_SEE_EXCHANGE_TIP' // 是否看过换货提示

export const LIVE_GIVE_THUMB_INFO = 'LIVE_GIVE_THUMB_INFO' // 口碑点赞情况
export const LIVE_GIVE_TOPIC_THUMB_INFO = 'LIVE_GIVE_TOPIC_THUMB_INFO' // 口碑专题点赞情况

export const LIVE_DIARY_GUIDE = 'LIVE_DIARY_GUIDE'
export const LIVE_DIARY_SHOW_TAG_SET = 'LIVE_DIARY_SHOW_TAG_SET'

export const CHANNEL_CLICK_STATE = 'CHANNEL_CLICK_STATE' // 各栏目最后点击时间
export const CIRCLE_CLICK_STATE = 'CIRCLE_CLICK_STATE' // 各圈子最后点击时间
export const CIRCLE_DYNAMIC_CLICK_STATE = 'CIRCLE_DYNAMIC_CLICK_STATE' // 圈子消息动态点击时间
export const CIRCLE_MESSAGE_CLICK_STATE = 'CIRCLE_MESSAGE_CLICK_STATE' // 圈子消息通知点击时间
export const CIRCLE_PY_AGREEMENT = 'CIRCLE_PY_AGREEMENT' // 朋友素材圈签约作者协议

export const LIVE_DIARY_GUIDE_UPLOAD = 'LIVE_DIARY_GUIDE_UPLOAD'
export const LIVE_INDEX_SHOW = 'LIVE_INDEX_SHOW'

export const LIVE_INDEX_RANK = 'LIVE_INDEX_RANK'
export const LIVE_INDEX_RANK_TIME = 'LIVE_INDEX_RANK_TIME'

export const LIVE_PERSONAL_MEDAL = 'LIVE_PERSONAL_MEDAL'
export const LIVE_PERSONAL_FEEDBACK = 'LIVE_PERSONAL_FEEDBACK'
export const LIVE_PERSONAL_FANS = 'LIVE_PERSONAL_FANS'

export const LIVE_DISCLAIMER_ARGEE = 'LIVE_DISCLAIMER_ARGEE'

export const TIPS_EXCHANGE_DETIAL = 'TIPS_EXCHANGE_DETIAL'

// 新支付模块token
export const IPAY_INFO = 'IPAY_INFO'
