/**
 @description: App的一些配置项
 @date: 2016-7-27
 @author: Rayleigh(Rayleigh@Nero-zou.com)
 */
import {getProjectText} from '../utils/getProjetcBind'
import {CAPACITYS_GET} from './FetchUrls'
import {isRightToken, R_GET} from '../utils/index'
import {getItem, saveItem} from '../utils/Storage'
import {GLOBAL_USER_MAP} from '../project_config/global_config'

/**
   risePrice (number): 晋级金额 ,
   totalPrice (number): 累计金额
 */
export let GRADE_MAP = GLOBAL_USER_MAP

// 星探等级对应map
export const GRADE_STAR_MAP = {
    '2': {
        text: '星店店主'
    },
    '3': {
        text: '代理'
    },
    '4': {
        text: '总代'
    },
    '5': {
        text: '专业特约'
    },
    error: {
        text: '未知'
    }
}

getItem('GRADE_MAP_NEW')
    .then(oldData => {
        if (oldData) {
            const old = JSON.parse(oldData)
            if (old['1']) {
                GRADE_MAP = old
            }
        }
    })
    .catch(e => {
        console.log(e)
    })

/**
 * 获取等级信息
 * @param level
 * @returns {*}
 */
export function getGradeInfo(level) {
    // 每次进去进货单都会调用setGradeInfo来更新信息
    if (GRADE_MAP[level]) {
        return GRADE_MAP[level]
    }
    return GRADE_MAP.error
}

/**
 * 设置等级信息
 * @param info
 * @returns {*}
 */
export function setGradeInfo(info) {
    const level = info.rankNum
    if (GRADE_MAP[level]) {
        GRADE_MAP[level] = {
            ...GRADE_MAP[level],
            text: info.name,
            totalPrice: info.totalPrice,
            risePrice: info.risePrice
        }
    }
}

/**
 * 获取经销商等级描述文本
 * @param grade
 * @returns {*}
 */
export function getUserGradeText(grade) {
    if (GRADE_MAP[grade]) {
        return GRADE_MAP[grade].text
    }
    return GRADE_MAP.error.text
}

/**
 * 获取星探等级描述文本
 * @param grade
 * @returns {*}
 */
export function getUserStarGradeText(grade) {
    if (GRADE_STAR_MAP[grade]) {
        return GRADE_STAR_MAP[grade].text
    }
    return GRADE_STAR_MAP.error.text
}

export function fetchUserGradeInfo(token) {
    // todo 获取等级信息
    return
    if (!isRightToken(token)) {
        return
    }
    /**
         deadline (integer): 有效期 ,
         name (string): 等级名称 ,
         rankNum (integer): 等级数值 ,
         risePrice (number): 晋级金额 ,
         totalPrice (number): 累计金额
     */
    R_GET(CAPACITYS_GET, {token})
        .then(response => {
            const {items} = response
            for (let i = 0; i < items.length; i++) {
                const {name, rankNum, totalPrice, risePrice} = items[i]
                if (GRADE_MAP[rankNum]) {
                    GRADE_MAP[rankNum] = {
                        ...GRADE_MAP[rankNum],
                        text: name,
                        risePrice,
                        totalPrice
                    }
                }
            }
            saveItem('GRADE_MAP_NEW', GRADE_MAP).then(() => {})
        })
        .catch(err => {
            console.log('更新等级信息失败', err)
        })
}

export const CHANNEL_MARKETING_CODE = getProjectText('渠道')
