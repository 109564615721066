/**
 @description:
 @date: 2018/3/26
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import {signature} from './Crypto/index'

export function encode(message = '') {
    return message
}

export function decode(message = '') {
    return message
}
export function encryptByDES() {
    return 'encryptByDES todo'
}

export function Sign(params) {
    return signature(params)
}
