/**
 @description: Avatar - 头像
 @date: 2018/1/10
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example:
 */
import React, {Component} from 'react'
import Header from './AppHeader'

const HEADER_BACK = require('./img/header_back.png')

export default class AppHeaderWithBack extends Component {
    onPress() {
        const {onPress} = this.props
        if (onPress) {
            onPress()
        }
    }

    render() {
        const {closeView} = this.props
        if (closeView) {
            return (
                <Header
                    leftItem={{
                        title: '返回',
                        icon: HEADER_BACK,
                        onPress: closeView,
                        layout: 'icon'
                    }}
                    {...this.props}
                    style={[{backgroundColor: '#f8f8f8'}, this.props.style]}
                />
            )
        }
        return <Header {...this.props} style={[{backgroundColor: '#f8f8f8'}, this.props.style]} />
    }
}
