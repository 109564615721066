/**
 @description: 获取百e、云康相关的差异化数据
 @date: 2017/7/13
 @author: 雷利(Rayleight@baie.com.cn)
 */
import {GLOBAL_PROJECT_TEXT} from '../project_config/global_config'

const PROJECT = 'baie'
export const IS_YUN_KANG = false
const PROJECT_TEXT = GLOBAL_PROJECT_TEXT

/**
 * 获取项目差异化的文案
 * @param key
 * @returns {*}
 */
export function getProjectText(key) {
    try {
        return PROJECT_TEXT[key][PROJECT]
    } catch (e) {
        console.error(`${key}未配置，请至 getProjectBind.js 配置`)
        return '未配置，请至 getProjectBind.js 配置'
    }
}

const PROJECT_IMAGE = {
    应用小图标: {
        baie: require('../img/login_page_icon1.png'),
        yunKang: require('../img/login_page_icon1.png')
    },
    创客_商城入口图标_新: {
        baie: require('../img/mall/mall-show.png'),
        yunKang: require('../img/mall/mall-show.png')
    },
    创客_商品详情_仓库图标: {
        baie: require('../img/mall/rosepie_store_logo.png'),
        yunKang: require('../img/mall/rosepie_store_logo.png')
    },
    个人中心_头像默认图片: {
        baie: require('../img/icons_tab_my/avatar_new_bg.png'),
        yunKang: require('../img/icons_tab_my/avatar_new_bg.png')
    },
    个人中心_分销伙伴_默认头像: {
        baie: require('../img/icons_tab_my/avatar_bg.png'),
        yunKang: require('../img/icons_tab_my/avatar_bg.png')
    },
    个人中心_分销伙伴_默认头像_2: {
        baie: require('../img/icons_tab_my/avatar_bg_2.png'),
        yunKang: require('../img/icons_tab_my/avatar_bg_2.png')
    },
    个人中心_分销伙伴_推荐人banner: {
        baie: require('../img/icons_tab_my/partnerDetailBanner.png'),
        yunKang: require('../img/icons_tab_my/partnerDetailBanner.png')
    }
}

/**
 * 获取项目差异化的图片
 * @param key
 * @returns {*}
 */
export function getProjectImage(key) {
    try {
        return PROJECT_IMAGE[key][PROJECT]
    } catch (e) {
        console.error(`${key}未配置，请至 getProjectBind.js 配置`)
        return '未配置，请至 getProjectBind.js 配置'
    }
}
