/**
 @description:各种接口的地址
 @date: 2016/7/6
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
// import {Platform} from 'react-native'
// import packageInfo from '../../package.json'

// let project_env = 'production'

// if (process.env.RELEASE_MODE === 'remote') {
//     // 远程发布以 config/deploy/XXX.rb:23 里注入的 APP_ENV 为准
//     project_env = process.env.APP_ENV
// } else {
//     // 本地发布以 package.json 为准
//     project_env = packageInfo.env
// }

// if (process.env.NODE_ENV === 'development') {
//     // 本地开发 package.json 为准
//     project_env = packageInfo.env
// }

// window.project_env = project_env

// export const IS_IOS = Platform.OS === 'ios'

// export const IS_BETA = project_env === 'beta'

// export const IS_REL = project_env === 'rel' || project_env === 'release'

// export const IS_PRE_PRODUCTION = project_env === 'pre_production'

// export const PREFIX = `${window.location.protocol}//`

// export const PREFIX_HTTPS = 'https://'

// export const PREFIX_HTTP = 'http://'

// // 爱英优选url
// export let IENGLISH_BASE_URL = `${PREFIX_HTTPS}member-agent.tope365.com/`

// //= ==========  重要提示  ===========
// // AppWebPageView 里有判断当前服务器地址
// // 注意：子域名有变更时 需要去 utils/index.js 里的 _FETCH 方法调整相关配置

// if (IS_BETA) {
//     IENGLISH_BASE_URL = `${PREFIX_HTTPS}member-agent.tope365.com/`
// }

// if (IS_PRE_PRODUCTION) {
//     IENGLISH_BASE_URL = `${PREFIX_HTTPS}web-agent.tope365.com/`
// }

export const IENGLISH_BASE_URL = process.env.API_ROOT
export const IENGLISH_BASE_URL2 = process.env.API_ROOT2
export const IENGLISH_BASE_URL3 = process.env.API_ROOT3
///marketer/ifPriceIncreasePop 判断涨价弹框
export const IFPRICEINCREASEPOP = `${IENGLISH_BASE_URL}/marketer/marketer/ifPriceIncreasePop`
////marketer/confirmPriceIncreasePop 确认涨价弹框
export const COMFIRMPRICEINCREASEPOP = `${IENGLISH_BASE_URL}/marketer/marketer/confirmPriceIncreasePop`
///marketer/ifServiceChargePop 判断是否服务费弹框
export const IFSERVICECHARGEPOP = `${IENGLISH_BASE_URL}/marketer/marketer/ifServiceChargePop`
//确认服务费弹框 marketer/confirmServiceChargePop
export const CONFIRMSERVICECHARGEPOP = `${IENGLISH_BASE_URL}/marketer/marketer/confirmServiceChargePop`
// 会员
export const LOGIN_URL = `${IENGLISH_BASE_URL}/member/password_login`

export const LOGIN_PHONE_URL = `${IENGLISH_BASE_URL}/member/verify_code_login`

// export const LOGIN_INSERT = `${IENGLISH_BASE_URL}/member/notifyUser/insert`

export const LOGOUT_URL = `${IENGLISH_BASE_URL}/logout.jhtml`

export const LOGIN_INSERT = `${IENGLISH_BASE_URL}/member/notifyUser/insert` //openId

export const BIND_OPENID = `${IENGLISH_BASE_URL}/member/bindOpenId` //新openId

export const SIGN_OUT = `${IENGLISH_BASE_URL}/member/signOut` //退出

export const PHONE_REGISTER = `${IENGLISH_BASE_URL}/marketer/marketer/save` // 手机号 验证码登录-注册

export const GANGAO_REGISTER = `${IENGLISH_BASE_URL}/marketer/marketer/speSave` //港澳台 手机号 验证码登录-注册

export const CHECK_PHONEEMAIL = `${IENGLISH_BASE_URL}/member/user/checkPhoneEmail` //港澳台  验证手机号或者邮箱是否满足条件

export const WEIXIN_ADD = `${IENGLISH_BASE_URL}/weixin/add` // 绑定微信
export const WEIXIN_ADD_WITHOUT_VERIFY = `${IENGLISH_BASE_URL}/weixin/add` // 绑定微信

export const WEIXIN_GET = `${IENGLISH_BASE_URL}/weixin/get` // 查询是否绑定微信

export const UNIFIED_USER_PHONE_EXIST = `${IENGLISH_BASE_URL}/unified_user/phoneExist` // 检查手机号是否存在

export const UNIFIED_USER_USERNAME_EXIST = `${IENGLISH_BASE_URL}/unified_user/usernameExist` // 检查手机号是否存在

export const WEIXIN_PHONEBINDINGGET = `${IENGLISH_BASE_URL}/weixin/phoneBindingGet` // 查询手机是否绑定微信

export const WEXIN_DELETE = `${IENGLISH_BASE_URL}/wexin/delete` // 解除微信绑定

export const REGISTER_URL = `${IENGLISH_BASE_URL}/unified_user/register` // app 先不做注册

export const I_FORGET_URL = `${IENGLISH_BASE_URL}/member/user/passwordForgot`

export const UPDATE_USER_AVATAR_URL = `${IENGLISH_BASE_URL}/member/user/cover_img_edit` // 修改用户头像

export const CHANGE_LOGIN_PWD_URL = `${IENGLISH_BASE_URL}/member/user/editPassword` // 修改登录密码

export const USER_BIND_MOBILE_URL = `${IENGLISH_BASE_URL}/member/user/editPhone` // 修改手机号

export const OSS_CRYPTO = `${IENGLISH_BASE_URL}/msg/cryptoGet`

export const USER_NUMBER_GET = `${IENGLISH_BASE_URL}/number/get` // 协议状态查询、修改

// export const REFRESH_CODE_IMAGE = `${IENGLISH_BASE_URL}/member/getImage` // 刷新动态验证码图片
export const REFRESH_CODE_IMAGE = `${IENGLISH_BASE_URL}/member/getCaptchaBase64` // 刷新动态验证码图片
export const REFRESH_CODE_IMAGE_NEW = `${IENGLISH_BASE_URL}/msg/sms/captcha` // 动态验证码图片
export const REFRESH_CODE_IMAGE_CHECK = `${IENGLISH_BASE_URL}/msg/image_code/verify` // 动态验证码校验

export const NODE_SEND_CODE = `${IENGLISH_BASE_URL}/msg/sms/codeSend` // 发送短信验证码

export const UNIFIED_USER_GET = `${IENGLISH_BASE_URL}/marketer/marketer/getInfo` // 查询用户信息

export const OPTION_DEAL_PASSWORD = `${IENGLISH_BASE_URL}/payaccount/tfs/password` //修改 重置交易密码

export const UNIFIED_USER_REGISTRATIONIDADD = `${IENGLISH_BASE_URL}/unified_user/registrationIdAdd` // 个体工商户认证

export const CAPACITYS_GET = `${IENGLISH_BASE_URL}/capacitys/get` // 等级信息获取

// 经销商注册
export const RECOMMENDGET_INFO = `${IENGLISH_BASE_URL}/member/user/getByUsername` // 邀请用户信息获取

export const UNIFIED_USER_GET_NEW = `${IENGLISH_BASE_URL}/marketer/marketer/getInfo` // 用户信息查询

// 商品接口改造 -结束

export const SELL_PROFITS_GET = `${IENGLISH_BASE_URL}/payplatform/order_clearing_infos/get` // 收益信息-新

// export const LOGISTICS_GET = `${ORDER}/logistics/get` // 获取物流信息

export const LOGISTICS_GET = `${IENGLISH_BASE_URL}/mailing/logistics/get` // 获取物流信息

export const B2B_SEND_LIST = `${IENGLISH_BASE_URL}/marketer/orders/getshippings` // 我的发货订单

export const B2C_ORDERS_CLEARING_FEN_REJECT = `${IENGLISH_BASE_URL}/marketer/order/rejectrepair` // 分销确认不补单
// export const B2C_ORDERS_CLEARING_FEN_REJECT = `${ORDER}/orders_clearing/noRepair` // 分销确认不补单

export const ORDER_WEB_SELLS_GET = `${IENGLISH_BASE_URL}/marketer/marketer/getList` // 我的好友列表

export const USER_INFO = `${IENGLISH_BASE_URL}/marketer/marketer/getInfo` // 用户信息

export const ORDER_WEB_PRODUCTS_GET = `${IENGLISH_BASE_URL}/marketer/collocations/get` // 发货列表、零售列表等等专题
export const PAYACCOUNT_FUNDQUERY_GET = `${IENGLISH_BASE_URL}/payaccount/tfs/fundQuery` // 发货列表、零售列表等等专题

export const PRODUCTS_GETLIST = `${IENGLISH_BASE_URL}/marketer/orders/getshippings` // 发货列表、我的库存列表等等专题

export const ORDER_WEB_ORDERSS_GET = `${IENGLISH_BASE_URL}/marketer/orders/get` // 分销订单列表查询

export const ORDER_WEB_ORDERS_CLEARINGS_GET = `${IENGLISH_BASE_URL}/marketer/orders/getclearing` // 分销补单列表查询
export const ORDER_WEB_ORDERS_SIZE_GET = `${IENGLISH_BASE_URL}/marketer//orders/size` // 分销补单列表查询

export const ORDER_WEB_ORDERS_REPAIR_GET = `${IENGLISH_BASE_URL}/marketer/order/repair` // 分销补单
export const ORDER_WEB_ORDERS_CLEARING_GET = `${IENGLISH_BASE_URL}/marketer/order/get` // 分销订单详情
export const ORDER_WEB_ORDERS_ORDRE_QUERY_PAY = `${IENGLISH_BASE_URL}/marketer/order/queryPay` // 分销订单详情

export const ORDER_WEB_COLLECTION_GET = `${IENGLISH_BASE_URL}/marketer/collocation/get` // 套组详情
export const WECHAT_ACCESSTOKEN = `${IENGLISH_BASE_URL}/msg/wechat/accessToken` // 套组详情

export const IUX_SEARCH_LIST = `${IENGLISH_BASE_URL}/marketer/marketer/getByTelOrName` //搜索

export const ORDER_WEB_COLLECTION_GETTRAINING = `${IENGLISH_BASE_URL}/marketer/collocation/getTraining` // 培训费套组详情

export const ORDER_WEB_ORDER_ADD = `${IENGLISH_BASE_URL}/marketer/order/add` // 普通订单下单
export const PAY_SUCCESS1 = `${IENGLISH_BASE_URL}/msg/paySuccess1` // 订货下单成功, 订单支付成功,生成发货单
export const PAY_SUCCESS2 = `${IENGLISH_BASE_URL}/msg/paySuccess2` // 订货下单成功, 余额不足
export const PAY_SUCCESS3 = `${IENGLISH_BASE_URL}/msg/paySuccess3` // 订货下单成功, 订单支付成功

export const ORDER_WEB_COLLECTIONS_LAST_ORDER_ADD = `${IENGLISH_BASE_URL}/marketer/order/collocationAdd` // 固定套组下单（先下单后支付）
export const ORDER_WEB_COLLECTIONS_TRAINING_ADD = `${IENGLISH_BASE_URL}/marketer/order/trainingAdd` // 新增服务费订单

export const ORDER_WEB_IF_PERSION = `${IENGLISH_BASE_URL}/marketer/order/ifpersionyueduofen` // 判断是否校董单子

export const ORDER_WEB_PAY = `${IENGLISH_BASE_URL}/ipay/index.html#` // 支付收银台

export const B2B_ORDER_DETAIL = `${IENGLISH_BASE_URL}/marketer/order/get` // 我的订货订单 订单详情

export const INVITE_COLLECTIONS_LIST_GET = `${IENGLISH_BASE_URL}/marketer/collocations/get` // 邀请礼包列表

export const INVITE_GETACTIVITY = `${IENGLISH_BASE_URL}/marketer/collocations/getActivity` //套组

export const IPAY_USER_BALANCE_QUERY = `${IENGLISH_BASE_URL}/payaccount/tfs/fundQuery`

// 内容
export const GUANGGAO_LIST = `${IENGLISH_BASE_URL}/marketer/_advertises/get` // 广告模块

export const MY_SHORTCUT_GET = `${IENGLISH_BASE_URL}/_advertise_sort/get` // 我的快捷方式【新标准】

export const MESSAGE_RECEIVER_UNREAD_COUNT_GET = `${IENGLISH_BASE_URL}/msg/message_receiver/unreadCountGet` // 系统通知未读数

export const MESSAGE_RECEIVERS_GET = `${IENGLISH_BASE_URL}/msg/message_receivers/get` // 系统通知列表

export const MESSAGE_RECEIVER_GET = `${IENGLISH_BASE_URL}/msg/message_receiver/get` // 系统通知列表

export const MESSAGE_RECEIVER_READ = `${IENGLISH_BASE_URL}/msg/message_receiver/read` // 系统通知列表

export const RMS_MESSAGE_RECEIVERS_GETTIME = `${IENGLISH_BASE_URL}/msg/message_receivers/getTime` // 系统通知列表

export const ORDERS_AFFIRMSHIPPING = `${IENGLISH_BASE_URL}/marketer/order/affirmshipping` //确认收货
// ems 模块
// export const USER_ADDRESS_LIST = `${EMS}/mailing/get` // 收货地址列表
export const USER_ADDRESS_LIST = `${IENGLISH_BASE_URL}/mailing/mailings/get` // 收货地址列表

export const USER_ADDRESS_ADD = `${IENGLISH_BASE_URL}/mailing/mailing/add` // 添加收货地址

export const USER_ADDRESS_EDIT = `${IENGLISH_BASE_URL}/mailing/mailing/edit` // 删除收货地址

export const USER_ADDRESS_DELETE = `${IENGLISH_BASE_URL}/mailing/mailing/delete` // 删除收货地址

export const USER_ADDRESS_PRIORITY = `${IENGLISH_BASE_URL}/mailing/mailing/priority` // 设为默认收货地址

export const USER_ADDRESS_DETAIL = `${IENGLISH_BASE_URL}/mailing/mailing/get` // 收货地址详情

// export const USER_ADDRESS_PRIORITY_GET = `${EMS}/mailing/priorityGet` // 默认收货地址详情
export const USER_ADDRESS_PRIORITY_GET = `${IENGLISH_BASE_URL}/mailing/mailing/priorityGet` // 默认收货地址详情

export const ORDERS_GETSHIPPING = `${IENGLISH_BASE_URL}/marketer/order/getshipping` //获取发货订单
export const ORDERS_SHIPPING = `${IENGLISH_BASE_URL}/marketer/order/shipping` //发货
export const ORDERS_REJECTREPAIR = `${IENGLISH_BASE_URL}/marketer/order/rejectrepair` //拒绝补货
export const ORDERS_GETCLEARINGREPAIR = `${IENGLISH_BASE_URL}/marketer/orders/getclearingrepair` //我的待补订单 //发货
export const ORDERS_GETRECEIVSHIPPINGS = `${IENGLISH_BASE_URL}/marketer/orders/getreceivshippings` //我的待补订单 //发货
export const ORDERS_AFFIRMPAY = `${IENGLISH_BASE_URL}/marketer/order/affirmpay` //我的待补订单 //发货
export const ORDERS_GETDESC = `${IENGLISH_BASE_URL}/payplatform/pay_config/getdesc` //我的待补订单 //发货

export const INVITE_REGISTER = `${IENGLISH_BASE_URL}registerLead` // 邀请注册链接

export const IUX_INVITER = `${IENGLISH_BASE_URL}/marketer/marketer/findInviter` //邀请人
export const IUX_FRIENDSCOUNT = `${IENGLISH_BASE_URL}/marketer/marketer/getFriendsCount` //查看好友数量

export const IENGLISH_SEND_DETAIL_URI = `${IENGLISH_BASE_URL}b2bSendDetail` // 发货详情页地址

export const ORDER_WEB_CANCEL_ORDERS = `${IENGLISH_BASE_URL}/marketer/order/abolish` // 订单品取消

export const GETENGLISHINFO = `${IENGLISH_BASE_URL}/marketer/marketer/getaffirmAcctCount` // 查询iEnglish账号信息

export const JUMP_TOP365_URL = `${IENGLISH_BASE_URL}/marketer/acct/Jump`

// 开户中间页

export const IPAY_WALLET_MIddle = `${IENGLISH_BASE_URL}walletMiddle`

//企业开户状态

export const ACCOUNT_STATUS = `${IENGLISH_BASE_URL}/payaccount/payaccount/getAccount`

//好友

export const LISTBYGROUP = `${IENGLISH_BASE_URL}/marketer/marketer/getListByGroup` //获取等级

//助理
export const FIND_ASSISTANT = `${IENGLISH_BASE_URL}/member/assistant/find`
export const DEL_ASSISTANT = `${IENGLISH_BASE_URL}/member/assistant/delete`
export const ADD_ASSISTANT = `${IENGLISH_BASE_URL}/member/assistant/insert`

export const ORDER_CHECKUSER = `${IENGLISH_BASE_URL}/marketer/order/checkUserQualification` //套组任务完成
export const ORDER_CHECKUSER_NEW = `${IENGLISH_BASE_URL}/marketer/order/checkUserQualificationNew` //套组任务完成
export const RECOMMENDGET_INFO_NEW = `${IENGLISH_BASE_URL}/member/user/getByUsernameNew` // 邀请用户信息获取

//实名认证

export const ELECTRON_GETBYUSER = `${IENGLISH_BASE_URL}/electron/verified/getByUserId` // 查询是否认证
export const REGISTER_ANDVERIFIED = `${IENGLISH_BASE_URL}/electron/verified/registerAndVerified` //注册并获取认证地址
export const GET_VERIFIEDINFO = `${IENGLISH_BASE_URL}/electron/verified/getVerifiedInfo` //查询认证信息
export const GET_STATUS = `${IENGLISH_BASE_URL}/electron/verified/getStatus` //查询认证信息
export const WHITELIST = `${IENGLISH_BASE_URL}/electron/whiteList/getByUserId` //白名单查询
export const USER_SNAPSHOT = `${IENGLISH_BASE_URL}/marketer/invite-friends/user-snapshot` //邀请好友并保存用户快照信息(平替save接口)
export const GET_POPUPVERIFEDINFO = `${IENGLISH_BASE_URL}/electron/verified/getPopupVerifiedInfo` //实名认证信息回显

//授权书
export const IS_EXITCERT = `${IENGLISH_BASE_URL}/electron/certificate/isExitCert` //查询有无授权证书
export const EXTSIGNAUTO = `${IENGLISH_BASE_URL}/electron/certificate/extSignAuto` //自动签署-授权证书
export const GENERATE_CONTRACT = `${IENGLISH_BASE_URL}/electron/certificate/generateContract` //生成授权证书
export const CUSTOM_SIGNATURE = `${IENGLISH_BASE_URL}/electron/certificate/customSignature` //自定义印章
export const VIEW_CONTRACT = `${IENGLISH_BASE_URL}/electron/certificate/viewContract` //查看授权证书

//电子签
export const IF_SIGN_ORDER = `${IENGLISH_BASE_URL}/electron/contract/ifSignOrder` //判断是否需要签署订货协议
export const IF_SIGN_PROXY = `${IENGLISH_BASE_URL}/electron/contract/ifSignProxy` //判断是否需要签署代理协议
export const CONTRACT = `${IENGLISH_BASE_URL}/electron/contract/contractList` //查询我的合同列表
export const SIGNPAGE = `${IENGLISH_BASE_URL}/electron/contract/signPage` //获取签署页面
export const IFYUEDUOFENG = `${IENGLISH_BASE_URL}/electron/contract/ifYueduofeng` //判断是否是悦多峰
export const EGETTIPS = `${IENGLISH_BASE_URL}/electron/contract/getTips` //判断是否是悦多峰
export const IFSIGNPROXY = `${IENGLISH_BASE_URL}/electron/contract/ifSignProxy` //判断是否是悦多峰
export const BATCHSIGN = `${IENGLISH_BASE_URL}/electron/contract/batchSign` //判断是否是悦多峰
export const RENEWAL_NOTICE = `${IENGLISH_BASE_URL}/electron/contract/renewal_notice` //判断电子签弹窗显示或强制字段
export const UPCONTEXT = `${IENGLISH_BASE_URL}/electron/contract/upContext` //电子签弹窗里面内容
export const GENERATE = `${IENGLISH_BASE_URL}/electron/contract/generate` //生成合同id
export const ISSHOWSTORTEPROTOCOL = `${IENGLISH_BASE_URL}/electron/contract/isShowStoreProtocol` //是否显示实体店协议

// pad用户续费
export const ISCHANGE_PRIVACYPOLICYPOPUPS = `${IENGLISH_BASE_URL}/marketer/buriedPointAct/changePrivacyPolicyPopups` //判断是否需要弹出隐私协议变更弹窗
export const TIMEBURIED_POINTACT = `${IENGLISH_BASE_URL}/marketer/buriedPointAct/timeBuriedPointAct` //隐私协议弹窗点击同意
export const UNCONFIRM_RENEWORDER = `${IENGLISH_BASE_URL}/marketer/renew/unConfirmedRenewOrder` //待确认的续费订单红点(同确认订单弹窗)
export const MY_RENEW_ORDER = `${IENGLISH_BASE_URL}/marketer/renew/myRenewOrder` //我买的续费订单
export const SELL_RENEW_ORDER = `${IENGLISH_BASE_URL}/marketer/renew/sellRenewOrder` //我卖的续费订单
export const SELL_APPEAL = `${IENGLISH_BASE_URL}/marketer/renew/order-appeal` //申诉
export const ORDER_DETAIL = `${IENGLISH_BASE_URL}/marketer/renew/order-detail` //我卖的订单详情
export const ORDER_DETAIL_SHOP = `${IENGLISH_BASE_URL}/marketer/renew/bought-order-detail` //我买的订单详情
export const CONFIRM_ORDER = `${IENGLISH_BASE_URL}/marketer/renew/confirm-order` //确认订单
export const CONFIRM_ORDER_HF = `${IENGLISH_BASE_URL}/marketer/fund/renew/confirm-order` //对接汇付-确认订单
export const PERSONAL_XIAMEN_ACCTINFO = `${IENGLISH_BASE_URL}/marketer/acct/personal/xiaMenAcctInfo` //开通个人户回显信息
export const ENTERPRISE_XIAMEN_ACCTINFO = `${IENGLISH_BASE_URL}/marketer/acct/enterprise/xiaMenAcctInfo` //开通企业户回显信息
export const QUERY_BALANCE = `${IENGLISH_BASE_URL}/marketer/renewalIncome/query-balance` //续费收益
export const HIFU_ACCINFO = `${IENGLISH_BASE_URL}/marketer/hf/acct/huifuAcctInfo` //续费收益/hf/acct/huifuAcctInfo
///marketer/hf/renewalIncome/renewal-amount
export const RENWAL_AMOUNT = `${IENGLISH_BASE_URL}/marketer/hf/renewalIncome/renewal-amount`

//消息
export const MESSAGE_RECEIVERS_LIST = `${IENGLISH_BASE_URL}/msg/message_receivers/getMessage` // 默认展示消息+公告列表
export const MESSAGE_RECEIVERS_ALL_READ = `${IENGLISH_BASE_URL}/msg/message_receiver/allRead` // 一键已读
export const MESSAGE_RECEIVERS_TYPE_LIST = `${IENGLISH_BASE_URL}/msg/message_receivers/getMessageType` // 获取用户接受全部消息列表
export const MESSAGE_RECEIVERS_UPWINDOWS = `${IENGLISH_BASE_URL}/msg/message_receiver/upWindows` // 公告弹窗

export const EDIT_EMAIL = `${IENGLISH_BASE_URL}/member/user/editEmail` //邮箱

export const DAILYTASK = `${IENGLISH_BASE_URL}/ientrepreneurship/taskTarget/dailyTask` //icy数据

export const PAYCHANNELURL = `${IENGLISH_BASE_URL}/payplatform/manual_refund/payChannelUrl` //自助退款

export const PHYSICALSTOREVERIFIED = `${IENGLISH_BASE_URL}/marketer/verified/physicalStoreVerified` //获取实体店实名认证信息
export const SUBMITPHYSICAL = `${IENGLISH_BASE_URL}/marketer/marketer/submitPhysicalStoreApprove`
// let url = 'http://10.0.1.30:822'
// let urlone = 'http://10.0.1.30:832'
//我的卡券
export const GETACTIVITYCARD = `${IENGLISH_BASE_URL}/marketer/activity/getActivityCard` //支付页面学霸有理弹窗
export const GETCARDLIST = `${IENGLISH_BASE_URL}/marketer/activity/getCardList` //我的卡券
export const CONSUMECARD = `${IENGLISH_BASE_URL}/marketer/activity/consumeCard` //使用卡券
export const USERGET = `${IENGLISH_BASE_URL}/member/user/userGet` //判断是否是海外账户

//数据埋点

export const TIMEBURIEDPOINTACT = `${IENGLISH_BASE_URL}/marketer/buriedPointAct/timeBuriedPointAct` //数据埋点

//升降级二期
export const GETAUDITINFO = `${IENGLISH_BASE_URL}/marketer/marketer/get_audit_data_info` //升降级展示数据
export const DISTRIBUTEACCOUNT = `${IENGLISH_BASE_URL}/marketer/marketer/distributeAccount` //根据等级账号数据
export const DISTRIBUTEACCOUNTBYUSERID = `${IENGLISH_BASE_URL}/marketer/marketer/distributeAccountByUserId` //

// 个人资料-我的协议是否全未签署
export const AGREEMENT_SHOWSTOREMSG = `${IENGLISH_BASE_URL}/electron/contract/showStoreMsg`

// 账号企业信息查询
export const ACCOUNT_INFO = `${IENGLISH_BASE_URL}/payaccount/wallet_bank/findAccountInfo`
///payaccount/wallet_bank/findPersonInfo 个人信息查询
export const FINDPERSON_INFO = `${IENGLISH_BASE_URL}/payaccount/wallet_bank/findPersonInfo`
/// 判断完善信息弹窗是否展示
export const furtherInformation = `${IENGLISH_BASE_URL}/marketer/marketer/fundPayPop`

// 开户状态查询
export const GET_OPEN_ACCOUNT_STATUS = `${IENGLISH_BASE_URL}/payaccount/thirdparty_account/get`

// 获取支付加密信息
export const GET_NUMBER_DESC = `${IENGLISH_BASE_URL}/payplatform/pay_platform/desc`
