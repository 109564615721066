/**
 @description: dom相关的工具函数
 @date: 2018/4/12
 @author: 雷利(Rayleight@baie.com.cn)
 */

export function appendCss(styleText) {
    let css = document.createElement('style')
    css.type = 'text/css'
    css.innerHTML = styleText
    document.body.appendChild(css)
}

export function addClassName(target, className) {
    if (!target || target.className === undefined || !className) {
        return
    }
    target.className = `${target.className} ${className}`
}

export function removeClassName(target, className) {
    if (!target || target.className === undefined || !className) {
        return
    }
    target.className = target.className.replace(className, '').replace('  ', ' ')
}

const needAutoHeightRoutes = ['/activity1', '/activity2', '/replaceActivity', '/rechargeQuota'] // 不需要 限制高度 100%的路由，比如需要页面lazyLoad的页面

export function updateHtmlAutoHeight() {
    const htmlDom = document.getElementsByTagName('html')[0]
    const nowPath = window.location.pathname
    if (needAutoHeightRoutes.includes(nowPath)) {
        addClassName(htmlDom, 'autoHeight')
    } else {
        removeClassName(htmlDom, 'autoHeight')
    }
}

/**
 * @description: 零售订单详情滚动穿透问题解决
 * @param type
 */
export function hideGoodsContentScrollBar(type = true) {
    let _fn = removeClassName
    if (type) {
        _fn = addClassName
    }
    _fn(document.body, 'hide-scroll-bar')
}
