/**
 * @author 贝波（liupengyu@baie.com.cn）
 * @date 2019-05-17 09:55
 * @Description:
 */
import * as types from '../constants/ActionTypes'

const initialState = {
    isCRMLoggedIn: false,
    CRMMessageList: []
}

export default function crm(state = initialState, action) {
    switch (action.type) {
        case types.CRM_LOGIN:
            return {
                ...state,
                isCRMLoggedIn: true
            }
        case types.CRM_LOGOUT:
            return {
                ...state,
                isCRMLoggedIn: false
            }
        case types.CRM_MESSAGE:
            return {
                ...state,
                CRMMessageList: action.messageList
            }
        case types.CRM_MESSAGE_DELETE:
            return {
                ...state,
                CRMMessageList: state.CRMMessageList.filter(item => {
                    return item.contactId !== action.contactId
                })
            }
        case types.CRM_MESSAGE_TOP:
            return {
                ...state,
                CRMMessageList: state.CRMMessageList.map(item => {
                    return {
                        ...item,
                        tag: item.contactId === action.contactId ? !item.tag : item.tag
                    }
                })
            }
        case types.CRM_MESSAGE_NOTIFY:
            return {
                ...state,
                CRMMessageList: state.CRMMessageList.map(item => {
                    return {
                        ...item,
                        notify: item.contactId === action.contactId ? !item.notify : item.notify
                    }
                })
            }
        default:
            return state
    }
}
