/**
 @description: reducer - 云商城
 @date: 2016/7/6
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as TYPES from '../constants/ActionTypes'

const initialState = {
    tabs: {
        index: 0, // 首页的消息数
        msg: 0, // 消息
        kb: 0, // 内容素材
        circle: 0, // 线下专区
        my: 0 // 我的
    }
}

export default function message(state = initialState, action) {
    const {newValue} = action
    const {tabs} = state
    switch (action.type) {
        case TYPES.UPDATE_TABS_MSG_NUM:
            return {
                ...state,
                tabs: {
                    ...tabs,
                    ...newValue
                }
            }
        default:
            return state
    }
}
