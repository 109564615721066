/**
 @description: reducer - 导航
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as TYPES from '../constants/ActionTypes'

let {pathname} = window.location
let routeList = []
let routeName = 'init'
if (pathname !== '') {
    pathname = pathname.substr(1)
    routeList = [pathname]
    routeName = pathname
}
const initialState = {
    tab: 'index',
    routeName,
    routeList,
    userInfoStatus: {}
}
global.__nowTabName = 'index'

export default function navigation(state = initialState, action) {
    switch (action.type) {
        case TYPES.SWITCH_TAB: {
            tabChange(action.tabId)
            return {
                ...state,
                routeName: 'mainTabs',
                tab: action.tabId
            }
        }
        case TYPES.GET_USER_INTO: {
            // userChange(action.tabId)
            return {
                ...state,
                userInfoStatus: action.newData
            }
        }
        case 'BACK': {
            pageChange(routeName)
            return {
                ...state,
                routeName: window.location.pathname.substr(1)
            }
        }
        case 'RESET': {
            const {actions} = action
            pageChange(routeName)
            return {
                ...state,
                routeName: actions[0].routeName
            }
        }
        case 'ReplaceCurrentScreen': {
            pageChange(action.routeName)
            return {
                ...state,
                routeName: action.routeName
            }
        }
        default:
            return action.routeName
                ? {
                      ...state,
                      routeName: action.routeName
                  }
                : state
    }
}

function pageChange(routeName) {
    if (global.__nowPageName && global.__nowPageName !== routeName) {
        let prevPageName = global.__nowPageName
        if (prevPageName === 'mainTabs') {
            prevPageName = `${prevPageName}_${global.__nowTabName}`
        }
        global.__prevPageName = prevPageName
    }
    let newRouteName = routeName
    if (newRouteName === 'mainTabs') {
        newRouteName = `${newRouteName}_${global.__nowTabName}`
    }
    global.__nowPageName = newRouteName
}

function tabChange(tab) {
    if (global.__nowTabName && global.__nowTabName !== tab) {
        global.__prevTabName = global.__nowTabName
    }
    global.__nowTabName = tab
    global.__nowPageName = `mainTabs_${tab}`
    global.__prevPageName = `mainTabs_${global.__prevTabName}`
}
