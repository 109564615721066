/**
 @description: 路由配置
 @date: 2018/3/12
 @author: 雷利(Rayleight@baie.com.cn)
 */
import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as connectAction from 'connected-react-router'
import * as NavigationActions from '../actions/Navigation'
import {needOpenOld} from '../utils/oldProjectUrl'
import {isRightToken, R_alert} from '../utils'
import AuthMiddleRoute from '../components/AuthMiddleRoute'
import {routeCheckCrossJumpInfo, storagePageInfoBeforeJump} from './mainTabs/AppCommonMiddle'
import {isInApp} from '../VUI/VStyle'
import {getProjectText} from '../utils/getProjetcBind'
import {wxShare} from '../utils/WechatApi'
import {isInWechat} from '../VUI/StyleSheet'

const {push, replace, goBack} = connectAction

// 需要验证登录状态的页面
class PrivateRouteContainer extends React.Component {
    componentDidMount() {
        if(isInWechat) {
            wxShare({showShare: false})
        }
        goOldPath.call(this)
        routeCheckCrossJumpInfo(this.props)
    }

    render() {
        if (checkOldPath.call(this)) {
            return null
        }
        const {appData, location, component: Component, authType, level = 1, userInfo} = this.props
        const {userEpithetId,ramarkJSON,grade} = userInfo
        if (location.pathname !== window.location.pathname) {
            // 解决页面返回时，当前路由还会被触发一次的bug
            return null
        }
        const isAuthenticated = isRightToken(appData.token)

        const nowRoute = location.pathname.replace(/^\//, '')
        const nowParam = location.state ? location.state : {}
        const nowUrlParam = getParamFromSearch(location.search)

        //去掉判断
        // if (isAuthenticated && ((+userEpithetId || 0) < level)) {
        //     R_alert(`该手机号未注册，请联系身边的${getProjectText('用户集合名称')}注册账号`)
        //     return <Redirect to={{pathname: '/login', search: "?loginType=phone&logout=force"}} />
        // }
        
        if (grade==5) {

            if(ramarkJSON){

                // return null

                if(window.location.pathname =='/b2bNewUserConfirmLevelUpSettled'
                    || window.location.pathname == '/addressEdit'
                    || window.location.pathname == '/b2bAddressSelect'
                    || window.location.pathname == '/inviteEspecialGoods'
                    || window.location.pathname == '/b2bOrderSuccess'
                ){
                    console.log(111)

                }else{

                    return <Redirect to={{pathname: '/b2bNewUserConfirmLevelUpSettled', search: `?id=${ramarkJSON}&originType=1`}} />
                }


                // return <Redirect to={{pathname: '/b2bOrderConfirmLevelUpSettled', search: `?id=${ramarkJSON}&originType=1`}} />
            }else{
                // R_alert('====>'+grade)
                //return <Redirect to={{pathname: '/login', search: "?loginType=phone&logout=force"}} />
            }
        }
        
        if (isAuthenticated) {
            return authType ? <AuthMiddleRoute {...this.props} /> : <Component {...this.props} />
        }
        // from 的值： appData.token==="logout"，说明点击 退出登录触发的，则不需要带上from，因为from是myDetail
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        from:
                            appData.token === 'logout'
                                ? false
                                : {
                                      route: nowRoute,
                                      param: {...nowParam, ...nowUrlParam}
                                  }
                    }
                }}
            />
        )
    }
}

// 不验证登录状态的页面
class PublicRouteContainer extends React.Component {
    componentDidMount() {
        if(isInWechat) {
            wxShare({showShare: false})
        }
        goOldPath.call(this)
        routeCheckCrossJumpInfo(this.props)
    }

    render() {
        if (checkOldPath.call(this)) {
            return null
        }
        const {location, component: Component, authType} = this.props

        if (location.pathname !== window.location.pathname) {
            // 解决页面返回时，当前路由还会被触发一次的bug
            return null
        }
        return authType ? <AuthMiddleRoute {...this.props} /> : <Component {...this.props} />
    }
}

function checkOldPath() {
    const {location, param} = this.props
    try {
        let routeName = location.pathname.substr(1)
        let oldProjectUrl = needOpenOld(routeName, param)
        if (oldProjectUrl) {
            return true
        }
    } catch (e) {
        console.log(e)
    }
    return false
}
function goOldPath() {
    const {location, param} = this.props
    try {
        let routeName = location.pathname.substr(1)
        let oldProjectUrl = needOpenOld(routeName, param)
        if (oldProjectUrl) {
            window.location.href = oldProjectUrl
        }
    } catch (e) {}
}

function mapStateToProps(state) {
    const {router, appData, userInfo, navigation} = state
    const {location} = router
    const stateParam = location.state ? location.state : {}
    const urlParam = getParamFromSearch(location.search)
    return {
        appData,
        token: appData.token,
        userInfo,
        navigation,
        param: {...stateParam, ...urlParam}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({...NavigationActions}, dispatch)
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const {token, userInfo} = stateProps
    const {actions, dispatch} = dispatchProps
    const {back, navigate, reset, replaceCurrentScreen} = actions

    const openView = (routeName, _param, type = 'push') => {
        const nowRoute = window.location.pathname.replace(/^\//, '')
        let param = {}
        for (const paramKey in _param) {
            if (typeof _param[paramKey] !== 'function') {
                param[paramKey] = _param[paramKey]
                // 参数无法带funtion
            }
        }

        let webPageUrl = needOpenOld(routeName, param) // 判断是否需要拦截到老仓库

        if ((routeName === 'webPage' || routeName === 'cash') && param && param.uri) {
            webPageUrl = addCallbackForWabPage(param) // 自动带上 __c=https://trade.rosepie.com/m 作为回调地址
            storagePageInfoBeforeJump(param) // 离开系统时，把回调页面信息存起来
        }
        if (webPageUrl) {
            // 需要跳到老仓库或者 路由名是 webPage 的情况直接用location跳转
            // webPage 的uri 会覆盖 oldProjectUrl，不过没关系，routeName为webPage时，needOpenOld肯定返回false

            if (type === 'replace') {
                // 调用 navigator.replace({XXX:{}})的情况
                window.location.replace(webPageUrl)
            } else {
                window.location.href = webPageUrl
            }
            return
        }

        if (type === 'replace') {
            if (nowRoute !== 'login' && nowRoute === routeName) {
                // replace遇到 路由名相同，会导致页面不刷新，这里直接用 location
                // 这个场景主要是给云商城首页重定向需求用的
                window.location.replace(`/${routeName}${setParamsForSearch(param)}`)
            } else {
                replaceCurrentScreen(routeName)
                dispatch(replace(`/${routeName}${setParamsForSearch(param)}`, param))
            }
        } else {
            // push 应该规避 新老路由名一样的问题
            navigate(routeName)
            dispatch(push(`/${routeName}${setParamsForSearch(param)}`, param))
        }
    }

    const navigator = {
        // 理论上web只有push(navigate)）  pop  replace 其他都是兼容app react-navigation
        navigate: (routeName, param) => {
            openView(routeName, param)
        },
        push: route => {
            const routeName = Object.keys(route)[0]
            const params = route[routeName]
            openView(routeName, params)
        },
        pop: () => {
            back()
            dispatch(goBack())
        },
        replace: route => {
            const routeName = Object.keys(route)[0]
            const params = route[routeName]
            openView(routeName, params, 'replace')
        },
        // 为了兼容app 重置路由栈  但是对于web来说只是取传进来的数组的最末位
        immediatelyResetRouteStack: routes => {
            const targetIndex = routes.length - 1
            const routeName = routes[targetIndex].routeName
            const param = routes[targetIndex][routeName]
            reset([{routeName, param}], routes.length - 1)
            dispatch(replace(`/${routeName}`), param)
        },
        token,
        userInfo
    }
    window.__b_navigator = navigator
    return Object.assign({}, ownProps, stateProps, {
        navigator,
        dispatch,
        connectAction
    })
}

export const PrivateRoute = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PrivateRouteContainer)
export const Route = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PublicRouteContainer)

export function getParamFromSearch(search) {
    if (!search) {
        return {}
    }
    let params = search.substr(1)
    if (params !== '') {
        let temp = decodeURI(params).split('&')
        params = {}
        for (let i = 0; i < temp.length; i++) {
            let obj = temp[i]
            let key = obj.split('=')
            let value = key[1]

            try {
                value = decodeURIComponent(value)
            } catch (e) {}
            try {
                if (value.indexOf('{') !== -1) {
                    value = JSON.parse(value)
                }
            } catch (e) {}
            if (value === 'false') {
                value = false
            }
            if (value === 'true') {
                value = true
            }
            if (value === 'undefined') {
                value = undefined
            }
            if (value === 'null') {
                value = null
            }
            if (params[key[0]]) {
                if (params[key[0]] instanceof Array) {
                    params[key[0]].push(value)
                } else {
                    params[key[0]] = [params[key[0]], value]
                }
            } else {
                params[key[0]] = value
            }
        }
    } else {
        params = {}
    }

    return params
}

/**
 * 为地址栏加上参数
 * @param params
 * @returns {string}
 */
export function setParamsForSearch(params) {
    let _p = []
    const ignoreKeys = ['__index', '__item_id', 'token', '__isLast', 'hide_msg'] // 不需要添加到地址栏上的参数
    if (params instanceof Object) {
        for (let key in params) {
            let value = params[key]
            if (ignoreKeys.indexOf(key) !== -1) {
                continue
            }
            if ((value && value.length > 30) || value === undefined) {
                // 值太长的不放到地址栏里
                continue
            }
            if (key === 'callbackRoute') {
                _p.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`)
            } else if (value instanceof Object) {
                value = JSON.stringify(value)
            } else if (value instanceof Array) {
                value = JSON.stringify(value)
            } else {
                _p.push(`${key}=${encodeURIComponent(value)}`)
            }
        }
    }
    let urlParams = ''
    if (_p.length > 0) {
        urlParams = `?${_p.join('&')}`
    }

    return urlParams
}

function addCallbackForWabPage(params) {
    let uri = params.uri
    if (uri.indexOf('?') === -1) {
        uri += '?'
    } else {
        uri += '&'
    }

    if (params.callbackPageInfo) {
        const callbackUrl = encodeURIComponent(`${window.location.origin}/m`)
        return `${uri}__c=${callbackUrl}`
    }
    if (isInApp) {
        return `${uri}platform=baieapp`
    }
    return params.uri
}
