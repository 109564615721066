/**
 @description: 路由跳转的actions
 @date: 2018/4/15
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as types from '../constants/ActionTypes'

/**
 * navigate
 * @param routeName
 * @returns {{type: string, routeName: *}}
 */
export function navigate(routeName) {
    return {
        type: 'NAVIGATE',
        routeName
    }
}

/**
 * BACK
 * @returns {{type: string}}
 */
export function back() {
    return {
        type: 'BACK'
    }
}

/**
 * RESET
 * @param actions
 * @param index
 * @returns {{type: string, actions: *, index: *}}
 */
export function reset(actions, index) {
    return {
        type: 'RESET',
        actions,
        index
    }
}

/**
 * replaceCurrentScreen
 * @param routeName
 * @returns {{type: string, routeName: *}}
 */
export function replaceCurrentScreen(routeName) {
    return {
        type: 'ReplaceCurrentScreen',
        routeName
    }
}
