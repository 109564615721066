/**
 @description: 支付失败
 @date: 2019/8/28
 @author: hoe(yanghanjia@baie.com.cn)
 */
import React, {useState, useEffect} from 'react'
import {View, ScrollView, Image} from 'react-native'
import AppHeaderWithBack from '../../VUI/AppHeaderWithBack'
import * as StyleSheet from '../../VUI/StyleSheet'
import Button from '../../VUI/Button'
import Text from '../../VUI/Text'
import {track} from '../../utils/ZGTools'
import VStyle, {tSize} from '../../VUI/VStyle'
import {pageBackgroundColor, normalText} from '../../VUI/Colors'
import {R_POST, R_alert} from '../../utils/index'
import {PRODUCT_REPLACEMENT_ORDER_SHIPMENTID_GET} from '../../constants/FetchUrls'
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: pageBackgroundColor
    },
    scrollView: {}
})

const AppPayFailedView = props => {
    const [cash_result] = useState(props.param?.cash_result || 'fail')
    const CASH_RETURN_CODE = {
        success: '支付成功', // 支付成功
        fail: '支付失败', // 支付失败
        abort: '支付取消', // 用户主动取消
        timeOut: '支付超时' // 支付超时
    }

    useEffect(() => {
        track('进入支付失败页面')
    }, [])

    const closeView = () => {
        const {navigator} = props
        navigator.pop()
    }

    const goBack = () => {
        const {navigator} = props
        navigator.replace({
            mainTabs: {
                tab: 'my'
            }
        })
    }

    return (
        <View style={VStyle.container_bg}>
            <AppHeaderWithBack title={CASH_RETURN_CODE[cash_result]} closeView={closeView} />
            <ScrollView>
                <View>
                    <Image
                        style={{
                            width: tSize(60),
                            height: tSize(60),
                            alignSelf: 'center',
                            marginTop: tSize(30),
                            marginBottom: tSize(20)
                        }}
                        source={require('../../img/b2b/pay-fail.png')}
                    />
                    <Text
                        style={{
                            alignSelf: 'center',
                            color: normalText,
                            fontSize: tSize(20),
                            marginBottom: tSize(15)
                        }}>
                        {CASH_RETURN_CODE[cash_result]}
                    </Text>
                    <Button
                        style={{margin: tSize(15), marginBottom: tSize(15)}}
                        size="lg"
                        caption="返回"
                        onPress={goBack}
                    />
                </View>
            </ScrollView>
        </View>
    )
}

export default AppPayFailedView
