/**
 @description: 按钮
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 @example: <Button loading={isSaving} onPress={this.save.bind(this)} style={{}} buttonStyle={{}} caption="确定"/>
 */
import React, {Component} from 'react'
import {View, Image, ActivityIndicator} from 'react-native'

import {
    COLOR_PRIMARY,
    COLOR_WHITE_24,
    disableColor,
    COLOR_WHITE_80,
    disableText,
    borderColor,
    FONT_COLOR_IN_PRIMARY, FONT_COLOR_IN_DISABLE
} from './Colors'
import Text from './Text'
import TouchableOpacity from './TouchableOpacity'
import ButtonOld from '../components/Button'
import {TRUE_ONE_PIXEL, tSize} from './VStyle'
import * as StyleSheet from './StyleSheet'

const styles = StyleSheet.create({
    container_default: {
        height: tSize(30)
    },
    container_md: {
        height: tSize(40)
    },
    container_lg: {
        height: tSize(50)
    },
    container_default_pb: {
        iPhoneX: {
            height: tSize(50)
        }
    },
    container_md_pb: {
        iPhoneX: {
            height: tSize(60)
        }
    },
    container_lg_pb: {
        iPhoneX: {
            height: tSize(70)
        }
    },

    button: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: tSize(15),
        borderRadius: tSize(3),
        paddingTop: tSize(8),
        paddingBottom: tSize(8),
        android: {
            paddingTop: tSize(7)
        }
    },
    button_primary: {
        backgroundColor: COLOR_PRIMARY
    },
    button_bordered: {
        backgroundColor: 'transparent',
        borderWidth: TRUE_ONE_PIXEL,
        borderColor: COLOR_WHITE_80
    },
    button_md: {
        borderRadius: tSize(5),
        paddingTop: tSize(9),
        paddingBottom: tSize(9),
        paddingHorizontal: tSize(25),
        android: {
            paddingTop: tSize(8)
        }
    },
    button_lg: {
        borderRadius: tSize(5),
        paddingTop: tSize(13),
        paddingBottom: tSize(13),
        borderColor: COLOR_WHITE_80,
        android: {
            paddingTop: tSize(12)
        }
    },

    button_default_pb: {
        borderRadius: 0,
        iPhoneX: {
            paddingBottom: tSize(28)
        }
    },
    button_md_pb: {
        borderRadius: 0,
        iPhoneX: {
            paddingBottom: tSize(29)
        }
    },
    button_lg_pb: {
        borderRadius: 0,
        iPhoneX: {
            paddingBottom: tSize(33)
        }
    },

    caption: {
        letterSpacing: 1,
        fontSize: tSize(13),
        lineHeight: tSize(13)
    },
    caption_md: {
        fontSize: tSize(15),
        lineHeight: tSize(16)
    },
    caption_lg: {
        fontSize: tSize(18),
        lineHeight: tSize(19)
    },
    icon: {
        marginRight: tSize(5),
        width: tSize(18),
        height: tSize(18)
    },
    loading: {
        marginRight: tSize(5)
    },
    disable_primary: {
        backgroundColor: disableColor,
        borderWidth: 0
    },
    disable_bordered: {
        backgroundColor: disableColor,
        borderColor: disableColor
    }
})

export default class Button extends Component {
    onPress() {
        const {onPress, loading, disable, onDisablePress} = this.props
        if (loading) {
            return
        }
        if (disable) {
            if (onDisablePress) {
                onDisablePress()
            }
        } else {
            if (onPress) {
                onPress()
            }
        }
    }

    render() {
        const {
            caption,
            assist,
            hide = false,
            loadingCaption,
            icon,
            iconStyle = {},
            type = 'primary',
            size = 'default',
            loading,
            style,
            buttonStyle,
            textStyle,
            color,
            disable,
            disableExtraStyle,
            disableExtraTextStyle,
            onDisablePress,
            pb = false
        } = this.props

        if (hide) {
            return null
        }
        let pbContainerStyle = {}
        let pbStyle = {}
        if (pb) {
            // pb ： 表示 这个按钮在屏幕底部

            // 兼容 iphone X 底部按钮
            pbStyle = styles[`container_${size}_pb`]
            pbContainerStyle = styles[`button_${size}_pb`]
        }
        let extraStyle = {}

        if (size === 'sm') {
            // 老版本的 size sm 与现有规范不一致，这里直接用老的Button
            return <ButtonOld {...this.props} />
        }
        let captionText = caption
        let assisttext = assist
        let assistStyle = null
        let assistDom = null
        if (assisttext) {
            assistStyle = {flexDirection: 'column'}
            assistDom = <Text style={{color: FONT_COLOR_IN_PRIMARY, marginTop: tSize(3), fontSize: tSize(10)}}>{assisttext}</Text>
        }
        if (Object.keys(extraStyle).length) {
            extraStyle.borderColor = color
        }
        let textColor = color
        if (!textColor) {
            if (type === 'bordered' || type === 'normal') {
                textColor = COLOR_WHITE_24
            } else {
                textColor = FONT_COLOR_IN_PRIMARY
            }
        }
        if (disable) {
            textColor = FONT_COLOR_IN_PRIMARY
        }

        let iconImage
        if (icon && !loading) {
            iconImage = (
                <Image source={icon} resizeMode="stretch" style={[styles.icon, styles[`icon_${size}`], iconStyle]} />
            )
        }

        let loadingDom
        let disableStyle = {}

        if (loading) {
            captionText = loadingCaption || caption
            loadingDom = <ActivityIndicator animating={true} style={styles.loading} color={textColor} size="small" />
        }
        if (loading || disable) {
            disableStyle = styles[`disable_${type}`]

            if (size === 'lg') {
                disableStyle = styles.disable_primary
                textColor = FONT_COLOR_IN_DISABLE
            }
        }

        let content = (
            <View
                style={[
                    styles.button,
                    styles[`button_${type}`],
                    styles[`button_${size}`],
                    extraStyle,
                    pbContainerStyle,
                    buttonStyle,
                    disableStyle,
                    disable ? disableExtraStyle : {},
                    assistStyle
                ]}>
                {iconImage}
                {loadingDom}
                <Text
                    style={[
                        styles.caption,
                        styles[`caption_${type}`],
                        styles[`caption_${size}`],
                        {color: textColor},
                        textStyle,
                        disable ? disableExtraTextStyle : {}
                    ]}>
                    {captionText}
                </Text>
                {assistDom}
            </View>
        )

        if (disable && !onDisablePress) {
            return <View style={[styles[`container_${size}`], pbStyle, style]}>{content}</View>
        }

        return (
            <TouchableOpacity
                accessibilityTraits="button"
                onPress={this.onPress.bind(this)}
                activeOpacity={0.5}
                style={[styles[`container_${size}`], pbStyle, style]}>
                {content}
            </TouchableOpacity>
        )
    }
}
