/**
 @description: reducer - 云商城
 @date: 2016/7/6
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as TYPES from '../constants/ActionTypes'

const initialState = {}

export default function mall(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}
