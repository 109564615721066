/**
 @description: 应用通用回调页面 - 跳到第三方系统后，都回跳回到这个路由，然后统一由这个路由处理跳向哪里 - 详细的方案思路看 /doc/crossJump.md
 @date: 2018/9/25
 @author: Coby(sunqilin@baie.com.cn)
 */
import React, {Component} from 'react'

import * as qs from 'qs'
import {getItem, saveItem, removeItem} from '../../utils/Storage'
import {isInApp} from '../../VUI/VStyle'

const APP_LATEST_LEAVE_PAGE = 'APP_LATEST_LEAVE_PAGE'
const __CROSS_JUMP_BACK_URL = '__CROSS_JUMP_BACK_URL'

export default class AppCommonMiddle extends Component {
    constructor(props) {
        super(props)

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount() {
        const {navigator, param} = this.props
        let latestLeavePage = false
        try {
            latestLeavePage = JSON.parse(localStorage.getItem('APP_LATEST_LEAVE_PAGE'))
        } catch (e) {
            console.log(e)
        }
        removeItem(APP_LATEST_LEAVE_PAGE).catch(e => {
            console.log(e)
        })
        if (isInApp) {
            window.postMessage(
                JSON.stringify({
                    type: 'latestLeavePage'
                })
            )
        } else {
            if (latestLeavePage && latestLeavePage.routeName) {
                navigator.replace({
                    [latestLeavePage.routeName]: {
                        ...latestLeavePage.param,
                        ...param
                    }
                })
            } else {
                navigator.replace({
                    mainTabs: {}
                })
            }
        }
    }

    render() {
        return null
    }
}

/**
 * 把需要跳转的页面信息存储到localStorage 中
 * @param params 数据格式如下
 * @example storagePageInfoBeforeJump(
 *     {
            webPage:{
                title: "购买星探",
                uri: "https://xxx.com/buy",
                
                callbackPageInfo:{
                    routeName:"b2cOrderDetail",
                    param:{
                        id:123,
                        type:"forBuyer"
                    }
                }
            }
        }
 *  )
 */
export function storagePageInfoBeforeJump(params) {
    const {callbackPageInfo} = params
    if (!callbackPageInfo) {
        return
    }
    saveItem(APP_LATEST_LEAVE_PAGE, callbackPageInfo).catch(err => {
        console.error(err)
    })
}

/**
 * 路由自动检查跨平台跳转信息
 * @param props
 */
export function routeCheckCrossJumpInfo(props) {
    const {path, param} = props
    if (!path || !param) {
        return
    }
    let routeName = path.replace('/', '')
    if (routeName === '') {
        routeName = 'mall'
    }

    const {__c} = param

    let old_CJBU = false

    try {
        old_CJBU = JSON.parse(localStorage.getItem(__CROSS_JUMP_BACK_URL))
        // __CROSS_JUMP_BACK_URL ：{
        //     url:"https://trade.rosepie.com/m",
        //     startRoute:{
        //         routeName:"buy",
        //         param:{id:123}
        //     }
        // }
    } catch (e) {}

    if (__c) {
        // 如果本次访问有 跨系统跳转参数，那么直接覆盖存储
        saveItem(__CROSS_JUMP_BACK_URL, {
            url: __c,
            startRoute: {
                routeName,
                param
            }
        }).catch(e => {
            console.error(e)
        })
    } else {
        // 如果本次访问访问没有 跨系统跳转参数，那么判断是否需要清除跨系统的参数
        if (old_CJBU) {
            const {startRoute} = old_CJBU
            if (startRoute.routeName === routeName) {
                // 如果访问的路由是同一个，且没有 __c ，那么就可以把  __CROSS_JUMP_BACK_URL 清了
                localStorage.removeItem(__CROSS_JUMP_BACK_URL)
            }
        }
    }
}

export function isNeedCrossJump(param) {
    let old_CJBU = false

    try {
        old_CJBU = JSON.parse(localStorage.getItem(__CROSS_JUMP_BACK_URL))
        // __CROSS_JUMP_BACK_URL ：{
        //     url:"https://trade.rosepie.com/m",
        //     startRoute:{
        //         routeName:"buy",
        //         param:{id:123}
        //     }
        // }
    } catch (e) {}

    if (old_CJBU) {
        let {url} = old_CJBU
        let callbackParam = ''
        if (param) {
            callbackParam = qs.stringify(param)
            if (url.indexOf('?') === -1) {
                url += '?'
            } else {
                url += '&'
            }
            url += callbackParam
        }

        localStorage.removeItem(__CROSS_JUMP_BACK_URL) // 流程结束，删除本次跳转信息
        window.location.replace(url)
        return true
    }

    return false
}
