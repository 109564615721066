/**
 @description: b2b部分数据
 @date: 2016-8-1
 @author: Rayleigh(Rayleigh@Nero-zou.com)
 */
import * as TYPES from '../constants/ActionTypes'

// const initialState = {
//     cart: {
//         id: 592,
//         productQuantity: 1,
//         sellerId: 4536,
//     },
//     cartItems: [],
//     category: -1 //-1 表示全部
// }
const initialState = {
    cart: {
        id: 0,
        productQuantity: 0,
        sellerId: 0
    },
    cartItems: [],
    category: -1 // -1 表示全部
}

export default function navigation(state = initialState, action) {
    switch (action.type) {
        case TYPES.B2B_CART_RESET: {
            return initialState
        }
        case TYPES.B2B_CART_ADD: {
            const {cart} = state
            return {
                ...state,
                cart: {...cart, productQuantity: cart.productQuantity + 1},
                cartItems: [...state.cartItems, action.cartItem]
            }
        }

        case TYPES.B2B_CART_DELETE: {
            const {cart} = state
            return {
                ...state,
                cart: {...cart, productQuantity: cart.productQuantity - 1},
                cartItems: state.cartItems.filter(item => item.id !== action.id)
            }
        }
        case TYPES.B2B_CART_UPDATE: {
            let cartItems = []
            if (action.cartItems instanceof Array) {
                cartItems = action.cartItems
            }
            return {
                ...state,
                cart: action.cart,
                cartItems
            }
        }
        case TYPES.B2B_CHANGE_CATEGORY: {
            return {
                ...state,
                category: action.category
            }
        }
        default:
            return state
    }
}
