/**
 @description: App级别的actions
 @date: 2016/7/5
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as types from '../constants/ActionTypes'

/**
 * 更新 appData
 * @param state
 * @returns {{type, tabId: *}}
 */
export function setAppData(state) {
    return {
        type: types.SET_APP_DATA,
        state
    }
}

/**
 * 设置 token
 * @param token
 * @returns {{type, tabId: *}}
 */
export function setToken(token) {
    global.token = token
    return {
        type: types.SET_TOKEN,
        token
    }
}

/**
 * 实名认证
 * @param newData
 * @returns {{type, newData: *}}
 */
export function getUserInfoData(newData) {
    return {
        type: types.GET_USER_INTO,
        newData
    }
}

/**
 * 切换tab
 * @param tabId :  ['mall'| 'cart' | 'my']
 * @returns {{type, tabId: *}}
 */
export function switchTab(tabId) {
    return {
        type: types.SWITCH_TAB,
        tabId
    }
}

/**
 * 切换实名认证
 * @param tabId :  ['mall'| 'cart' | 'my']
 * @returns {{type, data: *}}
 */
export function getUser(data) {
    return {
        type: types.GET_USER,
        data
    }
}

/**
 * 界面显示在最顶层了 - Navigator onDidFocus
 * @param routeName
 * @returns {{type, routeName: *}}
 */
export function routeDidFocus(routeName) {
    return {
        type: types.ROUTE_DID_FOCUS,
        routeName
    }
}

/**
 * 显示个人中心的公告弹窗
 * @param notifyInfo
 * @returns {{type, routeName: *}}
 */
export function showNotify(notifyInfo) {
    return {
        type: types.SHOW_NOTIFY,
        notifyInfo
    }
}

/**
 * 隐藏个人中心的公告弹窗
 * @returns {{type, routeName: *}}
 */
export function hideNotify() {
    return {
        type: types.HIDE_NOTIFY
    }
}

/**
 * 更新分享的信息
 * @param shareInfo
 * @returns {{type, shareInfo: *}}
 */
export function updateShare(shareInfo) {
    return {
        type: types.UPDATE_SHARE,
        shareInfo
    }
}

/**
 * 更新推送信息
 * @param notificationMsg
 * @returns {{type, notificationMsg: *}}
 */
export function updateNotificationMsg(notificationMsg) {
    return {
        type: types.NOTIFICATION_DATA,
        notificationMsg
    }
}

/**
 * 网络环境变化
 * @param netInfo
 * @returns {{type, netInfo: *}}
 */
export function netInfoChange(netInfo) {
    return {
        type: types.NET_INFO_CHANGE,
        netInfo
    }
}

/**
 * 显示图片轮播图
 * @param imageSlide
 * @returns {{type: string, imageSlide: *}}
 */
export function showImageSlide(imageSlide) {
    return {
        type: types.SHOW_IMAGE_SLIDE,
        imageSlide
    }
}

/**
 * //更新底部tab 的数字
 * @param newValue
 * @returns {{type, netInfo: *}}
 */
export function updateTabsMsgNum(newValue) {
    return {
        type: types.UPDATE_TABS_MSG_NUM,
        newValue
    }
}

/**
 * 处理页面存在modal 被app.js(层级较高)里的CustomerService || QuickMenu 遮挡
 * @param quickMenu true展示 false则render null
 * @returns {{type, newValue: *}}
 */
export function toggleQuickMenu(quickMenu = true) {
    return {
        type: types.TOGGLE_QUICKMENU,
        quickMenu
    }
}
