/**
 @description: reducer - 用户信息
 @date: 2016/7/18
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import * as TYPES from '../constants/ActionTypes'
import {USER_INFO} from '../constants/StorgeKeys'
import * as Storage from '../utils/Storage'

const initialState = {
    userId: 0,
    msg_num: 0, // 未读消息数
    myAsset: {},
    userName: '',
    avatar: '',
    grade: 0, // 经销商等级
    wechat: '',
    mobile: '',
    realName: '',
    status: '',
    address: [],
    addressChecked: {id: 0}, // 当前选中的地址
    addressCheckedId: 0, // 当前选中的地址id
    sellerId: '',
    permission: false,
    walletChannel: '', // 支付渠道 yeepay || TFS
    walletOpenStatus: '', // 钱包开通状态
    ifIdCardType: true, // 是否是身份证用户
    starInfo: {
        rankId: null, // 星探id
        rankNum: null, // 星探等级
        ifPopula: false, // 是否星探
        ifMarketerRight: false, // 是否拥有代销权
        pId: null // 上阶id
    },
    userEpithetId: 0, // iEnglish 用户等级
}

function save(state) {
    Storage.saveItem(USER_INFO, JSON.stringify(state))
        .then(() => {})
        .catch(err => {
            console.log('存储用户信息失败：', err)
        })

    return state
}

export default function userInfo(state = initialState, action) {
    switch (action.type) {
        case TYPES.SET_USER_INFO: {
            global.__userId = action.user_info.userId
            return save({
                ...initialState,
                ...state,
                ...action.user_info
            })
        }

        case TYPES.UPDATE_USER_INFO: {
            global.__userId = action.newData.userId
            return save({
                ...initialState,
                ...state,
                ...action.newData
            })
        }

        case TYPES.LOGOUT: {
            global.__userId = 0
            return save({
                ...initialState
            })
        }

        case TYPES.UPDATE_CHECK_ADDRESS: {
            return save({
                ...state,
                address: [action.address],
                addressCheckedId: action.address.id,
                addressChecked: action.address
            })
        }
        default:
            return state
    }
}
