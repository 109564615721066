/**
 @description: 模态框 基于react-modal  ref：https://github.com/reactjs/react-modal
 @date: 2018/3/9
 @author: Buggy(chenyuanhui@baie.com.cn)
 @warning: react-modal和app的Modal不同的是：
 1.控制是否显示的属性为isOpen
 2.样式修改参照Modal.defaultStyles
 3.必须添加ariaHideApp={false}属性(屏幕阅读器专用属性) 或 实现Modal.setAppElement(document.getElementById('root'))
 */
import React, {Component} from 'react'
import {View, ActivityIndicator} from 'react-native'
import Modal from 'react-modal'
import Text from './Text'
import Image from './Image'
import TouchableOpacity from './TouchableOpacity'
import {borderColor, placeholderText, PRIMARY_COLOR, labelText} from './Colors'
import {TRUE_ONE_PIXEL, tSizeSm} from './VStyle'
import * as StyleSheet from './StyleSheet'

const BORDER_RADIUS = 5
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    innerContainer: {
        borderRadius: BORDER_RADIUS,
        width: tSizeSm(300),
        overflow: 'hidden'
    },
    title: {
        height: tSizeSm(45),
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderTopLeftRadius: BORDER_RADIUS,
        borderTopRightRadius: BORDER_RADIUS,
        position: 'relative',
        paddingHorizontal: tSizeSm(15)
    },
    titleText: {
        fontSize: tSizeSm(15),
        textAlign: 'center'
    },

    titleBottomBorder: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 0,
        backgroundColor: '#e4e4e4'
    },
    closeButton: {
        position: 'absolute',
        right: tSizeSm(5),
        top: tSizeSm(2),
        width: tSizeSm(40),
        height: tSizeSm(40),
        justifyContent: 'center'
    },
    close: {
        width: tSizeSm(15),
        height: tSizeSm(15),
        marginLeft: tSizeSm(20)
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'center',
        borderTopWidth: TRUE_ONE_PIXEL,
        borderColor,
        marginTop: tSizeSm(15)
    },
    modalButton: {
        flex: 1,
        height: tSizeSm(46),
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    modalButtonText: {
        fontSize: tSizeSm(15),
        color: labelText
    },
    loading: {
        marginRight: tSizeSm(5)
    }
})
/**
 * Modal.defaultStyles的属性如下，可以通过customStyles去覆盖、添加
 {
    "overlay": {
        "position": "fixed",
        "top": 0,
        "left": 0,
        "right": 0,
        "bottom": 0,
        "backgroundColor": "rgba(255, 255, 255, 0.75)"
    },
    "content": {
        "position": "absolute",
        "top": "40px",
        "left": "40px",
        "right": "40px",
        "bottom": "40px",
        "border": "1px solid #ccc",
        "background": "#fff",
        "overflow": "auto",
        "WebkitOverflowScrolling": "touch",
        "borderRadius": "4px",
        "outline": "none",
        "padding": "20px"
    }
 }
 */

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
}

export default class AppModal extends Component {
    constructor(props) {
        super(props)
        this.onRequestClose = this.onRequestClose.bind(this)
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
        this.showTime = Date.now()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible === true && this.props.visible === false) {
            this.showTime = Date.now()
        }
    }

    onRequestClose() {
        const {close} = this.props
        const pastTime = Date.now() - this.showTime
        if (close && pastTime > 500) {
            // 解决modal刚被打开，就触发了 onRequestClose ，RN-web 08.4的bug
            close()
        }
    }

    sure() {
        const {sure, close} = this.props
        if (sure) {
            sure()
            return
        }
        if (close) {
            close()
        }
    }

    render() {
        const {
            style = {},
            containerStyle = {},
            title = '提示',
            visible = false,
            yesBtnText = '确定',
            type = 'alert',
            noBtnText = '取消',
            close,
            no = false,
            loading = false, // 确定按钮是否 显示loading
            yesBtnDisable = false,
            yesBtnColor = PRIMARY_COLOR,
            hideClose = false,
            hideHeader = false,
            hideFooter = false,
            styleType = 'modal',
            animationType = 'none',
            transparent = true
        } = this.props
        if (!visible) {
            return null
        }

        const fixStyle = {}
        const innerContainerTransparentStyle = transparent ? {backgroundColor: '#fff'} : null

        let cancelBtn
        let loadingDom
        let splitDom
        if (loading) {
            loadingDom = (
                <ActivityIndicator animating={true} style={styles.loading} color={PRIMARY_COLOR} size="small" />
            )
        }
        if (type === 'confirm') {
            cancelBtn = (
                <TouchableOpacity style={styles.modalButton} onPress={no || close}>
                    <Text style={styles.modalButtonText}>{noBtnText}</Text>
                </TouchableOpacity>
            )
            splitDom = <View style={{width: TRUE_ONE_PIXEL, backgroundColor: borderColor, height: tSizeSm(43)}} />
        }
        let footerDom = (
            <View style={styles.footer}>
                {cancelBtn}
                {splitDom}
                <TouchableOpacity style={styles.modalButton} onPress={this.sure.bind(this)}>
                    {loadingDom}
                    <Text style={[styles.modalButtonText, {color: yesBtnDisable ? placeholderText : yesBtnColor}]}>
                        {yesBtnText}
                    </Text>
                </TouchableOpacity>
            </View>
        )

        if (hideFooter) {
            footerDom = null
        }
        let closeDom
        const fixHeaderStyle = {}
        if (!hideClose) {
            fixHeaderStyle.paddingRight = tSizeSm(30)
            closeDom = (
                <TouchableOpacity style={styles.closeButton} onPress={close}>
                    <Image source={require('../img/app/close.png')} style={styles.close} />
                </TouchableOpacity>
            )
        }
        let titleStyle = {}
        let headerBottom = <View style={styles.titleBottomBorder} />
        if (styleType === 'alert') {
            closeDom = null
            titleStyle = {
                fontWeight: 'bold',
                fontSize: tSizeSm(16)
            }
            fixStyle.borderRadius = tSizeSm(10)
            headerBottom = null
        }
        let headerDom = (
            <View style={[styles.title, fixHeaderStyle]}>
                <Text style={[styles.titleText, titleStyle]} numberOfLines={1}>
                    {title}
                </Text>
                {closeDom}
                {headerBottom}
            </View>
        )
        if (hideHeader) {
            headerDom = null
        }
        return (
            <Modal
                ariaHideApp={false}
                animationType={animationType}
                transparent={transparent}
                isOpen={visible}
                style={customStyles}
                onRequestClose={this.onRequestClose}>
                <View style={[styles.container, style]}>
                    <View style={[styles.innerContainer, innerContainerTransparentStyle, fixStyle]}>
                        {headerDom}
                        <View style={[{padding: tSizeSm(15)}, containerStyle]}>{this.props.children}</View>
                        {footerDom}
                    </View>
                </View>
            </Modal>
        )
    }
}
