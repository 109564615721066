/**
 @description: 获取项目差异化相关的配置
 @date: 2018/2/28
 @author: Rayleigh(Rayleigh@baie.com.cn)
 */
import packageInfo from '../../package.json'
import text_zh from './zh/text'
import image_zh from './zh/image'

const PROJECT = packageInfo.project

let allData = {
    text: text_zh,
    image: image_zh,
}

/**
 * 通过key来获取各项目存在差异的内容
 * @param key - 定义好的关键词
 * @param type - 类型 enum(text,data,image,function)
 * @returns {*}
 */
export function getValue(key, type) {
    try {
        return allData[type][key][PROJECT]
    } catch (e) {
        if (type === "function") {
            return () => {
            }
        }
        return e.message
    }
}