/**
 @description: reducer 入口
 @date: 2018/3/26
 @author: 雷利(Rayleight@baie.com.cn)
 */
import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import Cookies from 'js-cookie'
import * as TYPES from '../constants/ActionTypes'
import * as Storage from '../utils/Storage'
import {APP_DATA} from '../constants/StorgeKeys'
import navigation from './navigation'
import userInfo from './userInfo'
import iPayInfo from './iPayInfo'
import b2b from './b2b'
import b2c from './b2c'
import mall from './mall'
import boc from './boc'
import message from './message'
import crm from './crm'
import hosting from './hosting'
import {getMainDomain} from '../utils'
import {env} from '../../package.json'

const initAppData = {
    // app 全局的一些信息
    token: 0,
    notifyInfo: {},
    shareInfo: {},
    notificationMsg: {},
    netInfo: true,
    imageSlide: false,
    quickMenu: true
}

global.token = 0

function save(state) {
    if (state.token) {
        global.token = state.token
        Cookies.set('TokenSign', state.token, {expires: 365, domain: getMainDomain(window.location.hostname)})
        // console.log(location.origin, ' updateToken')
        // global.BaieSSO?.updateToken(state.token, {
        //     env: env === 'rel' ? 'release' : env,
        //     business: 'ZT',
        //     callback: err => console.log(err)
        // })
    }

    Storage.saveItem(
        APP_DATA,
        JSON.stringify({
            ...state,
            shareInfo: {},
            quickMenu: true
        })
    )
        .then(() => {})
        .catch(err => {
            console.error(err)
        })

    return state
}

function appData(state = initAppData, action) {
    const {token, notifyInfo, shareInfo, notificationMsg, netInfo, imageSlide, quickMenu} = action
    switch (action.type) {
        case TYPES.LOGOUT: {
            return save({
                ...state,
                token: 'logout'
            })
        }
        case TYPES.SET_TOKEN: {
            return save({
                ...state,
                token
            })
        }
        case TYPES.SET_APP_DATA: {
            return save({
                ...state,
                ...action.state
            })
        }
        case TYPES.SHOW_NOTIFY: {
            return save({
                ...state,
                notifyInfo
            })
        }
        case TYPES.HIDE_NOTIFY: {
            return save({
                ...state,
                notifyInfo: {}
            })
        }
        case TYPES.UPDATE_SHARE: {
            return save({
                ...state,
                shareInfo
            })
        }
        case TYPES.NOTIFICATION_DATA: {
            return save({
                ...state,
                notificationMsg
            })
        }
        case TYPES.NET_INFO_CHANGE: {
            return save({
                ...state,
                netInfo
            })
        }
        case TYPES.SHOW_IMAGE_SLIDE: {
            return save({
                ...state,
                imageSlide
            })
        }
        case TYPES.TOGGLE_QUICKMENU: {
            return save({
                ...state,
                quickMenu
            })
        }
        default:
            return state
    }
}

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        appData,
        navigation,
        userInfo,
        iPayInfo,
        b2b,
        b2c,
        boc,
        mall,
        message,
        crm,
        hosting
    })

export default createRootReducer
