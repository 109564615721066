/**
 @description: 未命名
 @date: 2018/12/7
 @author: Buggy(chenyuanhui@baie.com.cn)
 */
import {Sign as SignBeta} from './beta-min'
import {Sign as SignRel} from './release-min'
import {Sign as SignProduct} from './reactWeb-production-min'

import {IS_BETA, IS_REL} from '../../constants/FetchUrls'
export let signature = SignProduct

if (IS_BETA) {
    signature = SignBeta
}

if (IS_REL) {
    signature = SignRel
}
